import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'
import DatePicker from 'components/DatePicker'

const WorkspaceFormStartedAt = ({ startedAt, onChange, disabled, formId }) => (
  <WorkspaceFormInput attribute='startedAt' formId={formId}>
    <DatePicker
      className='workspace-form__input'
      selected={startedAt}
      onChange={value => onChange({ startedAt: value })}
      showTimeSelect
      timeIntervals={1}
      precision='minute'
      disabled={disabled}
    />
  </WorkspaceFormInput>
)

WorkspaceFormStartedAt.propTypes = {
  startedAt: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  formId: PropTypes.string
}

const mapStateToProps = state => ({
  startedAt: state.form.data.startedAt
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormStartedAt)
