import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Checkbox = ({ value, onChange, className, ...props }) => {
  const handleOnChange = () => {
    onChange(!value)
  }

  return (
    <div className={classnames('checkbox', className)} onClick={handleOnChange} {...props}>
      <input className='checkbox__default' type='checkbox' checked={value} readOnly />
      <span className='checkbox__checkmark' />
    </div>
  )
}

Checkbox.defaultProps = {
  value: false,
  onChange: () => {}
}

Checkbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.any
}

export default Checkbox
