import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { selectRealizationHourlySummary, selectRealizationHourlySummaryProject } from 'workspace/selectors'
import Color from 'color'

const RealizationHourlySummaryTimelineBlockContent = ({
  id,
  changeover,
  plannedQuantity,
  realizedQuantity,
  project,
  stoppage
}) => {
  const borderStyle = { borderRightWidth: 1, borderLeftWidth: 1 }

  if (changeover) {
    return <div className='timeline-block__content' />
  } else if (stoppage) {
    return (
      <div className='timeline-block__stack'>
        <div
          className='timeline-block__content timeline-block__content--danger'
          style={borderStyle}
        />
      </div>
    )
  } else if (project) {
    const color = '#' + project.blockColor
    const style = project.blockColor
      ? { borderColor: Color(color).darken(0.5), backgroundColor: color }
      : {}
    const isOk = realizedQuantity >= plannedQuantity

    return (
      <div className='timeline-block__stack'>
        <div
          style={borderStyle}
          className={classnames(
            'timeline-block__content',
            'timeline-block__line--outstanding',
            {
              'timeline-block__content--success': isOk,
              'timeline-block__content--danger': !isOk
            }
          )}
        >
          <div className='timeline-block__line timeline-block__line--center'>
            { isOk && <FormattedMessage id='ok' /> }
            { !isOk && <FormattedMessage id='nok' /> }
          </div>
        </div>

        <div
          className='timeline-block__content timeline-block__content--success'
          style={{ ...style, ...borderStyle }}
        >
          <code className='timeline-block__line timeline-block__line--center'>
            <FormattedMessage
              id='plannedShort'
            />
            <br />
            {plannedQuantity}
          </code>

          <code className='timeline-block__line timeline-block__line--center'>
            <FormattedMessage
              id='realShort'
            />
            <br />
            {realizedQuantity}
          </code>
        </div>
      </div>
    )
  }

  return null
}

RealizationHourlySummaryTimelineBlockContent.propTypes = {
  id: PropTypes.string.isRequired,
  plannedQuantity: PropTypes.number,
  realizedQuantity: PropTypes.number,
  changeover: PropTypes.bool.isRequired,
  stoppage: PropTypes.bool,
  project: PropTypes.shape({
    blockColor: PropTypes.string
  })
}

const mapStateToProps = (state, { id }) => ({
  ...selectRealizationHourlySummary(state, id),
  project: selectRealizationHourlySummaryProject(state, id)
})

export default connect(mapStateToProps)(RealizationHourlySummaryTimelineBlockContent)
