import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import WorkspaceLocationToggle from 'workspace/WorkspaceLocationToggle'
import { selectMode } from './selectors'
import WorkspaceLocationSelectAll from './WorkspaceLocationSelectAll'
import WorkspaceLocationSelectNone from './WorkspaceLocationSelectNone'
import { Modes } from 'workspace/const'

const WorkspaceLocations = ({ ids, mode, multiple, allowAll }) => (
  <>
    <div className='btn-group btn-group--flex'>
      {ids.map(id => <WorkspaceLocationToggle multiple={multiple} key={id} id={id} />)}
      {
        (mode === Modes.FORMREINIGER || allowAll) && (
          <>
            <WorkspaceLocationSelectAll />
            <WorkspaceLocationSelectNone />
          </>
        )
      }
    </div>
  </>
)

WorkspaceLocations.defaultProps = {
  ids: []
}

WorkspaceLocations.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  mode: PropTypes.string,
  multiple: PropTypes.bool
}

const mapStateToProps = (state) => ({
  ids: state.locations.allIds,
  mode: selectMode(state)
})

export default connect(mapStateToProps)(WorkspaceLocations)
