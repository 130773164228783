import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import FormreinigerTimeline from 'workspace/FormreinigerTimeline'
import WorkspaceLocationsEmpty from 'workspace/WorkspaceLocationsEmpty'

const FormreinigerTimelines = ({ ids }) => (
  <div>
    {ids.length === 0 ? <WorkspaceLocationsEmpty /> : <FormreinigerTimeline />}
  </div>
)

FormreinigerTimelines.defaultProps = {
  ids: []
}

FormreinigerTimelines.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number)
}

const mapStateToProps = (state) => ({
  ids: state.locations.selectedIds
})

export default connect(mapStateToProps)(FormreinigerTimelines)
