import { call, all, select } from 'redux-saga/effects'
import { selectMode } from 'workspace/selectors'
import {
  requestLocationSchedules,
  requestAllSchedules,
  loadLocationSchedules,
  loadAllSchedules
} from './schedules'
import {
  requestLocationRealizations,
  requestAllRealizations,
  loadLocationRealizations,
  loadAllRealizations
} from './realizations'
import {
  requestLocationChangeovers,
  loadLocationChangeovers,
  requestAllChangeovers,
  loadAllChangeovers
} from './formreiniger'
import {
  requestLocationScraps,
  requestAllScraps,
  loadLocationScraps,
  loadAllScraps
} from './scraps'
import {
  requestLocationRealizationSummaries,
  requestAllRealizationSummaries,
  loadLocationRealizationSummaries,
  loadAllRealizationSummaries
} from './realizationSummaries'
import { Modes } from 'workspace/const'

export function * loadTimelines () {
  const mode = yield select(selectMode)
  const requests = [
    call(requestAllSchedules),
    mode === Modes.REALIZATION ? call(requestAllRealizations) : null,
    mode === Modes.REALIZATION ? call(requestAllScraps) : null,
    mode === Modes.REALIZATION ? call(requestAllRealizationSummaries) : null,

    mode === Modes.FORMREINIGER ? call(requestAllChangeovers) : null
  ]
  const loaders = [
    call(loadAllSchedules),
    mode === Modes.REALIZATION ? call(loadAllRealizations) : null,
    mode === Modes.REALIZATION ? call(loadAllScraps) : null,
    mode === Modes.REALIZATION ? call(loadAllRealizationSummaries) : null,

    mode === Modes.FORMREINIGER ? call(loadAllChangeovers) : null
  ]

  yield all(requests)
  yield all(loaders)
}

export function * loadTimeline (locationId) {
  const mode = yield select(selectMode)
  const requests = [
    call(requestLocationSchedules, locationId),
    mode === Modes.REALIZATION ? call(requestLocationRealizations, locationId) : null,
    mode === Modes.REALIZATION ? call(requestLocationScraps, locationId) : null,
    mode === Modes.REALIZATION ? call(requestLocationRealizationSummaries, locationId) : null,

    mode === Modes.FORMREINIGER ? call(requestLocationChangeovers, locationId) : null
  ]
  const loaders = [
    call(loadLocationSchedules, locationId),
    mode === Modes.REALIZATION ? call(loadLocationRealizations, locationId) : null,
    mode === Modes.REALIZATION ? call(loadLocationScraps, locationId) : null,
    mode === Modes.REALIZATION ? call(loadLocationRealizationSummaries, locationId) : null,

    mode === Modes.FORMREINIGER ? call(loadLocationChangeovers, locationId) : null
  ]

  yield all(requests)
  yield all(loaders)
}
