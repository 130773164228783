import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AssociationSelect from 'components/AssociationSelect'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { selectAvailableScrapTypes, selectFormValue } from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormScrapType = ({ id, variant, scrapTypes, onChange, formId }) => (
  <WorkspaceFormInput attribute='scrapType' formId={formId}>
    <AssociationSelect
      isClearable={false}
      backspaceRemovesValue={false}
      options={scrapTypes}
      value={id}
      onChange={newId => onChange({ scrapTypeId: newId })}
      labelMapper={st => `${st.symbol} - ${st.description}`}
    />
  </WorkspaceFormInput>
)

WorkspaceFormScrapType.propTypes = {
  scrapTypes: PropTypes.arrayOf(PropTypes.shape({
    symbol: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number
  })),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  formId: PropTypes.string,
  variant: PropTypes.string
}

const mapStateToProps = (state, ownProps) => ({
  scrapTypes: selectAvailableScrapTypes(state, ownProps.formId, ownProps.variant === 'collective'),
  id: selectFormValue(state, 'scrapTypeId', ownProps.formId)
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormScrapType)
