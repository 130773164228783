import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import AssociationSelect from 'components/AssociationSelect'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { selectAvailablePredefinedRemarks } from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'

const messages = defineMessages({
  noPredefinedRemarks: {
    id: 'workspace.form.noPredefinedRemarks',
    defaultMessage: 'No matching remarks',
    description: 'Message displayed when there are no remarks available for the selected location or search term'
  }
})

const WorkspaceFormPredefinedRemark = ({ id, intl, predefinedRemarks, onChange, formId }) => (
  <WorkspaceFormInput attribute='predefinedRemark'>
    <AssociationSelect
      isClearable
      options={predefinedRemarks}
      value={id}
      onChange={(newId, _, remark) => onChange({ predefinedRemarkId: newId })}
      labelMapper={remark => `${remark.description}`}
      noOptionsMessage={() => intl.formatMessage(messages.noPredefinedRemarks)}
    />
  </WorkspaceFormInput>
)

WorkspaceFormPredefinedRemark.propTypes = {
  intl: intlShape.isRequired,
  predefinedRemarks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired
  })),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  predefinedRemarks: selectAvailablePredefinedRemarks(state),
  id: state.form.data.predefinedRemarkId,
  formId: PropTypes.string
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WorkspaceFormPredefinedRemark)
