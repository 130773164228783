import { call, put, select, delay } from 'redux-saga/effects'
import api from 'lib/api/client'
import { closeForm, openForm } from 'workspace/actions'
import { selectToken, selectForm, selectSchedule } from 'workspace/selectors'
import { requestLocationSchedules, loadLocationSchedules } from './schedules'
import { Characters } from 'lib/const'

export function * edit ({ payload: id }) {
  const schedule = yield select(selectSchedule, id)
  const form = yield select(selectForm)

  if (form.isActive) {
    yield put(closeForm())
    yield call(delay, 0) // allow redux to update state before opening the form again
  }

  yield put(openForm(schedule.character, schedule[`${schedule.character}Plan`]))
}

const extractPlanId = schedule => {
  switch (schedule.character) {
    case Characters.PRODUCTION:
      return schedule.productionPlan.id
    case Characters.STOPPAGE:
      return schedule.stoppagePlan.id
    default:
      return null
  }
}

export function * destroy ({ payload: scheduleId }) {
  const token = yield select(selectToken)
  const schedule = yield select(selectSchedule, scheduleId)
  const form = yield select(selectForm)
  const planId = extractPlanId(schedule)

  yield call(requestLocationSchedules, schedule.locationId)

  if (form.isActive && form.data.id === planId) {
    yield put(closeForm())
  }

  yield call(api.delete, `/${schedule.character}_plans/${planId}`, { token })
  yield call(loadLocationSchedules, schedule.locationId)
}
