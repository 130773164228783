import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import PlanTimelines from 'workspace/PlanTimelines'
import PlanTimestamp from 'workspace/PlanTimestamp'
import RealizationScrapPercentage from 'workspace/RealizationScrapPercentage'
import RealizationScrapTables from 'workspace/RealizationScrapTables'
import RealizationTimelines from 'workspace/RealizationTimelines'
import RealizationSummaryTimelines from 'workspace/RealizationSummaryTimelines'
import RealizationHourlySummaryTimelines from 'workspace/RealizationHourlySummaryTimelines'
import FormreinigerTimelines from 'workspace/FormreinigerTimelines'
import { selectMode } from 'workspace/selectors'
import { Modes } from 'workspace/const'

const WorkspaceTimelines = ({ mode }) => (
  <React.Fragment>
    {
      mode === Modes.FORMREINIGER &&
      <>
        <div className='timeline-group'>
          <h2 className='timeline-group__header'>
            <FormattedMessage
              id='workspace.timelines.changeovers'
              defaultMessage='Changeovers'
              description='Changeovers timelines header'
            />
          </h2>
          <FormreinigerTimelines />
        </div>
        <div className='timeline-group'>
          <h2 className='timeline-group__header'>
            <FormattedMessage
              id='workspace.timelines.realization'
              defaultMessage='Realization'
              description='Realization timelines header'
            />
          </h2>
        </div>
      </>
    }

    <div className='timeline-group'>
      {
        mode === 'realization' &&
        <h2 className='timeline-group__header'>
          <FormattedMessage
            id='workspace.timelines.plan'
            defaultMessage='Plan'
            description='Plan timelines header'
          />
          <PlanTimestamp />
        </h2>
      }
      <PlanTimelines />
    </div>

    {
      mode === 'realization' &&
      <div className='timeline-group'>
        <h2 className='timeline-group__header'>
          <FormattedMessage
            id='workspace.timelines.realization'
            defaultMessage='Realization'
            description='Realization timelines header'
          />
        </h2>
        <RealizationTimelines />
      </div>
    }

    {
      mode === 'realization' &&
      <div className='timeline-group'>
        <h2 className='timeline-group__header'>
          <FormattedMessage
            id='workspace.timelines.summary'
            defaultMessage='WAB'
            description='Summary timelines header'
          />
        </h2>
        <RealizationHourlySummaryTimelines />
        <RealizationSummaryTimelines />
      </div>
    }

    {
      mode === 'realization' &&
      <div className='timeline-group'>
        <h2 className='timeline-group__header'>
          <FormattedMessage
            id='workspace.timelines.scrap'
            defaultMessage='Scrap'
            description='Scrap table header'
          />
          <RealizationScrapPercentage />
        </h2>
        <RealizationScrapTables />
      </div>
    }
  </React.Fragment>
)

WorkspaceTimelines.propTypes = {
  mode: Modes.propType
}

const mapStateToProps = (state) => ({
  mode: selectMode(state)
})

export default connect(mapStateToProps)(WorkspaceTimelines)
