import { call, fork, put, select, delay, race, take } from 'redux-saga/effects'
import moment from 'moment-timezone'
import api from 'lib/api/client'
import { receiveChangeovers, requestChangeovers } from 'workspace/actions'
import { selectToken, selectTimezone } from 'workspace/selectors'
import { selectNavigationRange } from 'containers/Navigation/selectors'
import { selectAllChangeoversWithoutChange } from '../selectors'
import Actions, { markChangeoverUrgent } from '../actions'

const changeoverDecorator = ({ startsAt, engineeringFinishedAt, endsAt, ...changeover }, timezone) => ({
  ...changeover,
  startsAt: moment.tz(startsAt, timezone),
  endsAt: moment.tz(endsAt, timezone),
  engineeringFinishedAt: moment.tz(engineeringFinishedAt, timezone)
})

const decorateChangeovers = (changeovers, timezone) => changeovers.map(s => changeoverDecorator(s, timezone))

const callChangeoversAPI = ({ token, from, to, locationId }) =>
  call(api.get, '/anticipated_location_realizations/changeovers', {
    token,
    params: {
      from,
      to,
      locationId
    }
  })

export function * requestLocationChangeovers (locationId) {
  yield put(requestChangeovers(locationId))
}

export function * requestAllChangeovers () {
  const ids = yield select(state => state.locations.selectedIds)

  for (var i = 0; i < ids.length; i++) {
    yield put(requestChangeovers(ids[i]))
  }
}

export function * loadLocationChangeovers (locationId) {
  const token = yield select(selectToken)
  const { from, to } = yield select(selectNavigationRange)
  const timezone = yield select(selectTimezone)

  const changeovers = yield callChangeoversAPI({ token, from, to, locationId })
  yield put(receiveChangeovers({ locationId, changeovers: decorateChangeovers(changeovers, timezone) }))
}

export function * loadAllChangeovers () {
  // used together with takeLatest this creates a "debounce" effect to stop too many requests from running at once
  yield delay(250)

  const ids = yield select(state => state.locations.selectedIds)

  for (var i = 0; i < ids.length; i++) {
    yield fork(loadLocationChangeovers, ids[i])
  }
}

function * watchUrgentChangeovers () {
  while (true) {
    const changeovers = yield select(selectAllChangeoversWithoutChange)
    const urgentChangeovers = []
    changeovers.map(changeover => {
      if (changeover.endsAt < new Date() && !changeover.urgent) {
        changeover.urgent = true
        urgentChangeovers.push(changeover)
      }
    })
    if (urgentChangeovers.length !== 0) {
      yield put(markChangeoverUrgent({ urgentChangeovers }))
    }
    yield race({
      action: take(Actions.RECEIVE_CHANGEOVERS),
      timeout: delay(10000)
    })
  }
}

export function * init () {
  yield fork(watchUrgentChangeovers)
}
