import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ToggleButton from 'components/ToggleButton'
import { selectLocation, selectSingleLocation, unselectLocation } from 'workspace/actions'
import { selectMode } from 'workspace/selectors'
import { Modes } from 'workspace/const'

const nothing = () => {}

const WorkspaceLocationToggle = ({ id, multiple, symbol, isSelected, select, selectSingle, unselect }) => (
  <ToggleButton
    isToggled={isSelected}
    onToggleOn={() => (multiple ? select : selectSingle)(id)}
    onToggleOff={() => (multiple ? unselect : nothing)(id)}
    color='default'
  >
    {symbol}
  </ToggleButton>
)

WorkspaceLocationToggle.propTypes = {
  symbol: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
  select: PropTypes.func.isRequired,
  selectSingle: PropTypes.func.isRequired,
  unselect: PropTypes.func.isRequired,
  multiple: PropTypes.bool
}

const mapStateToProps = (state, { id, multiple }) => ({
  ...state.locations.allById[id],
  isSelected: state.locations.selectedIds.includes(id),
  multiple: multiple || [Modes.PLANNING, Modes.FORMREINIGER].includes(selectMode(state))
})

const mapDispatchToProps = ({
  select: selectLocation,
  unselect: unselectLocation,
  selectSingle: selectSingleLocation
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceLocationToggle)
