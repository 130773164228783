import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import Icon from 'components/Icon'
import moment from 'moment-timezone'
import { selectPlanChanged, isLocationLoadingSchedules, selectPlanTimestamp } from 'workspace/selectors'
import { updatePlanChanged } from 'workspace/actions'
import { DATETIME_FORMAT } from 'lib/const'

const RECENCY_THRESHOLD = 8

class PlanTimestamp extends React.Component {
  isRecent = () => {
    if (!this.props.updatedAt) {
      return false
    }

    const { updatedAt } = this.props
    const threshold = moment.tz(updatedAt.tz()).subtract(RECENCY_THRESHOLD, 'hours')

    return updatedAt.isAfter(threshold)
  }

  handleDismiss = e => {
    e.preventDefault()
    this.props.dismiss()
  }

  render () {
    const { updatedAt, isLoading } = this.props

    return (
      <span className='small' style={{ fontWeight: this.isRecent() ? 'bold' : 'normal', lineHeight: '26px' }}>
        &nbsp;
        [
        { isLoading && <Icon name='cog' spin /> }
        { !isLoading && updatedAt === null && '---' }
        { !isLoading && updatedAt && <code>{updatedAt.format(DATETIME_FORMAT)}</code> }
        ]
        &nbsp;
        {
          this.props.planChanged &&
          <span className='timeline-group__header-alert'>
            <FormattedMessage
              id='workspace.planUpdated'
              defaultMessage='The planner has changed plan for your location'
              description='Message that appears when plan is updated'
            />
            &nbsp;
            <a href='#' onClick={this.handleDismiss} className='btn btn--small'>
              <FormattedMessage
                id='workspace.planUpdatedConfirmation'
                defaultMessage='I confirm'
                description='Message that appears on confirmation button when plan is updated'
              />
            </a>
          </span>
        }
      </span>
    )
  }
}

PlanTimestamp.propTypes = {
  updatedAt: PropTypes.shape({ format: PropTypes.func.isRequired }),
  isLoading: PropTypes.bool.isRequired,
  planChanged: PropTypes.bool,
  dismiss: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const locationId = state.locations.selectedIds[0]

  return {
    planChanged: selectPlanChanged(state),
    updatedAt: selectPlanTimestamp(state, locationId),
    isLoading: isLocationLoadingSchedules(state, locationId)
  }
}

const mapDispatchToProps = ({
  dismiss: () => updatePlanChanged(false)
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(PlanTimestamp)
