import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ToggleButton from 'components/ToggleButton'
import { showAllLocations } from './actions'
import { compose } from 'redux'
import { injectIntl, intlShape } from 'react-intl'
import { selectAllLocationsSelected } from './selectors'

const WorkspaceLocationSelectAll = ({
  allSelected,
  showAll,
  intl
}) => (
  <ToggleButton
    isToggled={allSelected}
    onToggleOn={showAll}
    onToggleOff={showAll}
    color='default'
  >
    {intl.formatMessage({ id: 'components.button.showAll' })}
  </ToggleButton>
)

WorkspaceLocationSelectAll.propTypes = {
  allSelected: PropTypes.bool,
  showAll: PropTypes.func,
  intl: intlShape
}

const mapStateToProps = state => ({
  allSelected: selectAllLocationsSelected(state)
})

const mapDispatchToProps = {
  showAll: showAllLocations
}

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(WorkspaceLocationSelectAll)
