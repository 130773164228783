import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import AssociationSelect from 'components/AssociationSelect'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { selectAvailableComponents, selectFormValue } from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'

const messages = defineMessages({
  noComponents: {
    id: 'workspace.form.noComponents',
    defaultMessage: 'No matching components',
    description: 'Message displayed when there are no components for the selected location or search term'
  }
})

const WorkspaceFormComponent = ({ id, intl, components, onChange, formId }) => (
  <WorkspaceFormInput attribute='component' formId={formId}>
    <AssociationSelect
      isClearable
      options={components}
      value={id}
      onChange={(newId, _, component) => onChange({ componentId: newId, quantity: component ? (component.packQuantity || null) : '' })}
      labelMapper={prod => `${prod.description} (${prod.code})`}
      noOptionsMessage={() => intl.formatMessage(messages.noComponents)}
    />
  </WorkspaceFormInput>
)

WorkspaceFormComponent.propTypes = {
  intl: intlShape.isRequired,
  components: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    code: PropTypes.string,
    id: PropTypes.number
  })),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  formId: PropTypes.string
}

const mapStateToProps = (state, ownProps) => ({
  components: selectAvailableComponents(state, ownProps.formId),
  id: selectFormValue(state, 'componentId', ownProps.formId)
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WorkspaceFormComponent)
