import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'
import { compose } from 'redux'
import { injectIntl, intlShape } from 'react-intl'

const getLabel = (calculatedDuration, intl) => {
  if (calculatedDuration) {
    return intl.formatMessage(
      { id: 'workspace.form.manualDurationWithHint' },
      { calculatedDuration: Math.round(calculatedDuration) }
    )
  } else {
    return intl.formatMessage({ id: 'workspace.form.manualDuration' })
  }
}

const WorkspaceFormManualDuration = ({ manualDuration, onChange, calculatedDuration, intl, formId }) => (
  <WorkspaceFormInput attribute='manualDuration' label={getLabel(calculatedDuration, intl)}>
    <input
      type='number'
      className='workspace-form__input'
      value={manualDuration || ''}
      onChange={(e) => onChange({ manualDuration: e.target.value ? Number(e.target.value) : null })}
    />
  </WorkspaceFormInput>
)

WorkspaceFormManualDuration.propTypes = {
  manualDuration: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  calculatedDuration: PropTypes.any,
  intl: intlShape,
  formId: PropTypes.string
}

const mapStateToProps = state => {
  return {
    manualDuration: state.form.data.manualDuration,
    quantity: state.form.data.quantity,
    calculatedDuration: state.form.data.calculatedDuration
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WorkspaceFormManualDuration)
