import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, defineMessages, intlShape } from 'react-intl'

const messages = defineMessages({
  piece: {
    id: 'components.unit.piece',
    defaultMessage: 'pcs',
    description: 'Piece/pieces measurement unit'
  },
  minute: {
    id: 'components.unit.minute',
    defaultMessage: 'min.',
    description: 'Minute/minutes unit'
  }
})

const Unit = ({ intl, value, unit }) => {
  if (value === null || value === undefined) {
    return <span>---</span>
  } else {
    return <span>{value} {intl.formatMessage(messages[unit])}</span>
  }
}

Unit.propTypes = {
  intl: intlShape,
  value: PropTypes.any,
  unit: PropTypes.oneOf(['piece', 'minute']).isRequired
}

export default injectIntl(Unit)
