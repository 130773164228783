import { call, put, race, select, delay, fork, take } from 'redux-saga/effects'
import {
  selectMode,
  selectToken,
  selectSelectedLocation
} from 'workspace/selectors'
import Actions, { receiveMeasurement } from 'workspace/actions'
import { Modes } from 'workspace/const'
import api from 'lib/api/client'

const INTERVAL = 30 * 1000

function * realization () {
  let result

  yield call(loadMeasurement)

  while (true) {
    result = yield race({
      action: take(Actions.RECEIVE_MEASUREMENT),
      timeout: delay(INTERVAL)
    })

    if (result.timeout) {
      yield call(loadMeasurement)
    }
  }
}

export function * loadMeasurement () {
  const token = yield select(selectToken)
  const location = yield select(selectSelectedLocation)

  const result = yield call(api.get, '/measurements/to_confirm', { token, params: { locationId: location.id } })
  yield put(receiveMeasurement(result))
}

export default function * measurements () {
  const mode = yield select(selectMode)

  if (mode === Modes.REALIZATION) {
    yield fork(realization)
  }
}

export function * confirmMeasurement ({ payload: id }) {
  const token = yield select(selectToken)
  yield call(api.post, `/measurements/${id}/confirm`, {}, { token })
  yield call(loadMeasurement)
}
