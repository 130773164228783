import React from 'react'
import PropTypes from 'prop-types'
import Barcode from 'react-barcode'
import { injectIntl, intlShape } from 'react-intl'

const WorkspaceBarcodeZZVORA = ({ product, intl }) => {
  const color = `#${product.labelColor}`
  return (
    <React.Fragment>
      <div className='label-modal__line'>{product.description}</div>
      <div className='label-modal__line' style={{ textTransform: 'uppercase' }}>
        { intl.formatMessage({ id: 'workspace.realization.tooltip.modal.zzvora' }) }
      </div>
        <Barcode
          value={product.labelNumber}
          format='CODE39'
          background={color}
          height={200}
          width={2}
          displayValue
        />
    </React.Fragment>
  )
}

WorkspaceBarcodeZZVORA.propTypes = {
  product: PropTypes.shape({
    description: PropTypes.string.isRequired,
    labelNumber: PropTypes.string,
    labelDetails: PropTypes.string,
    labelColor: PropTypes.string.isRequired,
    kanbanConfirmationText: PropTypes.string,
    intl: intlShape
  })
}

export default injectIntl(WorkspaceBarcodeZZVORA)
