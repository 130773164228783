import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Timeline from 'components/Timeline'
import RealizationHourlySummaryTimelineBlock from 'workspace/RealizationHourlySummaryTimelineBlock'
import RealizationHourlySummaryAggregationTimelineBlock from 'workspace/RealizationHourlySummaryAggregationTimelineBlock'
import { selectNavigationRange } from 'containers/Navigation/selectors'
import { selectRealizationHourlySummaryRealizationAggregations } from './selectors'

const RealizationHourlySummaryTimeline = ({ id, date, scope, symbol, realizationHourlySummaryIds, isLoading, lunchBreaks, start, finish, aggregations }) => (
  <Timeline height={160} scope={scope} date={date} header={symbol} isLoading={isLoading} size={1} lunchBreaks={lunchBreaks} start={start} finish={finish}>
    {realizationHourlySummaryIds.map(sid => <RealizationHourlySummaryTimelineBlock key={sid} id={sid} />)}
    {aggregations.map(aggregation => <RealizationHourlySummaryAggregationTimelineBlock key={aggregation.id} {...aggregation} />)}
  </Timeline>
)

RealizationHourlySummaryTimeline.propTypes = {
  id: PropTypes.number.isRequired,
  symbol: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
  scope: PropTypes.oneOf(['day', 'week', 'range']).isRequired,
  realizationHourlySummaryIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  lunchBreaks: PropTypes.arrayOf(PropTypes.shape({
    startsAfterMidnight: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired
  })),
  isLoading: PropTypes.bool,
  start: PropTypes.object,
  finish: PropTypes.object,
  aggregations: PropTypes.array
}

const mapStateToProps = (state, { id }) => ({
  date: selectNavigationRange(state).from,
  scope: state.navigation.shift ? 'range' : state.navigation.scope,
  start: state.navigation.shift ? selectNavigationRange(state).from : undefined,
  finish: state.navigation.shift ? selectNavigationRange(state).to : undefined,
  ...state.locations.allById[id],
  realizationHourlySummaryIds: state.realizationHourlySummaries.idsByLocation[id],
  isLoading: state.locations.isLoadingRealizationSummariesById[id],
  aggregations: selectRealizationHourlySummaryRealizationAggregations(state, id)
})

export default connect(mapStateToProps)(RealizationHourlySummaryTimeline)
