import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import AssociationSelect from 'components/AssociationSelect'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import {
  selectProductionPlansForRealization,
  selectFormValue,
  selectCurrentUserManualProductionAllowed
} from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'

const messages = defineMessages({
  noProductionPlans: {
    id: 'workspace.form.noProductionPlans',
    defaultMessage: 'No matching production plans',
    description: 'Message displayed when there are no production plans for the selected location or search term'
  },
  unknownPlan: {
    id: 'workspace.form.unknownPlan',
    defaultMessage: 'Unknown Plan',
    description: 'Message displayed when the selected production plan is not visible on the current timeline'
  }
})

const WorkspaceFormProductionPlan = ({ id, intl, productionPlans, onChange, formId, disabled }) => (
  <WorkspaceFormInput attribute='productionPlan' formId={formId}>
    <AssociationSelect
      isDisabled={disabled}
      isClearable
      options={!productionPlans.find(p => p.id === id) && id ? [...productionPlans, { id }] : productionPlans}
      value={id}
      onChange={(newId, _action, data) => onChange({ productionPlanId: newId, projectId: data ? data.projectId : null })}
      labelMapper={plan => plan.priority ? `${plan.priority}. ${plan.projectName}` : intl.formatMessage(messages.unknownPlan)}
      noOptionsMessage={() => intl.formatMessage(messages.noProductionPlans)}
    />
  </WorkspaceFormInput>
)

WorkspaceFormProductionPlan.propTypes = {
  intl: intlShape.isRequired,
  productionPlans: PropTypes.arrayOf(PropTypes.shape({
    priority: PropTypes.number.isRequired,
    projectName: PropTypes.string,
    id: PropTypes.number
  })),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  formId: PropTypes.string,
  disabled: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => ({
  productionPlans: selectProductionPlansForRealization(state),
  id: selectFormValue(state, 'productionPlanId', ownProps.formId),
  disabled: !selectCurrentUserManualProductionAllowed(state)
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WorkspaceFormProductionPlan)
