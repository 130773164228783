import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'
import Unit from 'components/Unit'

const RealizationSummaryTimelineAggregationTooltip = ({
  plannedQuantity,
  realizedQuantity,
  project
}) => {
  return (
    <div
      className={classnames('timeline-tooltip', `timeline-tooltip--secondary`)}
    >
      <div className='timeline-tooltip__content'>
        <table className='timeline-tooltip__table'>
          <tbody>
            <tr>
              <th />
              <th>{project.name}</th>
            </tr>
            <tr>
              <th>
                <FormattedMessage
                  id='workspace.realizationSummary.tooltip.plannedQuantity'
                  defaultMessage='Planned quantity'
                />
                :
              </th>
              <td>
                <Unit value={plannedQuantity} unit='piece' />
              </td>
            </tr>
            <tr>
              <th>
                <FormattedMessage
                  id='workspace.realizationSummary.tooltip.realizedQuantity'
                />
                :
              </th>
              <td>
                <Unit value={realizedQuantity} unit='piece' />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

RealizationSummaryTimelineAggregationTooltip.propTypes = {
  plannedQuantity: PropTypes.number.isRequired,
  realizedQuantity: PropTypes.number.isRequired,
  project: PropTypes.object
}

export default RealizationSummaryTimelineAggregationTooltip
