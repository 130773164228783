import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  selectRealization,
  selectRealizationDetails,
} from "workspace/selectors";
import Color from "color";
import {
  formatDuration
} from "workspace/lib";

const RealizationTimelineBlockContent = ({
  id,
  quantity,
  product,
  stoppageType,
  startedAt,
  finishedAt,
  duration,
  onClick,
  rawColor,
  fresh,
}) => {
  rawColor = rawColor && Color(rawColor);
  return (
    <div
      className="timeline-block__content"
      onClick={onClick}
      style={{
        borderColor: rawColor && rawColor.darken(0.5),
        background:
          rawColor && fresh
            ? `repeating-linear-gradient(to bottom, ${rawColor}, ${rawColor} 18.75px, ${rawColor.darken(
                0.4
              )} 18.75px, ${rawColor.darken(0.4)} 37.5px)`
            : rawColor,
      }}
    >
      {product && (
        <React.Fragment>
          <p className="timeline-block__line">
            {product.description} ({product.code})
          </p>
          <p className="timeline-block__line">
            <code>{quantity}x</code>
          </p>
        </React.Fragment>
      )}
      {stoppageType && (
        <React.Fragment>
          <p className="timeline-block__line">{stoppageType.symbol}</p>
          <p className="timeline-block__line">
            <code>{formatDuration(duration)} min.</code>
          </p>
        </React.Fragment>
      )}
    </div>
  );
};

RealizationTimelineBlockContent.propTypes = {
  id: PropTypes.number.isRequired,
  quantity: PropTypes.number,
  startedAt: PropTypes.object.isRequired,
  finishedAt: PropTypes.object.isRequired,
  product: PropTypes.shape({
    description: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    productionTime: PropTypes.number.isRequired,
  }),
  stoppageType: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
  }),
  duration: PropTypes.number,
  onClick: PropTypes.func,
  rawColor: PropTypes.string,
};

const mapStateToProps = (state, { id }) => ({
  ...selectRealization(state, id),
  ...selectRealizationDetails(state, id),
});

export default connect(mapStateToProps)(RealizationTimelineBlockContent);
