import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  injectIntl,
  intlShape,
  defineMessages,
  FormattedMessage
} from 'react-intl'
import classnames from 'classnames'
import Color from 'color'
import Button from 'components/Button'
import ConfirmableButton from 'components/ConfirmableButton'
import Icon from 'components/Icon'
import {
  editRealization,
  destroyRealization,
  showProductBarcode
} from 'workspace/actions'
import {
  selectRealization,
  selectRealizationDetails,
  selectPermissions
} from 'workspace/selectors'
import { Characters, DATETIME_FORMAT } from 'lib/const'
import { BarcodeModalType } from 'workspace/const'
import {
  formatDuration
} from 'workspace/lib'

const CHARACTER_COLOR = {
  [Characters.PRODUCTION]: 'success',
  [Characters.STOPPAGE]: 'danger',
  [Characters.CHANGEOVER]: 'secondary'
}

const messages = defineMessages({
  deleteConfirmation: {
    id: 'workspace.realization.tooltip.deleteConfirmation',
    defaultMessage: 'sure?',
    description: 'Confirmation message displayed when deleting a realization'
  }
})

const RealizationTimelineTooltip = ({
  id,
  startedAt,
  finishedAt,
  project,
  product,
  productionPlan,
  stoppageType,
  quantity,
  predefinedRemark,
  edit,
  destroy,
  showBarcode,
  character,
  duration,
  engineeringDuration,
  setupDuration,
  rawColor,
  isEditable,
  intl
}) => (
  <div
    className={classnames(
      'timeline-tooltip',
      `timeline-tooltip--${CHARACTER_COLOR[character]}`
    )}
  >
    <div
      className='timeline-tooltip__content'
      style={{ borderColor: rawColor && Color(rawColor).darken(0.5) }}
    >
      <table className='timeline-tooltip__table'>
        <tbody>
          <tr>
            <th />
            <th>
              {character === Characters.STOPPAGE && (
                <FormattedMessage
                  id='workspace.realization.tooltip.stoppage'
                  defaultMessage='Stoppage'
                />
              )}
              {character === Characters.PRODUCTION && (
                <FormattedMessage
                  id='workspace.realization.tooltip.realization'
                  defaultMessage='Realization'
                />
              )}
              {character === Characters.CHANGEOVER && (
                <FormattedMessage
                  id='workspace.realization.tooltip.changeover'
                  defaultMessage='Changeover'
                />
              )}
              {character === Characters.UNREPORTED && (
                <FormattedMessage
                  id='workspace.realization.tooltip.unreported'
                  defaultMessage='Unreported'
                />
              )}
              {character !== Characters.UNREPORTED && isEditable && (
                <ConfirmableButton
                  size='small'
                  onClick={e => destroy(id)}
                  className='fa-pull-right'
                  style={{ marginLeft: 5 }}
                  confirmationMessage={intl.formatMessage(
                    messages.deleteConfirmation
                  )}
                >
                  <FormattedMessage
                    id='workspace.realization.tooltip.delete'
                    defaultMessage='Delete'
                  />
                  &nbsp;
                  <Icon name='trash' />
                </ConfirmableButton>
              )}
              {(character === Characters.UNREPORTED ||
                (character === Characters.STOPPAGE && isEditable)) && (
                <React.Fragment>
                  <Button
                    size='small'
                    onClick={e => edit(id)}
                    className='fa-pull-right'
                    style={{ marginLeft: 5 }}
                  >
                    <FormattedMessage
                      id='workspace.realization.tooltip.edit'
                      defaultMessage='Edit'
                    />
                    &nbsp;
                    <Icon name='pencil' />
                  </Button>
                </React.Fragment>
              )}
            </th>
          </tr>
          {productionPlan && (
            <tr>
              <th>
                <FormattedMessage
                  id='workspace.realization.tooltip.productionPlan'
                  defaultMessage='Plan'
                />
                :
              </th>
              <td>
                {productionPlan.priority}. {project.name}
              </td>
            </tr>
          )}
          {character !== Characters.UNREPORTED && !productionPlan && (
            <tr>
              <th>
                <FormattedMessage
                  id='workspace.realization.tooltip.project'
                  defaultMessage='Project'
                />
                :
              </th>
              <td>{project && project.name}</td>
            </tr>
          )}
          {product && (
            <React.Fragment>
              <tr>
                <th>
                  <FormattedMessage
                    id='workspace.realization.tooltip.product'
                    defaultMessage='Product'
                  />
                  :
                </th>
                <td>
                  {product.description} ({product.code})
                </td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage
                    id='workspace.realization.tooltip.earlySapStage'
                    defaultMessage='Early stage SAP'
                  />
                  :
                </th>
                <td>
                  {product.earlySapStage || '---'}
                </td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage
                    id='workspace.realization.tooltip.quantity'
                    defaultMessage='Quantity'
                  />
                  :
                </th>
                <td>
                  <code>{quantity}x</code>
                </td>
              </tr>
            </React.Fragment>
          )}
          {stoppageType && (
            <React.Fragment>
              <tr>
                <th>
                  <FormattedMessage
                    id='workspace.realization.tooltip.stoppageType'
                    defaultMessage='Type'
                  />
                  :
                </th>
                <td>
                  {stoppageType.symbol} - {stoppageType.description}
                </td>
              </tr>
            </React.Fragment>
          )}
          <tr>
            <th>
              <FormattedMessage
                id='workspace.realization.tooltip.duration'
                defaultMessage='Duration'
              />
              :
            </th>
            <td>
              <code>{formatDuration(duration)} min.</code>
            </td>
          </tr>
          {character === Characters.CHANGEOVER && (
            <React.Fragment>
              <tr>
                <th>
                  <FormattedMessage
                    id='workspace.realization.tooltip.engineeringDuration'
                    defaultMessage='Eng. duration'
                  />
                  :
                </th>
                <td>
                  <code>{formatDuration(engineeringDuration)} min.</code>
                </td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage
                    id='workspace.realization.tooltip.setupDuration'
                    defaultMessage='Setup duration'
                  />
                  :
                </th>
                <td>
                  <code>{formatDuration(setupDuration)} min.</code>
                </td>
              </tr>
            </React.Fragment>
          )}
          {character !== Characters.UNREPORTED && (
            <tr>
              <th>
                <FormattedMessage
                  id='workspace.realization.tooltip.remarks'
                  defaultMessage='Remarks'
                />
                :
              </th>
              <td>{predefinedRemark && predefinedRemark.description}</td>
            </tr>
          )}
          <tr>
            <th>
              <FormattedMessage
                id='workspace.realization.tooltip.start'
                defaultMessage='Start'
              />
              :
            </th>
            <td>
              <code>{startedAt.format(DATETIME_FORMAT)}</code>
            </td>
          </tr>
          <tr>
            <th>
              <FormattedMessage
                id='workspace.realization.tooltip.end'
                defaultMessage='End'
              />
              :
            </th>
            <td>
              <code>{finishedAt.format(DATETIME_FORMAT)}</code>
            </td>
          </tr>
          <tr>
            <td colSpan='2'>
              {product && (
                <div className='timeline-tooltip__footer'>
                  { false && (
                    <Button
                      onClick={e => {
                        showBarcode({ productId: product.id, type: BarcodeModalType.REMAINING_QUANTITY })
                      }}
                      className='btn--flex btn--small'
                      style={{ marginRight: 9, paddingTop: 5.25, paddingBottom: 5.25 }}
                    >
                      <FormattedMessage id='workspace.realization.tooltip.modal.remainingQuantity' />
                      <Icon name='barcode' />
                    </Button>
                  )}
                  <Button
                    onClick={e => {
                      showBarcode({ productId: product.id, type: BarcodeModalType.KANBAN_CARD })
                    }}
                    className='btn--flex btn--small'
                    style={{ paddingTop: 5.25, paddingBottom: 5.25 }}
                  >
                    <FormattedMessage id='workspace.realization.tooltip.modal.kanban' />
                    <Icon name='barcode' />
                  </Button>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              { product && false && (
                <div className='timeline-tooltip__footer'>
                  <Button
                    onClick={e => {
                      showBarcode({ productId: product.id, type: BarcodeModalType.ZZVORA })
                    }}
                    className='btn--flex btn--small'
                    style={{ paddingTop: 5.25, paddingBottom: 5.25 }}
                  >
                    <FormattedMessage id='workspace.realization.tooltip.modal.zzvora' />
                    <Icon name='barcode' />
                  </Button>
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
)

RealizationTimelineTooltip.propTypes = {
  id: PropTypes.number.isRequired,
  startedAt: PropTypes.object,
  finishedAt: PropTypes.object,
  project: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  product: PropTypes.shape({
    description: PropTypes.string,
    code: PropTypes.string,
    earlySapStage: PropTypes.string
  }),
  productionPlan: PropTypes.shape({
    priority: PropTypes.number.isRequired
  }),
  stoppageType: PropTypes.shape({
    symbol: PropTypes.string,
    description: PropTypes.string
  }),
  quantity: PropTypes.number,
  edit: PropTypes.func,
  destroy: PropTypes.func,
  duration: PropTypes.number.isRequired,
  engineeringDuration: PropTypes.number,
  setupDuration: PropTypes.number,
  character: Characters.propType,
  predefinedRemark: PropTypes.shape({
    description: PropTypes.string.isRequired
  }),
  isEditable: PropTypes.bool.isRequired,
  showBarcode: PropTypes.func,
  rawColor: PropTypes.string,
  intl: intlShape.isRequired
}

const mapStateToProps = (state, { id }) => {
  const realization = selectRealization(state, id)

  return {
    ...realization,
    ...selectRealizationDetails(state, id),
    isEditable: realization.editable && selectPermissions(state).realization.write
  }
}

const mapDispatchToProps = {
  edit: editRealization,
  destroy: destroyRealization,
  showBarcode: showProductBarcode
}

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(RealizationTimelineTooltip)
