import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, intlShape } from 'react-intl'
import AssociationSelect from 'components/AssociationSelect'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { selectAvailableUserGroups } from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormUserGroup = ({ id, intl, disabled, userGroups, onChange, formId }) => (
  <WorkspaceFormInput attribute='userGroup'>
    <AssociationSelect
      isClearable
      options={userGroups}
      value={id}
      onChange={(newId) => onChange({ userGroupId: newId })}
      labelMapper={userGroup => userGroup.name}
      isDisabled={disabled}
    />
  </WorkspaceFormInput>
)

WorkspaceFormUserGroup.propTypes = {
  intl: intlShape.isRequired,
  userGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  userGroups: selectAvailableUserGroups(state),
  id: state.form.data.userGroupId,
  formId: PropTypes.string,
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WorkspaceFormUserGroup)
