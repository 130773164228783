import { call, fork, put, select, delay } from 'redux-saga/effects'
import moment from 'moment-timezone'
import api from 'lib/api/client'
import { receiveSchedules, requestSchedules } from 'workspace/actions'
import {
  selectMode,
  selectToken,
  selectTimezone
} from 'workspace/selectors'
import { selectNavigationRange } from 'containers/Navigation/selectors'
import { WEEK } from 'containers/Navigation/const'
import { Modes } from 'workspace/const'

const planDecorator = (plan, timezone) => {
  if (!plan) {
    return plan
  } else {
    return {
      ...plan,
      date: moment.tz(plan.date, timezone)
    }
  }
}

const scheduleDecorator = ({ date, startsAt, endsAt, updatedAt, productionPlan, stoppagePlan, ...schedule }, timezone) => ({
  ...schedule,
  startsAt: moment.tz(startsAt, timezone),
  endsAt: moment.tz(endsAt, timezone),
  date: moment.tz(date, timezone),
  updatedAt: moment.tz(updatedAt, timezone),
  productionPlan: planDecorator(productionPlan, timezone),
  stoppagePlan: planDecorator(stoppagePlan, timezone)
})

const decorateSchedules = (schedules, timezone) => schedules.map(s => scheduleDecorator(s, timezone))

const callSchedulesAPI = ({ token, from, to, locationId, mode }) =>
  call(api.get, mode === Modes.FORMREINIGER ? '/anticipated_location_realizations' : '/location_schedules', {
    token,
    params: {
      from,
      to,
      locationId
    }
  })

export function * requestLocationSchedules (locationId) {
  yield put(requestSchedules(locationId))
}

export function * requestAllSchedules () {
  const ids = yield select(state => state.locations.selectedIds)

  for (var i = 0; i < ids.length; i++) {
    yield put(requestSchedules(ids[i]))
  }

  return true
}

export function * loadLocationSchedules (locationId) {
  const token = yield select(selectToken)
  const mode = yield select(selectMode)
  const { from, to } = yield select(selectNavigationRange, mode === Modes.REALIZATION ? { scope: WEEK } : {})
  const timezone = yield select(selectTimezone)

  const schedules = yield callSchedulesAPI({ token, from, to, locationId, mode })
  yield put(receiveSchedules({ locationId, schedules: decorateSchedules(schedules, timezone) }))
}

export function * loadAllSchedules () {
  // used together with takeLatest this creates a "debounce" effect to stop too many requests from running at once
  yield delay(250)

  const ids = yield select(state => state.locations.selectedIds)

  for (var i = 0; i < ids.length; i++) {
    yield fork(loadLocationSchedules, ids[i])
  }
}
