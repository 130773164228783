import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import RealizationHourlySummaryTimeline from 'workspace/RealizationHourlySummaryTimeline'
import WorkspaceLocationsEmpty from 'workspace/WorkspaceLocationsEmpty'
import { selectRealizationHourlySummaryAllowed } from './selectors'

const RealizationHourlySummaryTimelines = ({ ids, allowed }) => {
  if (!allowed) return null

  return (
    <div>
      {ids.map(id => <RealizationHourlySummaryTimeline key={id} id={id} />)}
      {ids.length === 0 && <WorkspaceLocationsEmpty />}
    </div>
  )
}

RealizationHourlySummaryTimelines.defaultProps = {
  ids: []
}

RealizationHourlySummaryTimelines.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  allowed: PropTypes.bool
}

const mapStateToProps = (state) => ({
  ids: state.locations.selectedIds,
  allowed: selectRealizationHourlySummaryAllowed(state)
})

export default connect(mapStateToProps)(RealizationHourlySummaryTimelines)
