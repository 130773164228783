import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { injectIntl, intlShape } from 'react-intl'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'
import { selectFormValue } from 'workspace/selectors'
import Select from 'components/Select'

const WorkspaceFormCreateError = ({ createError, onChange, style, formId, intl }) => {
  const options = [
    { label: intl.formatMessage({ id: 'workspace.form.createError.with' }), value: false },
    { label: intl.formatMessage({ id: 'workspace.form.createError.without' }), value: true }
  ]

  return (
    <WorkspaceFormInput attribute='createError' extraErrorsAttribute='createError' style={style} formId={formId}>
      <Select
        isClearable={false}
        backspaceRemovesValue={false}
        options={options}
        onChange={onChange}
        value={options.find(option => option.value === createError)}
      />
    </WorkspaceFormInput>
  )
}

WorkspaceFormCreateError.defaultProps = {
  createErrorType: 'createError'
}

WorkspaceFormCreateError.propTypes = {
  createError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  formId: PropTypes.string,
  intl: intlShape
}

const mapStateToProps = (state, { formId }) => {
  return ({
    createError: selectFormValue(state, 'createError', formId)
  })
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData({ createError: data.value }, ownProps.formId))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WorkspaceFormCreateError)
