import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/Icon'
import { connect } from 'react-redux'
import { selectShiftBookNote, selectShiftBookNoteIsRead } from './selectors'
import { editShiftBookNote, replyShiftBookNote, destroyShiftBookNote, markShiftBookNoteAsRead } from './actions'
import { FormattedMessage } from 'react-intl'

const RealizationMenuShiftBookNoteActions = ({
  onEdit,
  onReply,
  onDestroy,
  onRead,
  canUpdate,
  canDestroy,
  canReply,
  isRead
}) => {
  return (
    <div className='shift-book-note__actions'>
      {canDestroy && <Icon name='trash' className='shift-book-note__actions-icon' onClick={onDestroy} />}
      {canUpdate && <Icon name='edit' className='shift-book-note__actions-icon' onClick={onEdit} />}
      {canReply && <Icon name='reply' className='shift-book-note__actions-icon' onClick={onReply} />}
      {!isRead && (
        <div className='shift-book-note__read' onClick={onRead}>
          <Icon name='eye' className='shift-book-note__actions-icon' />
          <FormattedMessage id='workspace.menu.shiftBook.markAsRead'>
            {msg => <span className='shift-book-note__actions-text'>{msg}</span>}
          </FormattedMessage>
        </div>
      )}
    </div>
  )
}

RealizationMenuShiftBookNoteActions.propTypes = {
  onEdit: PropTypes.func,
  onReply: PropTypes.func,
  onDestroy: PropTypes.func,
  onRead: PropTypes.func,
  canUpdate: PropTypes.bool,
  canDestroy: PropTypes.bool,
  canReply: PropTypes.bool,
  isRead: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => {
  const { canUpdate, canDestroy, topicId } = selectShiftBookNote(state, ownProps.id)

  return {
    canReply: !topicId,
    canUpdate,
    canDestroy,
    isRead: selectShiftBookNoteIsRead(state, ownProps.id)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onEdit: () => dispatch(editShiftBookNote(ownProps.id)),
    onReply: () => dispatch(replyShiftBookNote(ownProps.id)),
    onDestroy: () => window.confirm() && dispatch(destroyShiftBookNote(ownProps.id)),
    onRead: () => dispatch(markShiftBookNoteAsRead(ownProps.id))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RealizationMenuShiftBookNoteActions)
