import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Color from "color";
import { selectSchedule, selectScheduleDetails } from "workspace/selectors";
import { Characters } from "lib/const";
import Unit from "components/Unit";
import PlanTimelineRealization from "workspace/PlanTimelineRealization";

const PlanTimelineBlockContent = ({
  id,
  character,
  project,
  productionPlan,
  stoppageType,
  stoppagePlan,
  onClick,
  rawColor,
  fresh
}) => {
  rawColor = rawColor && Color(rawColor);

  return (
    <div
      className="timeline-block__content"
      onClick={onClick}
      style={{
        borderColor: rawColor && rawColor.darken(0.5),
        background:
          rawColor && fresh
            ? `repeating-linear-gradient(to bottom, ${rawColor}, ${rawColor} 18.75px, ${rawColor.darken(
                0.4
              )} 18.75px, ${rawColor.darken(0.4)} 37.5px)`
            : rawColor,
      }}
    >
      {character === "changeover" && <div />}
      {character === Characters.PRODUCTION && (
        <React.Fragment>
          <p className="timeline-block__line">
            {productionPlan.priority}. {project.name}
          </p>
          <p className="timeline-block__line">
            <code>
              {productionPlan.quantity}x (
              <Unit value={productionPlan.piecesQuantity} unit="piece" />)
            </code>
          </p>
          <p className="timeline-block__line">
            <code>
              <PlanTimelineRealization {...productionPlan} />
            </code>
          </p>
        </React.Fragment>
      )}
      {character === Characters.STOPPAGE && (
        <React.Fragment>
          <p className="timeline-block__line">
            {stoppagePlan.priority}. {stoppageType.symbol}
          </p>
          <p className="timeline-block__line">
            <code>{stoppagePlan.duration} min.</code>
          </p>
        </React.Fragment>
      )}
    </div>
  );
};

PlanTimelineBlockContent.propTypes = {
  id: PropTypes.number.isRequired,
  character: Characters.propType,
  project: PropTypes.shape({
    name: PropTypes.string,
  }),
  productionPlan: PropTypes.shape({
    quantity: PropTypes.number,
    piecesQuantity: PropTypes.number,
    realizedQuantity: PropTypes.number,
    priority: PropTypes.number,
  }),
  stoppageType: PropTypes.shape({
    symbol: PropTypes.string,
  }),
  stoppagePlan: PropTypes.shape({
    duration: PropTypes.number,
    priority: PropTypes.number,
  }),
  onClick: PropTypes.func,
  rawColor: PropTypes.string,
};

const mapStateToProps = (state, { id }) => ({
  ...selectSchedule(state, id),
  ...selectScheduleDetails(state, id),
});

export default connect(mapStateToProps)(PlanTimelineBlockContent);
