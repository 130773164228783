import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import TimelineBlock from 'components/TimelineBlock'
import RealizationHourlySummaryTimelineBlockContent from 'workspace/RealizationHourlySummaryTimelineBlockContent'
import RealizationHourlySummaryTimelineTooltip from 'workspace/RealizationHourlySummaryTimelineTooltip'
import { selectRealizationHourlySummary } from 'workspace/selectors'

const RealizationHourlySummaryTimelineBlock = ({
  id,
  startedAt,
  finishedAt,
  timelineSize,
  productionLeft,
  productionRight,
  stoppageDuration,
  changeover,
  stoppage,
  scrap,
  ...rest
}) => {
  const size = changeover || stoppage ? 1 : 0.75
  const offset = changeover || stoppage ? 0 : 0.25

  return (
    <TimelineBlock
      {...rest}
      startsAt={startedAt}
      endsAt={finishedAt}
      timelineSize={timelineSize}
      color='secondary'
      size={size}
      offset={offset}
      stacked={!changeover && !stoppage}
    >
      <RealizationHourlySummaryTimelineBlockContent id={id} />
      <RealizationHourlySummaryTimelineTooltip id={id} />
    </TimelineBlock>
  )
}

RealizationHourlySummaryTimelineBlock.propTypes = {
  id: PropTypes.string.isRequired,
  startedAt: PropTypes.object.isRequired,
  finishedAt: PropTypes.object.isRequired,
  timelineSize: PropTypes.number.isRequired
}

const mapStateToProps = (state, { id }) => ({
  ...selectRealizationHourlySummary(state, id)
})

export default connect(mapStateToProps, null)(RealizationHourlySummaryTimelineBlock)
