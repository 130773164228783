import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DatePicker from 'components/DatePicker'
import CustomCalendarInput from 'components/CustomCalendarInput'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'
import { DATE_FORMAT } from 'lib/const'

const WorkspaceFormExtrusionDate = ({ date, onChange, formId }) => {
  if (!date) return null

  return (
    <WorkspaceFormInput attribute='extrusionDate' className='workspace-form__button-input' formId={formId}>
      <DatePicker
        className='workspace-form__input'
        selected={date}
        onChange={value => onChange({ extrusionDate: value })}
        dateFormat={DATE_FORMAT}
        customInput={<CustomCalendarInput
          selected={date}
          buttonProps={{ block: true }}
        />}
      />
    </WorkspaceFormInput>
  )
}

WorkspaceFormExtrusionDate.propTypes = {
  date: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  formId: PropTypes.string
}

const mapStateToProps = state => ({
  date: state.form.data.extrusionDate
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormExtrusionDate)
