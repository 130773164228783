import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import classnames from 'classnames'
import Input from 'components/Input'
import { selectFormErrors } from 'workspace/selectors'

const messages = defineMessages({
  changeoverDuration: {
    id: 'workspace.form.changeoverDuration',
    defaultMessage: 'Changeover duration',
    description: 'Workspace form input label'
  },
  createError: {
    id: 'workspace.form.createError',
    defaultMessage: 'Errors',
    description: 'Workspace form input label'
  },
  component: {
    id: 'workspace.form.component',
    defaultMessage: 'Component',
    description: 'Workspace form input label'
  },
  data: {
    id: 'workspace.form.data',
    defaultMessage: 'Date',
    description: 'Workspace form input label'
  },
  date: {
    id: 'workspace.form.date',
    defaultMessage: 'Date',
    description: 'Workspace form input label'
  },
  description: {
    id: 'workspace.form.description',
    defaultMessage: 'Description',
    description: 'Workspace form input label'
  },
  division: {
    id: 'workspace.form.division',
    defaultMessage: 'Breakdown type',
    description: 'Workspace form input label'
  },
  duration: {
    id: 'workspace.form.duration',
    defaultMessage: 'Duration',
    description: 'Workspace form input label'
  },
  engineeringFinishedAt: {
    id: 'workspace.form.engineeringFinishedAt',
    defaultMessage: 'Eng. end / setup',
    description: 'Workspace form input label'
  },
  extrusionDate: {
    id: 'workspace.form.extrusionDate',
    defaultMessage: 'Extrusion date',
    description: 'Workspace form input label'
  },
  finishedAt: {
    id: 'workspace.form.finishedAt',
    defaultMessage: 'Finish',
    description: 'Workspace form input label'
  },
  location: {
    id: 'workspace.form.location',
    defaultMessage: 'Location',
    description: 'Workspace form input label'
  },
  manualDuration: {
    id: 'workspace.form.manualDuration',
    defaultMessage: 'Manual duration',
    description: 'Workspace form input label'
  },
  priority: {
    id: 'workspace.form.priority',
    defaultMessage: 'Priority',
    description: 'Workspace form input label'
  },
  product: {
    id: 'workspace.form.product',
    defaultMessage: 'Product',
    description: 'Workspace form input label'
  },
  productLeft: {
    id: 'workspace.form.productLeft',
    defaultMessage: 'Product (left side)',
    description: 'Workspace form input label'
  },
  productRight: {
    id: 'workspace.form.productRight',
    defaultMessage: 'Product (right side)',
    description: 'Workspace form input label'
  },
  project: {
    id: 'workspace.form.project',
    defaultMessage: 'Project',
    description: 'Workspace form input label'
  },
  productionPlan: {
    id: 'workspace.form.productionPlan',
    defaultMessage: 'Production Plan',
    description: 'Workspace form input label'
  },
  qualityController: {
    id: 'workspace.form.qualityController',
    defaultMessage: 'Quality controller',
    description: 'Workspace form input label'
  },
  quantity: {
    id: 'workspace.form.quantity',
    defaultMessage: 'Quantity',
    description: 'Workspace form input label'
  },
  quantityLeft: {
    id: 'workspace.form.quantityLeft',
    defaultMessage: 'Quantity (left side)',
    description: 'Workspace form input label'
  },
  quantityRight: {
    id: 'workspace.form.quantityRight',
    defaultMessage: 'Quantity (right side)',
    description: 'Workspace form input label'
  },
  remarks: {
    id: 'workspace.form.remarks',
    defaultMessage: 'Remarks',
    description: 'Workspace form input label'
  },
  predefinedRemark: {
    id: 'workspace.form.predefinedRemark',
    defaultMessage: 'Remarks',
    description: 'Workspace form input label'
  },
  reportedAt: {
    id: 'workspace.form.reportedAt',
    defaultMessage: 'Reported at',
    description: 'Workspace form input label'
  },
  resolution: {
    id: 'workspace.form.resolution',
    defaultMessage: 'Resolution',
    description: 'Workspace form input label'
  },
  startedAt: {
    id: 'workspace.form.startedAt',
    defaultMessage: 'Start',
    description: 'Workspace form input label'
  },
  scrapType: {
    id: 'workspace.form.scrapType',
    defaultMessage: 'Scrap type',
    description: 'Workspace form input label'
  },
  stoppageType: {
    id: 'workspace.form.stoppageType',
    defaultMessage: 'Stoppage type',
    description: 'Workspace form input label'
  },
  userGroup: {
    id: 'workspace.form.userGroup',
    defaultMessage: 'User group',
    description: 'Workspace form input label'
  }
})

const Sizes = {
  changeoverDuration: 1,
  date: null,
  description: 3,
  division: 2,
  duration: 1,
  engineeringFinishedAt: 1,
  finishedAt: 1,
  location: null,
  manualDuration: 1,
  predefinedRemark: null,
  priority: 1,
  product: 3,
  project: 2,
  qualityController: null,
  quantity: 1,
  remarks: null,
  resolution: 3,
  startedAt: 1,
  stoppageType: 3
}

const WorkspaceFormInput = ({ intl, attribute, errors, className, children, style, label, formId }) => (
  <Input
    nested
    label={label || intl.formatMessage(messages[attribute])}
    error={(errors || [])[0]}
    className={classnames('workspace-form__control', className)}
    style={{ ...style, flexGrow: Sizes[attribute] }}
    children={children}
  />
)

WorkspaceFormInput.defaultProps = {
  errors: []
}

WorkspaceFormInput.propTypes = {
  attribute: PropTypes.string.isRequired,
  extraErrorsAttribute: PropTypes.string,
  errors: PropTypes.array,
  intl: intlShape,
  className: PropTypes.any,
  children: PropTypes.any,
  style: PropTypes.object,
  label: PropTypes.string,
  formId: PropTypes.string
}

const mapStateToProps = (state, { attribute, extraErrorsAttribute, formId }) => {
  return {
    errors: [
      ...(selectFormErrors(state, formId)[attribute] || []),
      ...(selectFormErrors(state, formId)[attribute + 'Id'] || []),
      ...(selectFormErrors(state, formId)[extraErrorsAttribute] || [])
    ]
  }
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(WorkspaceFormInput)
