import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'
import Unit from 'components/Unit'
import {
  selectRealizationSummary,
  selectRealizationSummaryProject,
  selectRealizationSummaryDuration
} from 'workspace/selectors'
import { DATETIME_FORMAT } from 'lib/const'
import { formatDuration } from 'workspace/lib'

const RealizationSummaryTimelineTooltip = ({
  changeover,
  duration,
  finishedAt,
  id,
  productionLeft,
  productionRight,
  productionUnspecified,
  project,
  scrap,
  startedAt,
  stoppageDuration,
  plannedStoppageDuration,
  unplannedStoppageDuration
}) => {
  if (!project) {
    return null
  }

  return (
    <div
      className={classnames('timeline-tooltip', `timeline-tooltip--secondary`)}
    >
      <div className='timeline-tooltip__content'>
        <table className='timeline-tooltip__table'>
          <tbody>
            <tr>
              <th />
              <th>
                {changeover ? (
                  <FormattedMessage
                    id='workspace.realizationSummary.tooltip.changeover'
                    defaultMessage='Changeover'
                  />
                ) : (
                  project.name
                )}
              </th>
            </tr>
            {!changeover && (
              <React.Fragment>
                {!productionUnspecified && (
                  <tr>
                    <th>
                      <FormattedMessage
                        id='workspace.realizationSummary.tooltip.productionLeft'
                        defaultMessage='Left'
                      />
                      :
                    </th>
                    <td>
                      <code>
                        <Unit value={productionLeft} unit='piece' />
                      </code>
                    </td>
                  </tr>
                )}
                {!productionUnspecified && (
                  <tr>
                    <th>
                      <FormattedMessage
                        id='workspace.realizationSummary.tooltip.productionRight'
                        defaultMessage='Right'
                      />
                      :
                    </th>
                    <td>
                      <code>
                        <Unit value={productionRight} unit='piece' />
                      </code>
                    </td>
                  </tr>
                )}
                {productionUnspecified !== 0 && (
                  <tr>
                    <th>
                      <FormattedMessage
                        id='workspace.realizationSummary.tooltip.productionUnspecified'
                        defaultMessage='Production'
                      />
                      :
                    </th>
                    <td>
                      <code>
                        <Unit value={productionUnspecified} unit='piece' />
                      </code>
                    </td>
                  </tr>
                )}
                <tr>
                  <th>
                    <FormattedMessage
                      id='workspace.realizationSummary.tooltip.stoppageDuration'
                      defaultMessage='Stoppage'
                    />
                    :
                  </th>
                  <td>
                    <code>
                      <Unit value={stoppageDuration.toFixed(0)} unit='minute' />
                    </code>
                  </td>
                </tr>
                <tr>
                  <th>
                    <FormattedMessage
                      id='workspace.realizationSummary.tooltip.plannedStoppageDuration'
                      defaultMessage='Planned stoppage'
                    />
                    :
                  </th>
                  <td>
                    <code>
                      <Unit
                        value={plannedStoppageDuration.toFixed(0)}
                        unit='minute'
                      />
                    </code>
                  </td>
                </tr>
                <tr>
                  <th>
                    <FormattedMessage
                      id='workspace.realizationSummary.tooltip.unplannedStoppageDuration'
                      defaultMessage='Unplanned stoppage'
                    />
                    :
                  </th>
                  <td>
                    <code>
                      <Unit
                        value={unplannedStoppageDuration.toFixed(0)}
                        unit='minute'
                      />
                    </code>
                  </td>
                </tr>
                <tr>
                  <th>
                    <FormattedMessage
                      id='workspace.realizationSummary.tooltip.scrap'
                      defaultMessage='Scrap'
                    />
                    :
                  </th>
                  <td>
                    <code>
                      <Unit value={scrap} unit='piece' />
                    </code>
                  </td>
                </tr>
              </React.Fragment>
            )}
            <tr>
              <th>
                <FormattedMessage
                  id='workspace.realizationSummary.tooltip.duration'
                  defaultMessage='Duration'
                />
                :
              </th>
              <td>
                <code>
                  <Unit value={formatDuration(duration)} unit='minute' />
                </code>
              </td>
            </tr>
            <tr>
              <th>
                <FormattedMessage
                  id='workspace.realizationSummary.tooltip.start'
                  defaultMessage='Start'
                />
                :
              </th>
              <td>
                <code>{startedAt.format(DATETIME_FORMAT)}</code>
              </td>
            </tr>
            <tr>
              <th>
                <FormattedMessage
                  id='workspace.realizationSummary.tooltip.end'
                  defaultMessage='End'
                />
                :
              </th>
              <td>
                <code>{finishedAt.format(DATETIME_FORMAT)}</code>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

RealizationSummaryTimelineTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  startedAt: PropTypes.object,
  finishedAt: PropTypes.object,
  project: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  productionLeft: PropTypes.number,
  productionRight: PropTypes.number,
  productionUnspecified: PropTypes.number,
  stoppageDuration: PropTypes.number,
  plannedStoppageDuration: PropTypes.number,
  unplannedStoppageDuration: PropTypes.number,
  scrap: PropTypes.number,
  duration: PropTypes.number.isRequired,
  changeover: PropTypes.bool.isRequired
}

const mapStateToProps = (state, { id }) => ({
  ...selectRealizationSummary(state, id),
  project: selectRealizationSummaryProject(state, id),
  duration: selectRealizationSummaryDuration(state, id)
})

export default connect(mapStateToProps)(RealizationSummaryTimelineTooltip)
