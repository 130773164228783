import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modal from 'components/Modal'
import { hideProductBarcode } from 'workspace/actions'
import { selectBarcodeModalType, selectBarcodeModalProduct } from 'workspace/selectors'
import { BarcodeModalType } from 'workspace/const'
import WorkspaceBarcodeKanban from './WorkspaceBarcodeKanban'
import WorkspaceBarcodeRemainingQuantity from './WorkspaceBarcodeRemainingQuantity'
import WorkspaceBarcodeZZVORA from './WorkspaceBarcodeZZVORA'

const modals = {
  [BarcodeModalType.KANBAN_CARD]: WorkspaceBarcodeKanban,
  [BarcodeModalType.REMAINING_QUANTITY]: WorkspaceBarcodeRemainingQuantity,
  [BarcodeModalType.ZZVORA]: WorkspaceBarcodeZZVORA,
}

const WorkspaceBarcodeModal = ({ type, product, hide }) => {
  if (type && product && modals[type]) {
    const Component = modals[type]
    const backgroundColor = `#${product.labelColor}`
    return (
      <Modal onClose={hide} className='label-modal' style={{ backgroundColor }}>
        <Component product={product} />
      </Modal>
    )
  } else {
    return null
  }
}

WorkspaceBarcodeModal.propTypes = {
  type: BarcodeModalType.propType,
  product: PropTypes.shape({
    labelColor: PropTypes.string.isRequired
  })
}

const mapStateToProps = state => ({
  type: selectBarcodeModalType(state),
  product: selectBarcodeModalProduct(state)
})

const mapDispatchToProps = ({
  hide: () => hideProductBarcode()
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceBarcodeModal)
