import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Button from 'components/Button'

class ConfirmableButton extends React.Component {
  state = {
    clicked: false,
    timer: 3
  }

  handleClick = e => {
    if (this.state.clicked) {
      this.props.onClick(e)
    } else {
      e.preventDefault()
      this.setState({ clicked: true, timer: 3 })
      this._timeout = setTimeout(this.tick, 1000)
    }
  }

  tick = () => {
    if (this.state.clicked && this.state.timer <= 0) {
      this.setState({ clicked: false, timer: 3 })
      delete this._timeout
    } else if (this.state.clicked) {
      this.setState({ clicked: true, timer: this.state.timer - 1 })
      this._timeout = setTimeout(this.tick, 1000)
    }
  }

  componentWillUnmount () {
    this._timeout && clearTimeout(this._timeout)
    delete this._timeout
  }

  render () {
    return (
      <Button {...this.props} onClick={this.handleClick}>
        { this.state.clicked && this.props.confirmationMessage }
        {
          this.state.clicked &&
          !this.props.confirmationMessage &&
          <FormattedMessage
            id='components.button.confirmationMessage'
            defaultMessage='Click again to confirm'
            description='Confirmation message for buttons' />
        }

        {
          !this.state.clicked &&
          this.props.children
        }

        { this.state.clicked && '.'.repeat(3 - this.state.timer) }
      </Button>
    )
  }
}

ConfirmableButton.defaultProps = {
  onClick: () => {}
}

ConfirmableButton.propTypes = {
  onClick: PropTypes.func,
  confirmationMessage: PropTypes.string
}

export default ConfirmableButton
