import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  injectIntl,
  intlShape,
  FormattedMessage,
  defineMessages
} from 'react-intl'
import classnames from 'classnames'
import Color from 'color'
import ConfirmableButton from 'components/ConfirmableButton'
import {
  createChangeoverChange,
  destroyChangeoverChange
} from 'workspace/actions'
import {
  selectChangeover,
  selectChangeoverDetails,
  selectPermissions
} from 'workspace/selectors'
import { Characters, DATETIME_FORMAT } from 'lib/const'

const messages = defineMessages({
  deleteConfirmation: {
    id: 'workspace.realization.tooltip.deleteConfirmation',
    defaultMessage: 'sure?',
    description: 'Confirmation message displayed when deleting a realization'
  }
})

const CHARACTER_COLOR = {
  [Characters.PRODUCTION]: 'success',
  [Characters.STOPPAGE]: 'danger',
  [Characters.CHANGEOVER]: 'secondary'
}

const FormreinigerTimelineTooltip = ({
  id,
  startsAt,
  endsAt,
  project,
  locationId,
  productionPlan,
  changeoverChange,
  create,
  destroy,
  character,
  rawColor,
  intl,
  location,
  isEditable
}) => (
  <div
    className={classnames(
      'timeline-tooltip',
      `timeline-tooltip--${CHARACTER_COLOR[character]}`
    )}
  >
    <div
      className='timeline-tooltip__content'
      style={{ borderColor: rawColor && Color(rawColor).darken(0.5) }}
    >
      <table className='timeline-tooltip__table'>
        <tbody>
          <tr>
            <th />
            <th>
              {character === Characters.CHANGEOVER && (
                <FormattedMessage
                  id='workspace.realization.tooltip.changeover'
                  defaultMessage='Changeover'
                />
              )}
            </th>
          </tr>
          {productionPlan && (
            <tr>
              <th>
                <FormattedMessage
                  id='workspace.realization.tooltip.productionPlan'
                  defaultMessage='Plan'
                />
                :
              </th>
              <td>
                {productionPlan.priority}. {project.name}
              </td>
            </tr>
          )}
          <tr>
            <th>
              <FormattedMessage
                id='workspace.form.location'
                defaultMessage='Location'
              />
              :
            </th>
            <td>{location.symbol}</td>
          </tr>
          <tr>
            <th>
              <FormattedMessage
                id='workspace.realization.tooltip.start'
                defaultMessage='Start'
              />
              :
            </th>
            <td>
              <code>{startsAt.format(DATETIME_FORMAT)}</code>
            </td>
          </tr>
          <tr>
            <th>
              <FormattedMessage
                id='workspace.realization.tooltip.end'
                defaultMessage='End'
              />
              :
            </th>
            <td>
              <code>{endsAt.format(DATETIME_FORMAT)}</code>
            </td>
          </tr>
          <tr>
            <td />
            <td>
              {
                isEditable && (
                  changeoverChange ? (
                    <ConfirmableButton
                      size='small'
                      onClick={e => destroy({
                        changeoverId: id,
                        changeoverChangeId: changeoverChange.id,
                        locationId: locationId
                      })}
                      className='fa-pull-right'
                      style={{ marginLeft: 5 }}
                      confirmationMessage={intl.formatMessage(
                        messages.deleteConfirmation
                      )}
                    >
                      <FormattedMessage
                        id='workspace.formreiniger.tooltip.revert'
                        defaultMessage='Revert'
                      />
                      &nbsp;
                    </ConfirmableButton>
                  ) : (
                    <ConfirmableButton
                      size='small'
                      className='btn--success fa-pull-right'
                      onClick={e => create({
                        changeoverId: id,
                        locationId: locationId,
                        startedAt: startsAt,
                        finishedAt: endsAt
                      })}
                      style={{ marginLeft: 5 }}
                      confirmationMessage={intl.formatMessage(
                        messages.deleteConfirmation
                      )}
                    >
                      <FormattedMessage
                        id='workspace.formreiniger.tooltip.finish'
                        defaultMessage='Finish'
                      />
                      &nbsp;
                    </ConfirmableButton>
                  )
                )
              }
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
)

FormreinigerTimelineTooltip.propTypes = {
  id: PropTypes.number.isRequired,
  startsAt: PropTypes.object,
  endsAt: PropTypes.object,
  project: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  product: PropTypes.shape({
    description: PropTypes.string,
    code: PropTypes.string
  }),
  productionPlan: PropTypes.shape({
    priority: PropTypes.number.isRequired
  }),
  stoppageType: PropTypes.shape({
    symbol: PropTypes.string,
    description: PropTypes.string
  }),
  locationId: PropTypes.number,
  changeoverChange: PropTypes.object,
  create: PropTypes.func,
  destroy: PropTypes.func,
  character: Characters.propType,
  predefinedRemark: PropTypes.shape({
    description: PropTypes.string.isRequired
  }),
  rawColor: PropTypes.string,
  intl: intlShape.isRequired,
  location: PropTypes.object,
  isEditable: PropTypes.bool
}

const mapStateToProps = (state, { id }) => ({
  ...selectChangeover(state, id),
  ...selectChangeoverDetails(state, id),
  isEditable: selectPermissions(state).formreiniger.write
})

const mapDispatchToProps = {
  create: createChangeoverChange,
  destroy: destroyChangeoverChange
}

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(FormreinigerTimelineTooltip)
