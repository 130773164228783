import React from 'react'
import PropTypes from 'prop-types'
import Select from 'components/Select'

const findById = (records, id) => records.find(record => record.id === Number(id))

const AssociationSelect = ({ options: records, labelMapper, value: id, onChange, ...props }) => {
  const optionMapper = (record) => (
    record && record.id ? { value: record.id, label: labelMapper(record), data: record } : {}
  )

  return (
    <Select
      {...props}
      onChange={(selected, action) => onChange(
        selected ? selected.value : selected,
        action,
        selected ? selected.data : selected
      )}
      value={optionMapper(findById(records, id))}
      options={records.map(optionMapper)}
    />
  )
}

AssociationSelect.defaultProps = {
  labelMapper: record => record.name,
  options: []
}

AssociationSelect.propTypes = {
  labelMapper: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object)
}

export default AssociationSelect
