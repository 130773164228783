import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'
import Unit from 'components/Unit'
import {
  selectRealizationHourlySummary,
  selectRealizationHourlySummaryProject,
  selectRealizationHourlySummaryDuration
} from 'workspace/selectors'
import { DATETIME_FORMAT } from 'lib/const'
import { formatDuration } from 'workspace/lib'

const RealizationHourlySummaryTimelineTooltip = ({
  changeover,
  finishedAt,
  id,
  realizedQuantity,
  plannedQuantity,
  operatorsCount,
  duration,
  project,
  startedAt,
  stoppage
}) => {
  if (!project && !stoppage) {
    return null
  }

  const isProduction = !changeover && !stoppage

  return (
    <div
      className={classnames('timeline-tooltip', `timeline-tooltip--secondary`)}
    >
      <div className='timeline-tooltip__content'>
        <table className='timeline-tooltip__table'>
          <tbody>
            <tr>
              <th />
              <th>
                {changeover && (
                  <FormattedMessage
                    id='workspace.realizationSummary.tooltip.changeover'
                  />
                ) }
                {stoppage && (
                  <FormattedMessage
                    id='workspace.realizationSummary.tooltip.stoppage'
                  />
                )}
                {isProduction && (
                  project.name
                )}
              </th>
            </tr>
            {isProduction && (
              <React.Fragment>
                <tr>
                  <th>
                    <FormattedMessage
                      id='workspace.realizationSummary.tooltip.plannedQuantity'
                    />
                    :
                  </th>
                  <td>
                    <code>
                      <Unit value={plannedQuantity} unit='piece' />
                    </code>
                  </td>
                </tr>
                <tr>
                  <th>
                    <FormattedMessage
                      id='workspace.realizationSummary.tooltip.realizedQuantity'
                    />
                    :
                  </th>
                  <td>
                    <code>
                      <Unit value={realizedQuantity} unit='piece' />
                    </code>
                  </td>
                </tr>
              </React.Fragment>
            )}
            <tr>
              <th>
                <FormattedMessage
                  id='workspace.realizationSummary.tooltip.operatorsCount'
                />
                :
              </th>
              <td>
                <code>
                  {operatorsCount}
                </code>
              </td>
            </tr>
            <tr>
              <th>
                <FormattedMessage
                  id='workspace.realizationSummary.tooltip.duration'
                  defaultMessage='Duration'
                />
                :
              </th>
              <td>
                <code>
                  <Unit value={formatDuration(duration)} unit='minute' />
                </code>
              </td>
            </tr>
            <tr>
              <th>
                <FormattedMessage
                  id='workspace.realizationSummary.tooltip.start'
                  defaultMessage='Start'
                />
                :
              </th>
              <td>
                <code>{startedAt.format(DATETIME_FORMAT)}</code>
              </td>
            </tr>
            <tr>
              <th>
                <FormattedMessage
                  id='workspace.realizationSummary.tooltip.end'
                  defaultMessage='End'
                />
                :
              </th>
              <td>
                <code>{finishedAt.format(DATETIME_FORMAT)}</code>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

RealizationHourlySummaryTimelineTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  startedAt: PropTypes.object,
  finishedAt: PropTypes.object,
  project: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  changeover: PropTypes.bool.isRequired,
  stoppage: PropTypes.bool,
  duration: PropTypes.number.isRequired,
  realizedQuantity: PropTypes.number,
  plannedQuantity: PropTypes.number,
  operatorsCount: PropTypes.number
}

const mapStateToProps = (state, { id }) => ({
  ...selectRealizationHourlySummary(state, id),
  project: selectRealizationHourlySummaryProject(state, id),
  duration: selectRealizationHourlySummaryDuration(state, id)
})

export default connect(mapStateToProps)(RealizationHourlySummaryTimelineTooltip)
