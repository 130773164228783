import { call, put, select } from 'redux-saga/effects'
import api from 'lib/api/client'
import { receiveActiveness, addActivenessErrors, deleteFinishedActiveness } from 'workspace/actions'
import { selectToken } from 'workspace/selectors'
import { loadLocationRealizations } from 'workspace/saga/realizations'

export function * start ({ payload: params }) {
  const token = yield select(selectToken)

  try {
    const activeness = yield call(api.post, 'activeness_periods', params, { token })
    yield put(receiveActiveness(activeness))
    yield call(loadLocationRealizations, activeness.locationId)
  } catch (e) {
    if (e.response && e.response.status === 422 && e.response.data) {
      yield put(addActivenessErrors(e.response.data.errors))
    } else {
      throw e
    }
  }
}

export function * finish ({ payload: id }) {
  const token = yield select(selectToken)
  const activeness = yield call(api.put, 'activeness_periods/' + id + '/finish', {}, { token })
  yield put(deleteFinishedActiveness(activeness))
}
