import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormDescription = ({ description, onChange, disabled, formId }) => (
  <WorkspaceFormInput attribute='description'>
    <input
      type='text'
      className='workspace-form__input'
      value={description}
      onChange={(e) => onChange({ description: e.target.value })}
      disabled={disabled}
    />
  </WorkspaceFormInput>
)

WorkspaceFormDescription.propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  formId: PropTypes.string
}

const mapStateToProps = state => ({
  description: state.form.data.description
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormDescription)
