import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceForms from 'workspace/WorkspaceForms'
import WorkspaceAddSubform from 'workspace/WorkspaceAddSubform'
import WorkspaceSapImportLegend from 'workspace/WorkspaceSapImportLegend'
import WorkspaceLocations from 'workspace/WorkspaceLocations'
import WorkspaceMenu from 'workspace/WorkspaceMenu'
import WorkspaceNavigation from 'workspace/WorkspaceNavigation'
import WorkspaceTimelines from 'workspace/WorkspaceTimelines'
import WorkspaceBarcodeModal from 'workspace/WorkspaceBarcodeModal'
import WorkspaceMeasurementModal from 'workspace/WorkspaceMeasurementModal'
import Loader from 'components/Loader'
import { selectPermissions, selectMode } from 'workspace/selectors'

const Workspace = ({ mode, isLoaded, isFormActive, isFormAvailable }) => {
  if (isLoaded) {
    return (
      <React.Fragment>
        {
          isFormAvailable &&
          <div className='workspace__menu'>
            {isFormActive ? <WorkspaceForms /> : <WorkspaceMenu />}
            <WorkspaceSapImportLegend />
            <WorkspaceAddSubform />
          </div>
        }

        <div className='workspace__submenu'>
          <div className='workspace__locations'>
            <WorkspaceLocations />
          </div>
          <div className='workspace__navigation'>
            <WorkspaceNavigation />
          </div>
        </div>

        <WorkspaceTimelines />
        <WorkspaceBarcodeModal />
        <WorkspaceMeasurementModal />
      </React.Fragment>
    )
  } else {
    return <Loader full />
  }
}

Workspace.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  isFormActive: PropTypes.bool.isRequired,
  isFormAvailable: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  isLoaded: state.init.isFinished,
  isFormActive: state.form.isActive,
  isFormAvailable: selectPermissions(state)[selectMode(state)].write
})

export default connect(mapStateToProps)(Workspace)
