import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormTypes } from 'workspace/const'
import { intlShape, injectIntl } from 'react-intl'

const WorkspaceSapImportLegend = ({ formType, intl }) => {
  if (formType !== FormTypes.SAP_IMPORT) {
    return null
  }

  return (
    <div
      className='workspace__extra'
      style={{ justifyContent: 'center' }}
    >
      <table
        className='table table--striped'
        style={{ width: 'calc(33% - 30px)', transform: 'translateX(-47px)' }}
      >
        <tbody>
          <tr>
            <td>A</td>
            <td>B</td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: 'workspace.form.product' })}</td>
            <td>{intl.formatMessage({ id: 'workspace.form.quantity' })}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

WorkspaceSapImportLegend.propTypes = {
  formType: PropTypes.string,
  intl: intlShape
}

const mapStateToProps = state => ({
  formType: state.form.type
})

export default compose(connect(mapStateToProps), injectIntl)(WorkspaceSapImportLegend)
