import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'
import { selectFormValue } from 'workspace/selectors'

class WorkspaceFormData extends React.Component {
  state = {
    fileName: ''
  }

  constructor (props) {
    super(props)
    this.inputRef = React.createRef()
  }

  onChange = () => {
    if (this.inputRef.current) {
      if (this.inputRef.current.files[0]) {
        const file = this.inputRef.current.files[0]
        this.setState({ fileName: file.name })

        const fileReader = new window.FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          this.props.onChange({ data: fileReader.result, fileName: file.name })
        }
      } else {
        this.setState({ fileName: '' })
        this.props.onChange({ data: undefined, fileName: undefined })
      }
    }
  }

  render () {
    const { disabled, formId } = this.props

    return (
      <WorkspaceFormInput attribute='data' formId={formId}>
        <div className='workspace-form__file-container'>
          <div className='workspace-form__file'>{this.state.fileName}</div>
          <input
            type='file'
            className='workspace-form__input workspace-form__input--file'
            disabled={disabled}
            ref={this.inputRef}
            onChange={this.onChange}
          />
        </div>
      </WorkspaceFormInput>
    )
  }
}

WorkspaceFormData.propTypes = {
  data: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  formId: PropTypes.string
}

const mapStateToProps = (state, { formId }) => ({
  data: selectFormValue(state, 'data', formId)
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormData)
