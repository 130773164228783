import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import classnames from 'classnames'
import Button from 'components/Button'
import Icon from 'components/Icon'
import WorkspaceFormChangeoverDuration from 'workspace/WorkspaceFormChangeoverDuration'
import WorkspaceFormData from 'workspace/WorkspaceFormData'
import WorkspaceFormDate from 'workspace/WorkspaceFormDate'
import WorkspaceFormDescription from 'workspace/WorkspaceFormDescription'
import WorkspaceFormDivision from 'workspace/WorkspaceFormDivision'
import WorkspaceFormDuration from 'workspace/WorkspaceFormDuration'
import WorkspaceFormEngineeringFinishedAt from 'workspace/WorkspaceFormEngineeringFinishedAt'
import WorkspaceFormFinishedAt from 'workspace/WorkspaceFormFinishedAt'
import WorkspaceFormLocation from 'workspace/WorkspaceFormLocation'
import WorkspaceFormManualDuration from 'workspace/WorkspaceFormManualDuration'
import WorkspaceFormPredefinedRemark from 'workspace/WorkspaceFormPredefinedRemark'
import WorkspaceFormPriority from 'workspace/WorkspaceFormPriority'
import WorkspaceFormProduct from 'workspace/WorkspaceFormProduct'
import WorkspaceFormQualityController from 'workspace/WorkspaceFormQualityController'
import WorkspaceFormProductionPlan from 'workspace/WorkspaceFormProductionPlan'
import WorkspaceFormProductReadonly from 'workspace/WorkspaceFormProductReadonly'
import WorkspaceFormProject from 'workspace/WorkspaceFormProject'
import WorkspaceFormQuantity from 'workspace/WorkspaceFormQuantity'
import WorkspaceFormRemarks from 'workspace/WorkspaceFormRemarks'
import WorkspaceFormReportedAt from 'workspace/WorkspaceFormReportedAt'
import WorkspaceFormResolution from 'workspace/WorkspaceFormResolution'
import WorkspaceFormScrapType from 'workspace/WorkspaceFormScrapType'
import WorkspaceFormStartedAt from 'workspace/WorkspaceFormStartedAt'
import WorkspaceFormStoppageType from 'workspace/WorkspaceFormStoppageType'
import WorkspaceFormExtrusionDate from 'workspace/WorkspaceFormExtrusionDate'
import WorkspaceFormUserGroup from 'workspace/WorkspaceFormUserGroup'
import WorkspaceFormCreateError from 'workspace/WorkspaceFormCreateError'
import WorkspaceFormComponent from 'workspace/WorkspaceFormComponent'
import { selectLocations, selectIsFormMultiline, selectMode, selectFormErrors } from 'workspace/selectors'
import { closeForm, submitForm } from 'workspace/actions'
import { DATE_FORMAT } from 'lib/const'
import { FormTypes, Modes } from 'workspace/const'

const messages = defineMessages({
  productionScheduled: {
    id: 'workspace.form.productionScheduled',
    defaultMessage: 'Production scheduled at location {location} on {from} - {to}',
    description: 'Flash message that appears after successful submission'
  },
  stoppageScheduled: {
    id: 'workspace.form.stoppageScheduled',
    defaultMessage: 'Stopppage scheduled at location {location} on {from} - {to}',
    description: 'Flash message that appears after successful submission'
  }
})

function configureField (flags) {
  return {
    [FormTypes.PRODUCTION]: flags[0] === 1,
    [FormTypes.STOPPAGE]: flags[1] === 1,
    [FormTypes.REALIZATION]: flags[2] === 1,
    [FormTypes.REALIZATION_STOPPAGE]: flags[3] === 1,
    [FormTypes.REALIZATION_CHANGEOVER]: flags[4] === 1,
    [FormTypes.REALIZATION_SCRAP]: flags[5] === 1,
    [FormTypes.REALIZATION_UNREPORTED]: flags[6] === 1,
    [FormTypes.BREAKDOWN_START]: flags[7] === 1,
    [FormTypes.BREAKDOWN_FINISH]: flags[8] === 1,
    [FormTypes.SAP_IMPORT]: flags[9] === 1,
    [FormTypes.FRESH_PROJECT_NOTIFICATION]: flags[10] === 1
  }
}

// P - production
// S - stoppage
// R - realization
// RS - realization stoppage
// RC - realization changeover
// RR - realization scrap
// RU - realization unreported
// BS - breakdown start
// BF - breakdown finish
// I - SAP Import
// FP - Fresh project notification
// ---------------> P  S  R  RS RC RR RU BS BF I FP
const FIELDS = {
  location:
    configureField([1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0]),
  project:
    configureField([1, 0, 0, 1, 1, 0, 1, 0, 0, 0, 0]),
  productionPlan:
    configureField([0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0]),
  product:
    configureField([0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0]),
  createError:
    configureField([0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0]),
  qualityController:
    configureField([0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0]),
  extrusionDate:
    configureField([0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0]),
  stoppageType:
    configureField([0, 1, 0, 1, 0, 0, 1, 0, 0, 0, 0]),
  scrapType:
    configureField([0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0]),
  remarks:
    configureField([1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0]),
  predefinedRemark:
    configureField([0, 0, 0, 1, 1, 0, 1, 0, 0, 0, 0]),
  date:
    configureField([1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0]),
  quantity:
    configureField([1, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0]),
  duration:
    configureField([0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0]),
  manualDuration:
    configureField([1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]),
  changeoverDuration:
    configureField([1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]),
  priority:
    configureField([1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0]),
  startedAt:
    configureField([0, 0, 0, 1, 1, 0, 1, 0, 0, 0, 0]),
  engineeringFinishedAt:
    configureField([0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0]),
  finishedAt:
    configureField([0, 0, 0, 1, 1, 0, 1, 0, 0, 0, 0]),
  reportedAt:
    configureField([0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0]),
  division:
    configureField([0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0]),
  userGroup:
    configureField([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1]),
  description:
    configureField([0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 1]),
  resolution:
    configureField([0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0]),
  data:
    configureField([0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0]),
  component:
    configureField([0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0])
}

const ActionButtons = ({ submit, cancel, isMultiline, isLoading, allowSubmit }) => (
  <div className={classnames('workspace-form__menu', { 'workspace-form__menu--centered': isMultiline })}>
    {allowSubmit && (
      <Button onClick={submit} color='success' disabled={isLoading} isLoading={isLoading}>
        <Icon name='check' />
      </Button>
    )}
    {!allowSubmit && (
      <div className='workspace-form__button-placeholder' />
    )}
    <Button onClick={cancel} color='danger' disabled={isLoading} isLoading={isLoading}>
      <Icon name='times' />
    </Button>
  </div>
)

class WorkspaceForm extends React.Component {
  static defaultProps = {
    onCancel: () => {},
    onSubmit: () => {},
    data: {},
    locations: [],
    errors: {},
    isMaster: true
  }

  static propTypes = {
    intl: intlShape,
    type: FormTypes.propType.isRequired,
    variant: PropTypes.string,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    data: PropTypes.object,
    locations: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, id: PropTypes.number })),
    errors: PropTypes.object,
    isLoading: PropTypes.bool,
    isMultiline: PropTypes.bool,
    mode: PropTypes.string,
    formId: PropTypes.string,
    isMaster: PropTypes.bool
  }

  constructor (props) {
    super(props)

    this.formId = this.props.formId
  }

  successfulMessage = () => {
    if ([FormTypes.PRODUCTION, FormTypes.STOPPAGE].includes(this.props.type) && this.props.data.locationId) {
      return this.props.intl.formatMessage(messages[this.props.type + 'Scheduled'], {
        location: this.props.locations.find(({ id }) => id === this.props.data.locationId).symbol,
        from: this.props.data.date.clone().startOf('isoweek').format(DATE_FORMAT),
        to: this.props.data.date.clone().endOf('isoweek').format(DATE_FORMAT)
      })
    }
  }

  cancel = (e) => {
    e.preventDefault()
    this.props.onCancel()
  }

  submit = (e) => {
    e.preventDefault()
    this.props.onSubmit(this.props.data, this.successfulMessage())
  }

  isInputVisible = (attr) => FIELDS[attr][this.props.type]

  isPlanningMultiline = () => this.props.mode === Modes.PLANNING && this.props.type === FormTypes.PRODUCTION
  isPlanningMultilineStyles = () => this.isPlanningMultiline() ? { marginLeft: 0 } : {}

  render () {
    const { type, isLoading, isMultiline, mode, isMaster } = this.props
    const unreported = type === FormTypes.REALIZATION_UNREPORTED

    return (
      <form className='workspace-form'>
        { this.isInputVisible('location') && <WorkspaceFormLocation formId={this.formId} /> }
        { this.isInputVisible('data') && <WorkspaceFormData formId={this.formId} /> }
        { this.isInputVisible('project') && <WorkspaceFormProject formId={this.formId} /> }
        { this.isInputVisible('productionPlan') && (!this.isInputVisible('createError') || this.props.variant === 'collective') &&
          <WorkspaceFormProductionPlan formId={this.formId} />
        }
        { this.isInputVisible('productionPlan') && this.isInputVisible('createError') && this.props.variant !== 'collective' &&
          <div className='workspace-form__stack'>
            <WorkspaceFormProductionPlan formId={this.formId} />
            <WorkspaceFormCreateError formId={this.formId} />
          </div>
        }
        {
          !isMultiline && this.isInputVisible('product') && this.props.variant !== 'collective' &&
            <WorkspaceFormProduct formId={this.formId} />
        }
        {
          this.isInputVisible('component') && this.props.variant === 'collective' &&
            <WorkspaceFormComponent formId={this.formId} />
        }
        {
          isMultiline &&
            <div style={{ flexGrow: 3 }} className='workspace-form__stack'>
              <WorkspaceFormProductReadonly formId={this.formId} productType='productLeft' />
              <WorkspaceFormProductReadonly formId={this.formId} productType='productRight' />
            </div>
        }
        { this.isInputVisible('startedAt') && <WorkspaceFormStartedAt formId={this.formId} disabled={unreported} /> }
        { this.isInputVisible('engineeringFinishedAt') && <WorkspaceFormEngineeringFinishedAt formId={this.formId} /> }
        { this.isInputVisible('finishedAt') && <WorkspaceFormFinishedAt formId={this.formId} disabled={unreported} /> }
        { this.isInputVisible('stoppageType') && <WorkspaceFormStoppageType formId={this.formId} /> }
        { this.isInputVisible('remarks') && <WorkspaceFormRemarks formId={this.formId} /> }
        { this.isInputVisible('predefinedRemark') && <WorkspaceFormPredefinedRemark formId={this.formId} /> }
        { this.isInputVisible('date') && <WorkspaceFormDate formId={this.formId} /> }
        { this.isPlanningMultiline() && <div style={{ width: '100%' }} /> }
        { !isMultiline && this.isInputVisible('quantity') && (
          <WorkspaceFormQuantity formId={this.formId} style={this.isPlanningMultilineStyles()} />
        )}
        {
          isMultiline &&
            <div style={{ flexGrow: 1 }} className='workspace-form__stack'>
              <WorkspaceFormQuantity formId={this.formId} quantityType='quantityLeft' />
              <WorkspaceFormQuantity formId={this.formId} quantityType='quantityRight' />
            </div>
        }
        {
          this.isInputVisible('qualityController') &&
            <div style={{ flexGrow: 1 }} className='workspace-form__stack'>
              <WorkspaceFormQualityController formId={this.formId} />
              <WorkspaceFormExtrusionDate formId={this.formId} />
            </div>
        }
        { this.isInputVisible('duration') && <WorkspaceFormDuration formId={this.formId} /> }
        { this.isInputVisible('manualDuration') && <WorkspaceFormManualDuration formId={this.formId} /> }
        { this.isInputVisible('changeoverDuration') && <WorkspaceFormChangeoverDuration formId={this.formId} /> }
        { this.isInputVisible('priority') && (
          <div className='input input--nested workspace-form__control' style={{ flexGrow: 1, display: 'flex', minWidth: 180 }}>
            <WorkspaceFormPriority formId={this.formId} />
            <ActionButtons
              submit={this.submit}
              cancel={this.cancel}
              isLoading={isLoading}
              isMultiline={false}
              allowSubmit={isMaster}
            />
          </div>
        ) }
        {
          !isMultiline &&
            <React.Fragment>
              { this.isInputVisible('scrapType') && <WorkspaceFormScrapType variant={this.props.variant} formId={this.formId} /> }
              { this.isInputVisible('reportedAt') && <WorkspaceFormReportedAt formId={this.formId} /> }
            </React.Fragment>
        }
        {
          isMultiline && (this.isInputVisible('scrapType') || this.isInputVisible('reportedAt')) &&
            <div style={{ flexGrow: 1 }} className='workspace-form__stack'>
              { this.isInputVisible('scrapType') && <WorkspaceFormScrapType variant={this.props.variant} formId={this.formId} /> }
              { this.isInputVisible('reportedAt') && <WorkspaceFormReportedAt formId={this.formId} /> }
            </div>
        }
        { this.isInputVisible('division') && <WorkspaceFormDivision formId={this.formId} disabled={!!this.props.data.id} /> }
        { this.isInputVisible('userGroup') && <WorkspaceFormUserGroup formId={this.formId} /> }
        { this.isInputVisible('description') && <WorkspaceFormDescription formId={this.formId} disabled={!!this.props.data.id} /> }
        { this.isInputVisible('resolution') && <WorkspaceFormResolution formId={this.formId} /> }

        { !this.isInputVisible('priority') && (
          <ActionButtons
            submit={this.submit}
            cancel={this.cancel}
            isLoading={isLoading}
            isMultiline={isMultiline}
            allowSubmit={isMaster}
          />
        )}
        {
          this.props.errors.base &&
          <div className='workspace-form__base-error'>{(this.props.errors.base || [])[0]}</div>
        }
      </form>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locations: selectLocations(state),
  errors: selectFormErrors(state, ownProps.formId),
  type: state.form.type,
  variant: state.form.variant,
  data: state.form.data,
  isLoading: state.form.isLoading,
  isMultiline: selectIsFormMultiline(state, ownProps.formId),
  mode: selectMode(state)
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (data, message) => dispatch(submitForm(data, message)),
    onCancel: () => dispatch(closeForm(ownProps.formId))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WorkspaceForm)
