import { withPropType } from 'lib/const'

export const FormTypes = withPropType({
  PRODUCTION: 'production',
  STOPPAGE: 'stoppage',
  SAP_IMPORT: 'sapImport',
  REALIZATION: 'realizationProduction',
  REALIZATION_SCRAP: 'realizationScrap',
  REALIZATION_STOPPAGE: 'realizationStoppage',
  REALIZATION_CHANGEOVER: 'realizationChangeover',
  REALIZATION_UNREPORTED: 'realizationUnreported',
  BREAKDOWN_START: 'breakdownStart',
  BREAKDOWN_FINISH: 'breakdownFinish',
  FRESH_PROJECT_NOTIFICATION: 'freshProjectNotification',
})

export const Modes = withPropType({
  PLANNING: 'planning',
  REALIZATION: 'realization',
  FORMREINIGER: 'formreiniger'
})

export const BarcodeModalType = withPropType({
  KANBAN_CARD: 'kanban_card',
  REMAINING_QUANTITY: 'remaining_quantity',
  ZZVORA: 'zzvora'
})
