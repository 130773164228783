import React from 'react'
import PropTypes from 'prop-types'
import {
  injectIntl,
  intlShape,
  defineMessages,
  FormattedMessage
} from 'react-intl'
import Barcode from 'react-barcode'

const WorkspaceBarcodeRemainingQuantity = ({ intl, product }) => {
  const color = `#${product.labelColor}`
  return (
    <React.Fragment>
      <div className='label-modal__line'>{product.description}</div>
      <div className='label-modal__line'>
        <FormattedMessage id='workspace.realization.barcode.remainingQuantity' />
      </div>
      <Barcode value={product.remainingQuantityNumber} format='CODE39' background={color} height={200} width={2} displayValue />
    </React.Fragment>
  )
}

WorkspaceBarcodeRemainingQuantity.propTypes = {
  intl: intlShape.isRequired,
  product: PropTypes.shape({
    description: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    labelColor: PropTypes.string.isRequired
  })
}

export default injectIntl(WorkspaceBarcodeRemainingQuantity)
