import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Button from 'components/Button'
import ButtonGroup from 'components/ButtonGroup'
import Input from 'components/Input'
import { selectActivenessErrors } from 'workspace/selectors'

class RealizationMenuActivenessForm extends React.Component {
  static defaultProps = {
    personalNumberOnly: false
  }

  handleSubmit = e => {
    e.preventDefault()
    const params = {
      operatorsCount: this.operatorsCountInput
        ? this.operatorsCountInput.value
        : null,
      personalNumber: this.personalNumberInput.value
    }
    this.props.onSubmit(params)
  }

  render () {
    const { onClose, errors, personalNumberOnly } = this.props

    return (
      <form
        onSubmit={this.handleSubmit}
        style={{ margin: 2, padding: 5, backgroundColor: 'white' }}
      >
        {!personalNumberOnly && (
          <Input
            label={
              <FormattedMessage
                id='workspace.menu.activenessForm.operatorsCountLabel'
                defaultMessage='Operators count'
              />
            }
            error={(errors.operatorsCount || [])[0]}
          >
            <input
              autoFocus
              ref={node => {
                this.operatorsCountInput = node
              }}
              defaultValue={1.0}
            />
          </Input>
        )}
        <Input
          label={
            <FormattedMessage
              id='workspace.menu.activenessForm.personalNumberLabel'
              defaultMessage='Personal number'
            />
          }
          error={(errors.personalNumber || [])[0]}
        >
          <input
            ref={node => {
              this.personalNumberInput = node
            }}
          />
        </Input>

        <ButtonGroup nested flex compact>
          <Button size='small' onClick={this.handleSubmit} color='success'>
            <FormattedMessage
              id='workspace.menu.activenessForm.confirm'
              defaultMessage='Confirm'
            />
          </Button>
          <Button size='small' onClick={onClose}>
            <FormattedMessage
              id='workspace.menu.activenessForm.cancel'
              defaultMessage='Cancel'
            />
          </Button>
        </ButtonGroup>
      </form>
    )
  }
}

RealizationMenuActivenessForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  personalNumberOnly: PropTypes.bool
}

const mapStateToProps = state => ({
  errors: selectActivenessErrors(state)
})

export default connect(mapStateToProps)(RealizationMenuActivenessForm)
