import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AssociationSelect from 'components/AssociationSelect'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { selectLocations } from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormLocation = ({ id, locations, onChange, formId }) => (
  <WorkspaceFormInput attribute='location'>
    <AssociationSelect
      isClearable
      options={locations}
      value={id}
      onChange={newId => onChange({ locationId: newId })}
    />
  </WorkspaceFormInput>
)

WorkspaceFormLocation.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, id: PropTypes.number })),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  locations: selectLocations(state),
  id: state.form.data.locationId,

})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormLocation)
