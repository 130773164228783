import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import WorkspaceForm from './WorkspaceForm'
import { selectSubforms } from './selectors'

class WorkspaceForms extends React.Component {
  static propTypes = {
    isMulti: PropTypes.bool,
    ids: PropTypes.arrayOf(PropTypes.string)
  }

  render () {
    const { isMulti, ids } = this.props

    if (!isMulti) {
      return <WorkspaceForm isMaster />
    }

    return (
      <React.Fragment>
        {ids.map((id, idx) => (
          <WorkspaceForm key={id} formId={id} isMaster={idx === 0} />
        ))}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isMulti: state.form.isMulti,
  ids: selectSubforms(state)
})

export default connect(mapStateToProps)(WorkspaceForms)
