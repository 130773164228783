import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormChangeoverDuration = ({ changeoverDuration, onChange, formId }) => (
  <WorkspaceFormInput attribute='changeoverDuration'>
    <input
      type='number'
      className='workspace-form__input'
      value={changeoverDuration || ''}
      onChange={(e) => onChange({ changeoverDuration: e.target.value ? Number(e.target.value) : null })}
    />
  </WorkspaceFormInput>
)

WorkspaceFormChangeoverDuration.propTypes = {
  changeoverDuration: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  formId: PropTypes.string
}

const mapStateToProps = state => ({
  changeoverDuration: state.form.data.changeoverDuration
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormChangeoverDuration)
