import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Timeline from 'components/Timeline'
import FormreinigerTimelineBlock from 'workspace/FormreinigerTimelineBlock'
import { selectNavigationRange } from 'containers/Navigation/selectors'
import {
  selectAllChangeoversMarshalled,
  selectAllLocationsChangesovers,
  selectAnyLocationLoadingChangovers
} from 'workspace/selectors'
import { NBSP } from '../lib/const'

const FormreignerTimeline = ({
  date,
  scope,
  symbol,
  changeoverIds,
  changeovers,
  isLoading,
  lunchBreaks,
  start,
  finish
}) => (
  <Timeline
    scope={scope}
    date={date}
    header={NBSP}
    isLoading={isLoading}
    size={3}
    lunchBreaks={lunchBreaks}
    start={start}
    finish={finish}
  >
    {changeovers.map(changeover => (
      <FormreinigerTimelineBlock
        key={changeover.id}
        id={changeover.id}
        size={changeover.size}
        offset={changeover.offset}
      />
    ))}
  </Timeline>
)

FormreignerTimeline.propTypes = {
  symbol: PropTypes.string,
  date: PropTypes.object.isRequired,
  scope: PropTypes.oneOf(['day', 'week', 'range']).isRequired,
  changeoverIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  changeovers: PropTypes.arrayOf(PropTypes.object).isRequired,
  lunchBreaks: PropTypes.arrayOf(
    PropTypes.shape({
      startsAfterMidnight: PropTypes.number.isRequired,
      duration: PropTypes.number.isRequired
    })
  ),
  isLoading: PropTypes.bool,
  start: PropTypes.object,
  finish: PropTypes.object
}

const mapStateToProps = (state) => ({
  date: selectNavigationRange(state).from,
  scope: state.navigation.shift ? 'range' : state.navigation.scope,
  start: state.navigation.shift ? selectNavigationRange(state).from : undefined,
  finish: state.navigation.shift ? selectNavigationRange(state).to : undefined,
  changeoverIds: selectAllLocationsChangesovers(state),
  changeovers: selectAllChangeoversMarshalled(state),
  isLoading: selectAnyLocationLoadingChangovers(state)
})

export default connect(mapStateToProps)(FormreignerTimeline)
