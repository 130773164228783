import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'
import Color from 'color'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { editSchedule, destroySchedule } from 'workspace/actions'
import { selectIsScheduleEditable, selectSchedule, selectScheduleDetails } from 'workspace/selectors'
import { Characters, DATETIME_FORMAT } from 'lib/const'
import Unit from 'components/Unit'
import PlanTimelineRealization from 'workspace/PlanTimelineRealization'

const CHARACTER_COLOR = {
  [Characters.PRODUCTION]: 'success',
  [Characters.STOPPAGE]: 'danger',
  [Characters.CHANGEOVER]: 'secondary'
}

const PlanTimelineTooltip = ({
  id,
  character,
  startsAt,
  endsAt,
  project,
  productionPlan,
  stoppageType,
  stoppagePlan,
  edit,
  destroy,
  rawColor,
  isEditable
}) => (
  <div className={classnames('timeline-tooltip', `timeline-tooltip--${CHARACTER_COLOR[character]}`)}>
    <div className='timeline-tooltip__content' style={{ borderColor: rawColor && Color(rawColor).darken(0.5) }}>
      <table className='timeline-tooltip__table'>
        <tbody>
          <tr>
            <th />
            <th>
              { character === Characters.STOPPAGE &&
                <FormattedMessage id='workspace.plan.tooltip.stoppage' defaultMessage='Stoppage' /> }
              { character === Characters.PRODUCTION &&
                <FormattedMessage id='workspace.plan.tooltip.production' defaultMessage='Production' /> }
              { character === Characters.CHANGEOVER &&
                <FormattedMessage id='workspace.plan.tooltip.changeover' defaultMessage='Changeover' /> }
            </th>
          </tr>
          {
            character === Characters.PRODUCTION &&
            <React.Fragment>
              <tr>
                <th><FormattedMessage id='workspace.plan.tooltip.project' defaultMessage='Project' />:</th>
                <td>{project.name}</td>
              </tr>
              <tr>
                <th><FormattedMessage id='workspace.plan.tooltip.quantity' defaultMessage='Quantity' />:</th>
                <td>
                  <code>{productionPlan.quantity}x (<Unit value={productionPlan.piecesQuantity} unit='piece' />)</code>
                </td>
              </tr>
              <tr>
                <th><FormattedMessage id='workspace.plan.tooltip.realizedQuantity' defaultMessage='Realization' />:</th>
                <td>
                  <code><PlanTimelineRealization {...productionPlan} /></code>
                </td>
              </tr>
            </React.Fragment>
          }
          {
            character === Characters.STOPPAGE &&
            <React.Fragment>
              <tr>
                <th><FormattedMessage id='workspace.plan.tooltip.stoppageType' defaultMessage='Type' />:</th>
                <td>{stoppageType.symbol} - {stoppageType.description}</td>
              </tr>
              <tr>
                <th><FormattedMessage id='workspace.plan.tooltip.duration' defaultMessage='Duration' />:</th>
                <td>{stoppagePlan.duration} min.</td>
              </tr>
            </React.Fragment>
          }
          {
            (character === Characters.STOPPAGE || character === Characters.PRODUCTION) &&
            <React.Fragment>
              <tr>
                <th><FormattedMessage id='workspace.plan.tooltip.remarks' defaultMessage='Remarks' />:</th>
                <td>{(stoppagePlan || productionPlan).remarks}</td>
              </tr>
              <tr>
                <th><FormattedMessage id='workspace.plan.tooltip.priority' defaultMessage='Prio' />:</th>
                <td>
                  {character === Characters.STOPPAGE && stoppagePlan.priority}
                  {character === Characters.PRODUCTION && productionPlan.priority}
                </td>
              </tr>
            </React.Fragment>
          }
          <tr>
            <th><FormattedMessage id='workspace.plan.tooltip.start' defaultMessage='Start' />:</th>
            <td><code>{startsAt.format(DATETIME_FORMAT)}</code></td>
          </tr>
          <tr>
            <th><FormattedMessage id='workspace.plan.tooltip.end' defaultMessage='End' />:</th>
            <td><code>{endsAt.format(DATETIME_FORMAT)}</code></td>
          </tr>
          {
            isEditable && character !== Characters.CHANGEOVER &&
            <React.Fragment>
              <tr>
                <th />
                <td>
                  <Button size='small' onClick={e => edit(id)}><Icon name='pencil' /></Button>
                  &nbsp;
                  <Button size='small' onClick={e => destroy(id)}><Icon name='trash' /></Button>
                </td>
              </tr>
            </React.Fragment>
          }
        </tbody>
      </table>
    </div>
  </div>
)

PlanTimelineTooltip.propTypes = {
  id: PropTypes.number.isRequired,
  character: Characters.propType,
  startsAt: PropTypes.object,
  endsAt: PropTypes.object,
  project: PropTypes.shape({
    name: PropTypes.string
  }),
  productionPlan: PropTypes.shape({
    quantity: PropTypes.number,
    priority: PropTypes.number
  }),
  stoppageType: PropTypes.shape({
    symbol: PropTypes.string,
    description: PropTypes.string
  }),
  stoppagePlan: PropTypes.shape({
    duration: PropTypes.number,
    priority: PropTypes.number,
    remarks: PropTypes.string
  }),
  edit: PropTypes.func,
  destroy: PropTypes.func,
  isEditable: PropTypes.bool,
  rawColor: PropTypes.string
}

const mapStateToProps = (state, { id }) => ({
  ...selectSchedule(state, id),
  ...selectScheduleDetails(state, id),
  isEditable: selectIsScheduleEditable(state, id)
})

const mapDispatchToProps = ({
  edit: editSchedule,
  destroy: destroySchedule
})

export default connect(mapStateToProps, mapDispatchToProps)(PlanTimelineTooltip)
