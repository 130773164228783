import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import SimpleSelect from 'components/SimpleSelect'
import { changeFormData } from 'workspace/actions'

const PRIORITIES =
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]

const WorkspaceFormPriority = ({ priority, onChange, formId }) => (
  <WorkspaceFormInput attribute='priority'>
    <SimpleSelect
      options={PRIORITIES}
      value={priority}
      onChange={(value) => onChange({ priority: value })}
    />
  </WorkspaceFormInput>
)

WorkspaceFormPriority.propTypes = {
  priority: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  formId: PropTypes.string
}

const mapStateToProps = state => ({
  priority: state.form.data.priority
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormPriority)
