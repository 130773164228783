import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import AssociationSelect from 'components/AssociationSelect'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { selectAvailableProducts, selectFormValue } from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'

const messages = defineMessages({
  noProducts: {
    id: 'workspace.form.noProducts',
    defaultMessage: 'No matching products',
    description: 'Message displayed when there are no products for the selected location or search term'
  }
})

const WorkspaceFormProduct = ({ id, intl, products, onChange, formId }) => (
  <WorkspaceFormInput attribute='product' formId={formId}>
    <AssociationSelect
      isClearable
      options={products}
      value={id}
      onChange={(newId, _, product) => onChange({ productId: newId, quantity: product ? (product.packQuantity || null) : '' })}
      labelMapper={prod => `${prod.description} (${prod.code})`}
      noOptionsMessage={() => intl.formatMessage(messages.noProducts)}
    />
  </WorkspaceFormInput>
)

WorkspaceFormProduct.propTypes = {
  intl: intlShape.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    code: PropTypes.string,
    id: PropTypes.number
  })),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  formId: PropTypes.string
}

const mapStateToProps = (state, ownProps) => ({
  products: selectAvailableProducts(state, ownProps.formId),
  id: selectFormValue(state, 'productId', ownProps.formId)
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WorkspaceFormProduct)
