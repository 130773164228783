import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import Unit from 'components/Unit'
import { selectRealizationSummary, selectRealizationSummaryProject } from 'workspace/selectors'
import Color from 'color'

const RealizationSummaryTimelineBlockContent = ({
  id,
  changeover,
  productionLeft,
  productionRight,
  productionUnspecified,
  plannedStoppageDuration,
  unplannedStoppageDuration,
  stoppageDuration,
  scrap,
  project
}) => {
  if (!project) {
    return null
  }
  if (changeover) {
    return <div className='timeline-block__content' />
  } else {
    const color = '#' + project.blockColor
    const style = project.blockColor
      ? { borderColor: Color(color).darken(0.5), backgroundColor: color }
      : {}
    return (
      <div className='timeline-block__stack'>
        {
          !productionUnspecified &&
          <div
            className='timeline-block__content timeline-block__content--success'
            style={style}
          >
            <code className='timeline-block__line'>
              <FormattedMessage
                id='workspace.realizationSummary.block.productionLeft'
                defaultMessage='Left'
              />
              {': '}
              <Unit value={productionLeft} unit='piece' />
            </code>
          </div>
        }
        {
          !productionUnspecified &&
          <div
            className='timeline-block__content timeline-block__content--success'
            style={style}
          >
            <code className='timeline-block__line'>
              <FormattedMessage
                id='workspace.realizationSummary.block.productionRight'
                defaultMessage='Right'
              />
              {': '}
              <Unit value={productionRight} unit='piece' />
            </code>
          </div>
        }
        {
          productionUnspecified !== 0 &&
          <div
            className='timeline-block__content timeline-block__content--success'
            style={style}
          >
            <code className='timeline-block__line'>
              <FormattedMessage
                id='workspace.realizationSummary.block.productionUnspecified'
                defaultMessage='Production'
              />
              {': '}
              <Unit value={productionUnspecified} unit='piece' />
            </code>
          </div>
        }
        <div className='timeline-block__content timeline-block__content--danger'>
          <code className='timeline-block__line'>
            <FormattedMessage
              id='workspace.realizationSummary.block.stoppageDuration'
              defaultMessage='Stoppage'
            />
            {': '}
            <Unit value={stoppageDuration.toFixed(0)} unit='minute' />
            {' ('}
            {plannedStoppageDuration.toFixed(0)}
            {' '}
            <FormattedMessage id='workspace.realizationSummary.block.plannedStoppageDuration' defaultMessage='PL' />
            {' + '}
            {unplannedStoppageDuration.toFixed(0)}
            {' '}
            <FormattedMessage id='workspace.realizationSummary.block.unplannedStoppageDuration' defaultMessage='UPL' />
            {')'}

          </code>
        </div>
        <div className='timeline-block__content timeline-block__content--info'>
          <code className='timeline-block__line'>
            <FormattedMessage
              id='workspace.realizationSummary.block.scrap'
              defaultMessage='Scrap'
            />
            {': '}
            <Unit value={scrap} unit='piece' />
          </code>
        </div>
      </div>
    )
  }
}

RealizationSummaryTimelineBlockContent.propTypes = {
  id: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  startedAt: PropTypes.object.isRequired,
  finishedAt: PropTypes.object.isRequired,
  productionLeft: PropTypes.number,
  productionRight: PropTypes.number,
  productionUnspecified: PropTypes.number,
  stoppageDuration: PropTypes.number,
  plannedStoppageDuration: PropTypes.number,
  unplannedStoppageDuration: PropTypes.number,
  changeover: PropTypes.bool.isRequired,
  scrap: PropTypes.number,
  project: PropTypes.shape({
    blockColor: PropTypes.string
  })
}

const mapStateToProps = (state, { id }) => ({
  ...selectRealizationSummary(state, id),
  project: selectRealizationSummaryProject(state, id)
})

export default connect(mapStateToProps)(RealizationSummaryTimelineBlockContent)
