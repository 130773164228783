import { call, select } from 'redux-saga/effects'
import api from 'lib/api/client'
import { selectToken } from 'workspace/selectors'
import { requestLocationChangeovers, loadLocationChangeovers } from 'workspace/saga/formreiniger'

export function * create ({ payload: { changeoverId, locationId, startedAt, finishedAt } }) {
  const token = yield select(selectToken)
  const data = {
    changeover_id: changeoverId,
    started_at: startedAt,
    finished_at: finishedAt,
    location_id: locationId
  }
  yield call(api.post, `/changeover_changes`, data, { token })
  yield requestLocationChangeovers(locationId)
  yield loadLocationChangeovers(locationId)
}

export function * destroy ({ payload: { changeoverChangeId, changeoverId, locationId } }) {
  const token = yield select(selectToken)

  yield call(api.delete, `/changeover_changes/${changeoverChangeId}`, { token })
  yield requestLocationChangeovers(locationId)
  yield loadLocationChangeovers(locationId)
}
