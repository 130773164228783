import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import TimelineBlock from 'components/TimelineBlock'
import { selectRealizationHourlySummaryProject } from 'workspace/selectors'
import Color from 'color'
import RealizationSummaryTimelineAggregationTooltip from './RealizationHourlySummaryAggregationTimelineTooltip'

const RealizationHourlySummaryAggregationTimelineBlock = ({
  id,
  startedAt,
  finishedAt,
  timelineSize,
  project,
  realizedQuantity,
  plannedQuantity,
  ...rest
}) => {
  if (!project) return null

  const color = '#' + project.blockColor
  let style = project.blockColor
    ? { borderColor: Color(color).darken(0.5), backgroundColor: color }
    : {}
  style = { ...style, fontSize: '1.2em', fontWeight: 'bold' }
  style = { ...style, display: 'flex', alignItems: 'center' }

  return (
    <TimelineBlock
      {...rest}
      startsAt={startedAt}
      endsAt={finishedAt}
      timelineSize={timelineSize}
      color='secondary'
      size={0.28}
      offset={0}
    >
      <div className='timeline-block__content' style={style}>
        <FormattedMessage id='plannedShort' />
        :&nbsp;
        {plannedQuantity}
        ,&nbsp;
        <FormattedMessage id='realShort' />
        :&nbsp;
        {realizedQuantity}
      </div>

      <RealizationSummaryTimelineAggregationTooltip
        plannedQuantity={plannedQuantity}
        realizedQuantity={realizedQuantity}
        project={project}
      />
    </TimelineBlock>
  )
}

RealizationHourlySummaryAggregationTimelineBlock.propTypes = {
  id: PropTypes.string.isRequired,
  startedAt: PropTypes.object.isRequired,
  finishedAt: PropTypes.object.isRequired,
  timelineSize: PropTypes.number.isRequired
}

const mapStateToProps = (state, { id }) => ({
  project: selectRealizationHourlySummaryProject(state, id)
})

export default connect(mapStateToProps)(RealizationHourlySummaryAggregationTimelineBlock)
