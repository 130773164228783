import { all, call, put, select, delay } from 'redux-saga/effects'
import api from 'lib/api/client'
import { closeForm, openForm } from 'workspace/actions'
import { selectToken, selectForm, selectRealization } from 'workspace/selectors'
import { requestLocationSchedules, loadLocationSchedules } from './schedules'
import { requestLocationRealizations, loadLocationRealizations } from './realizations'
import { requestLocationRealizationSummaries, loadLocationRealizationSummaries } from './realizationSummaries'
import { requestLocationScraps, loadLocationScraps } from './scraps'
import { FormTypes } from 'workspace/const'
import { Characters } from 'lib/const'

function formTypeForRealization (realization) {
  switch (realization.character) {
    case Characters.PRODUCTION:
      return FormTypes.REALIZATION

    case Characters.UNREPORTED:
      return FormTypes.REALIZATION_UNREPORTED

    default:
      return FormTypes.REALIZATION_STOPPAGE
  }
}

export function * edit ({ payload: id }) {
  const realization = yield select(selectRealization, id)
  const form = yield select(selectForm)

  if (form.isActive) {
    yield put(closeForm())
    yield call(delay, 0) // allow redux to update state before opening the form again
  }

  yield put(openForm(formTypeForRealization(realization), realization))
}

export function * destroy ({ payload: id }) {
  const token = yield select(selectToken)
  const realization = yield select(selectRealization, id)
  const form = yield select(selectForm)

  yield call(requestLocationSchedules, realization.locationId)
  yield call(requestLocationRealizations, realization.locationId)
  yield call(requestLocationRealizationSummaries, realization.locationId)
  yield call(requestLocationScraps, realization.locationId)

  if (form.isActive && form.data.id === id) {
    yield put(closeForm())
  }

  yield call(api.delete, `/location_realizations/${id}`, { token })
  yield all([
    call(loadLocationSchedules, realization.locationId),
    call(loadLocationRealizations, realization.locationId),
    call(loadLocationRealizationSummaries, realization.locationId),
    call(loadLocationScraps, realization.locationId)
  ])
}
