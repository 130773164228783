import moment, { duration } from 'moment-timezone'
import { createSelector } from 'reselect'
import { Modes } from 'workspace/const'
import { Characters } from 'lib/const'
import { selectNavigationScope } from '../containers/Navigation/selectors'

// Config
export const selectMode = state => state.config.mode
export const selectToken = state => state.config.token
export const selectTimezone = state => state.config.timezone
export const selectLocale = state => state.config.locale

// Form
export const selectForm = state => state.form
export const selectFormType = state => state.form.type
export const selectIsFormActive = state => state.form.isActive
export const selectFormCharacter = state => state.form.data.character
export const selectFormProjectId = (state, formId) => {
  return selectFormValue(state, 'projectId', formId)
}
export const selectIsFormMultiline = (state, formId) => {
  if (state.form.variant === 'collective') {
    return false
  }

  if (state.form.isMulti) {
    return !!(state.form.data[formId].productLeftId && state.form.data[formId].productRightId)
  } else {
    return !!(state.form.data.productLeftId && state.form.data.productRightId)
  }
}
export const selectFormValue = (state, field, formId) => {
  if (state.form.isMulti) {
    if (!state.form.data[formId]) {
      return ''
    }

    return state.form.data[formId][field]
  } else {
    return state.form.data[field]
  }
}
export const selectFormErrors = (state, formId) => {
  if (state.form.isMulti) {
    return state.form.errors[formId] || {}
  } else {
    return state.form.errors
  }
}
export const selectSubforms = state => Object.keys(state.form.data)

// Locations
export const selectLocations = state => state.locations.allIds.map(id => state.locations.allById[id])
export const selectSelectedLocation = state => state.locations.allById[state.locations.selectedIds[0]]
export const selectSelectedLocations = state => state.locations.selectedIds.map(id => state.locations.allById[id])
export const selectLocation = (state, id) => state.locations.allById[id]
export const isLocationLoadingSchedules = (state, id) => (state.locations.isLoadingSchedulesById[id])
export const selectAllLocationsSelected = createSelector(
  state => state.locations.allIds,
  state => state.locations.selectedIds,
  (allIds, selectedIds) => allIds.sort().join(',') === selectedIds.sort().join(',')
)
export const selectAllLocationsHidden = createSelector(
  state => state.locations.selectedIds,
  selectedIds => selectedIds.length === 0
)

// Projects
export const selectProjects = state => state.projects.allIds.map(id => state.projects.allById[id])
export const selectAvailableProjects = (state, formId) => {
  let projects = state.projects.activeIds.map(id => state.projects.allById[id])

  const locationId = selectFormValue(state, 'locationId', formId)
  if (locationId) {
    projects = projects.filter(p => p.locationIds.includes(locationId))
  }

  return projects
}
export const selectProject = (state, id) => state.projects.allById[id]
const selectProjectsAllById = state => state.projects.allById

// Products
export const selectProduct = (state, id) => state.products.allById[id]
export const selectProductIds = (state) => state.products.allIds
export const selectProducts = createSelector(
  selectProductIds,
  state => state.products.allById,
  (ids, allById) => ids.map(id => allById[id])
)
export const selectActiveProducts = createSelector(
  state => state.products.activeIds,
  state => state.products.allById,
  (ids, allById) => ids.map(id => allById[id])
)
export const selectAvailableProducts = createSelector(
  selectFormProjectId,
  selectActiveProducts,
  (projectId, products) => {
    return (projectId ? products.filter(p => p.projectIds.includes(projectId)) : products)
  }
)
export const selectAvailableProductsCount = createSelector(
  selectAvailableProducts,
  products => products.length
)
export const selectAvailableProductsAreExactlyLeftAndRight = createSelector(
  selectAvailableProducts,
  products => {
    if (products.length !== 2) {
      return false
    }

    const [left, right] = products.map(p => p.side).sort()
    return left === 'left' && right === 'right'
  }
)
export const selectAvailableProductForLeftSide = createSelector(
  selectAvailableProducts,
  products => products.find(p => p.side === 'left')
)
export const selectAvailableProductForRightSide = createSelector(
  selectAvailableProducts,
  products => products.find(p => p.side === 'right')
)

// Components
export const selectAvailableComponents = createSelector(
  state => state.components.allById,
  state => state.components.idsByProduct,
  selectAvailableProducts,
  (allById, idsByProduct, products) => {
    const componentIds = {}
    products.forEach((product) => {
      (idsByProduct[product.id] || []).forEach((componentId) => {
        componentIds[componentId] = true
      })
    })

    return Object.keys(componentIds).map((id) => allById[id])
  }
)

// Stoppage types
export const selectStoppageTypes = state => state.stoppageTypes.allIds.map(id => state.stoppageTypes.allById[id])
export const selectAvailableStoppageTypes = (state, formId) => {
  let stoppageTypes = state.stoppageTypes.allIds.map(id => state.stoppageTypes.allById[id])

  const locationId = selectFormValue(state, 'locationId', formId)
  if (locationId) {
    stoppageTypes = stoppageTypes.filter(p => p.locationIds.includes(locationId))
  }

  return stoppageTypes
}
export const selectStoppageTypesForLocation = (state, locationId) => (
  state
    .stoppageTypes
    .allIds
    .map(id => state.stoppageTypes.allById[id])
    .filter(p => p.locationIds.includes(locationId))
)
export const selectStoppageType = (state, id) => state.stoppageTypes.allById[id]

// Scrap types
export const selectScrapType = (state, id) => state.scrapTypes.allById[id]
export const selectAvailableScrapTypes = (state, formId, collective = false) => {
  let scrapTypes = state.scrapTypes.allIds.map(id => state.scrapTypes.allById[id])

  const locationId = selectFormValue(state, 'locationId', formId)
  if (locationId) {
    scrapTypes = scrapTypes.filter((p) => (
      p.locationIds.includes(locationId) && p[collective ? 'collectiveScrap' : 'standardScrap']
    ))
  }

  return scrapTypes
}

// Schedules
export const selectSchedule = (state, id) => state.schedules.allById[id]
export const selectScheduleDetails = (state, id) => {
  const schedule = selectSchedule(state, id)

  if (schedule.character === Characters.PRODUCTION) {
    return { project: selectProject(state, schedule.productionPlan.projectId) }
  } else if (schedule.character === Characters.STOPPAGE) {
    return { stoppageType: selectStoppageType(state, schedule.stoppagePlan.stoppageTypeId) }
  } else {
    return {}
  }
}
export const selectScheduleProjectId = (state, id) => {
  const schedule = selectSchedule(state, id)

  if (schedule.character !== Characters.PRODUCTION) {
    return null
  }

  return schedule.productionPlan.projectId
}
export const selectIsScheduleEditable = (state, id) => {
  const timezone = selectTimezone(state)
  const now = moment.tz(timezone)
  const endsAt = moment.tz(selectSchedule(state, id).endsAt, timezone)

  return (
    selectMode(state) === Modes.PLANNING &&
    selectPermissions(state).planning.write &&
    duration(now.diff(endsAt)).asHours() < 48
  )
}
export const selectPlanTimestamp = (state, locationId) => {
  const schedules = state.schedules.idsByLocation[locationId]
  const timezone = selectTimezone(state)
  let updatedAt

  schedules.forEach(id => {
    const schedule = selectSchedule(state, id)
    if (!updatedAt || schedule.updatedAt.isAfter(updatedAt)) {
      updatedAt = schedule.updatedAt
    }
  })

  return updatedAt ? updatedAt.clone().tz(timezone) : null
}
export const selectPlanChanged = (state) => state.schedules.planChanged
const selectSchedulesIdsByLocation = state => state.schedules.idsByLocation
const selectSchedulesAllById = state => state.schedules.allById
const selectLocationProductionSchedules = createSelector(
  selectSelectedLocation,
  selectSchedulesIdsByLocation,
  selectSchedulesAllById,
  (location, idsByLocation, allById, projectsById) => {
    const ids = idsByLocation[location.id]
    return ids.map(id => allById[id]).filter(schedule => schedule.character === Characters.PRODUCTION)
  }
)

export const selectProductionPlansForRealization = createSelector(
  selectLocationProductionSchedules,
  selectProjectsAllById,
  (schedules, projectsById) => {
    const plans = schedules.map(schedule => schedule.productionPlan)
    return plans.map(plan => ({ ...plan, projectName: projectsById[plan.projectId].name }))
  }
)

// Realizations
export const selectRealization = (state, id) => state.realizations.allById[id]
export const selectRealizationDetails = (state, id) => {
  const realization = selectRealization(state, id)
  let details = {}

  if (realization.projectId) {
    details.project = selectProject(state, realization.projectId)
  }

  if (realization.productId) {
    details.product = selectProduct(state, realization.productId)
  } else if (realization.stoppageTypeId) {
    details.stoppageType = selectStoppageType(state, realization.stoppageTypeId)
  }

  if (realization.predefinedRemarkId) {
    details.predefinedRemark = selectPredefinedRemark(state, realization.predefinedRemarkId)
  }

  return details
}
export const selectOverlap = (state, id) => state.overlappings.allById[id]
export const selectLocationRealizationsPerformance = state => {
  const locationId = state.locations.selectedIds[0] // realization view allows only 1 location to be selected

  if (state.locations.isLoadingRealizationsById[locationId]) {
    return null
  }

  const realizations = state.realizations.idsByLocation[locationId]
    .map(id => selectRealization(state, id))
    .filter(realization => realization.productId)

  if (realizations.length === 0) {
    return -1
  }

  const requiredTime = realizations.reduce(
    (sum, { quantity, productId }) => sum + quantity * +selectProduct(state, productId).oeeProductionTime,
    0
  )
  const usedTime = realizations.reduce(
    (sum, realization) => sum + (realization.duration / 60.0),
    0
  )
  return requiredTime / usedTime
}

// Changeovers
export const selectChangeover = (state, id) => state.changeovers.allById[id]
export const selectChangeoverDetails = (state, id) => {
  const changeover = selectChangeover(state, id)
  let details = {}

  if (changeover.productionPlan && changeover.productionPlan.projectId) {
    details.project = selectProject(state, changeover.productionPlan.projectId)
  }

  if (changeover.locationId) {
    details.location = selectLocation(state, changeover.locationId)
  }

  return details
}
export const selectAllChangeoversWithoutChange = createSelector(
  state => state.changeovers.allById,
  (allById) => Object.values(allById).filter(changeover => !changeover.changeoverChange)
)
export const selectAllLocationsChangesovers = createSelector(
  state => state.locations.selectedIds,
  state => state.changeovers.idsByLocation,
  (selectedLocationIds, selectedChangeoversIdsByLocation) =>
    selectedLocationIds.flatMap(id => selectedChangeoversIdsByLocation[id])
)
export const selectAnyLocationLoadingChangovers = createSelector(
  state => state.locations.selectedIds,
  state => state.locations.isLoadingChangeoversById,
  (selectedLocationIds, selectedLocationsLoadingChangeoversById) =>
    selectedLocationIds.flatMap(id => selectedLocationsLoadingChangeoversById[id]).some(Boolean)

)
export const selectAllChangeoversMarshalled = createSelector(
  (state) => state.locations.selectedIds,
  (state) => state.changeovers.idsByLocation,
  (state) => state.changeovers.allById,
  (selectedLocationsIds, changeoverIdsByLocation, changeoversById) => {
    const changeoverIds = selectedLocationsIds
      .map(id => changeoverIdsByLocation[id])
      .flat()
    const changeovers = changeoverIds.map(id => changeoversById[id]).sort((a, b) => a.startsAt - b.startsAt)
    const maxSize = 3

    let groups = []
    let currentGroup = []
    let lastChangeover = null
    changeovers.map(changeover => {
      if (!lastChangeover) {
        currentGroup.push(changeover)
        lastChangeover = changeover
        return
      }

      if (lastChangeover.endsAt < changeover.startsAt) {
        groups.push(currentGroup)
        currentGroup = [changeover]
        lastChangeover = changeover
        return
      }

      currentGroup.push(changeover)
      if (lastChangeover.endsAt < changeover.endsAt) lastChangeover = changeover
    })
    groups.push(currentGroup)

    groups.map(group => {
      group.sort((a, b) =>
        selectedLocationsIds.indexOf(a.locationId) - selectedLocationsIds.indexOf(b.locationId)
      ).map((changeover, idx) => {
        changeover.size = maxSize / group.length
        changeover.offset = idx * (maxSize / group.length)
      })
    })

    return groups.flat()
  }
)

// Scraps
export const selectScrap = (state, id) => state.scraps.allById[id]
export const selectScrapDetails = (state, id) => {
  const scrap = selectScrap(state, id)

  return {
    ...scrap,
    product: selectProduct(state, scrap.productId),
    project: selectProject(state, scrap.projectId),
    scrapType: selectScrapType(state, scrap.scrapTypeId)
  }
}
export const selectLocationScrapPercentage = (state) => {
  const locationId = state.locations.selectedIds[0] // realization view allows only 1 location to be selected
  const percentage = state.scraps.percentageByLocation[locationId]

  if (state.locations.isLoadingScrapsById[locationId]) {
    return null
  }

  if (percentage.length === 0) {
    return -1
  } else {
    const [scrap, realization] = percentage

    if (realization === 0) {
      return 1.0
    } else {
      return scrap / realization
    }
  }
}

// Activenesses
export const selectCurrentActiveness = state => {
  const locationId = state.locations.selectedIds[0] // realization view allows only 1 location to be selected
  const activenessIds = state.activenesses.idsByLocation[locationId]

  if (!activenessIds || activenessIds.length === 0) {
    return null
  } else {
    return state.activenesses.allById[activenessIds[0]]
  }
}
export const selectActivenessErrors = state => state.activenesses.errors

// Permissions
export const selectPermissions = state => state.permissions

// Predefined remarks
export const selectPredefinedRemark = (state, id) => state.predefinedRemarks.allById[id]
export const selectPredefinedRemarks = state => state.predefinedRemarks.allIds.map(id => state.predefinedRemarks.allById[id])
export const selectAvailablePredefinedRemarks = (state, formId) => {
  let predefinedRemarks = state.predefinedRemarks.allIds.map(id => state.predefinedRemarks.allById[id])

  const locationId = selectFormValue(state, 'locationId', formId)
  if (locationId) {
    predefinedRemarks = predefinedRemarks.filter(p => p.locationIds.includes(locationId))
  }

  const stoppageTypeId = state.form.data.stoppageTypeId
  if (stoppageTypeId) {
    predefinedRemarks = predefinedRemarks.filter(p => p.stoppageTypeIds.includes(stoppageTypeId))
  }

  return predefinedRemarks
}

// Quality controllers
export const selectAvailableQualityControllers = createSelector(
  (state) => state.qualityControllers.allIds,
  (state) => state.qualityControllers.allById,
  (ids, allById) => {
    return ids.map((id) => allById[id])
  }
)
export const selectIsFreshProject = createSelector(
  selectFormProjectId,
  selectProjectsAllById,
  (projectId, allById) => {
    if (!projectId) return false

    return allById[projectId].type === 'fresh'
  }
)

// User groups
export const selectAvailableUserGroups = createSelector(
  (state) => state.userGroups.allIds,
  (state) => state.userGroups.allById,
  (ids, allById) => {
    return ids.map((id) => allById[id])
  }
)

// Barcode modal
export const selectBarcodeModalType = (state) => state.barcodeModal.type
export const selectBarcodeModalProduct = (state) => selectProduct(state, state.barcodeModal.productId)

// Breakdowns
export const selectCurrentBreakdown = state => {
  const locationId = state.locations.selectedIds[0] // realization view allows only 1 location to be selected
  const breakdownIds = state.breakdowns.idsByLocation[locationId]

  if (!breakdownIds || breakdownIds.length === 0) {
    return null
  } else {
    return state.breakdowns.allById[breakdownIds[0]]
  }
}

// Current User
export const selectCurrentUser = state => state.currentUser
export const selectCurrentUserUnreadShiftBookNoteIds = state =>
  (selectCurrentUser(state) || {}).unreadShiftBookNoteIds || []
export const selectCurrentUserManualProductionAllowed = state =>
  (selectCurrentUser(state) || {}).manualProductionAllowed || false

// Realization summaries
const calculateRealizationDuration = ({ startedAt, finishedAt }) => duration(finishedAt.diff(startedAt)).asSeconds()

export const selectRealizationSummary = (state, id) => state.realizationSummaries.allById[id]
export const selectRealizationSummaryProject = (state, id) =>
  selectProject(state, selectRealizationSummary(state, id).projectId)
export const selectRealizationSummaryDuration = (state, id) =>
  calculateRealizationDuration(selectRealizationSummary(state, id))

export const selectRealizationHourlySummaryAllowed = (state) => {
  const { realization: { hourlySummaries: hourlyAllowed } } = selectPermissions(state)
  const navigationScope = selectNavigationScope(state)

  return hourlyAllowed && navigationScope !== 'week'
}
export const selectRealizationHourlySummary = (state, id) => state.realizationHourlySummaries.allById[id]
export const selectRealizationHourlySummaryProject = (state, id) =>
  selectProject(state, selectRealizationHourlySummary(state, id).projectId)
export const selectRealizationHourlySummaryDuration = (state, id) =>
  calculateRealizationDuration(selectRealizationHourlySummary(state, id))
export const selectRealizationHourlySummaryRealizationAggregations = createSelector(
  (state, locationId) => state.realizationHourlySummaries.idsByLocation[locationId],
  (state) => state.realizationHourlySummaries.allById,
  (ids, allById) => {
    let summaries = ids.map(id => allById[id])
    summaries = summaries.sort((a, b) => new Date(a.startedAt) - new Date(b.startedAt))
    summaries = [...summaries, { stoppage: true }]

    const result = []
    let currentProjectId = null
    let currentId = null
    let startedAt = null
    let finishedAt = null
    let plannedQuantity = 0
    let realizedQuantity = 0
    summaries.forEach((summary) => {
      if (
        (currentProjectId !== summary.projectId) &&
        (startedAt && finishedAt)
      ) {
        result.push(
          {
            id: currentId,
            projectId: currentProjectId,
            startedAt,
            finishedAt,
            plannedQuantity,
            realizedQuantity
          }
        )

        plannedQuantity = 0
        realizedQuantity = 0
        startedAt = null
        finishedAt = null
      }

      if (summary.changeover || summary.stoppage) {
        currentProjectId = null
        return
      }

      currentId = summary.id
      currentProjectId = summary.projectId
      startedAt = startedAt === null ? summary.startedAt : startedAt
      finishedAt = summary.finishedAt
      plannedQuantity += summary.plannedQuantity
      realizedQuantity += summary.realizedQuantity
    })

    return result
  }
)

// Shift book
export const selectShiftBookNoteIds = (state) => state.shiftBookNotes.allIds
export const selectShiftBookNoteRootIds = (state) => state.shiftBookNotes.rootIds
export const selectShiftBookNote = (state, id) => state.shiftBookNotes.allById[id]
export const selectShiftBookNoteLoading = (state) => state.shiftBookNotes.loading
export const selectShiftBookNoteDraft = state => state.shiftBookNotes.draft
export const selectShiftBookReplyingNoteId = state => state.shiftBookNotes.replyingId
export const selectShiftBookEditingNoteId = state => state.shiftBookNotes.editingId
export const selectShiftBookEditingNote = state =>
  selectShiftBookNote(state, selectShiftBookEditingNoteId(state))
export const selectShiftBookError = (state) => state.shiftBookNotes.error
export const selectShiftBookProcessing = (state) => state.shiftBookNotes.processing
export const selectShiftBookNoteUnreadIds = createSelector(
  selectShiftBookNoteIds,
  selectCurrentUserUnreadShiftBookNoteIds,
  (noteIds, unreadIds) => noteIds && unreadIds &&
    noteIds.filter(noteId => unreadIds.includes(noteId))
)
export const selectShiftBookNoteUnreadCount = createSelector(
  selectShiftBookNoteUnreadIds,
  unreadIds => (unreadIds || []).length
)
export const selectShiftBookNoteIsRead = (state, id) =>
  !selectCurrentUserUnreadShiftBookNoteIds(state).includes(id)
export const selectShiftBookNoteReplyIds = (state, id) =>
  state.shiftBookNotes.idsByTopic[id]
