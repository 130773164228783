import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormResolution = ({ resolution, onChange, formId }) => (
  <WorkspaceFormInput attribute='resolution'>
    <input
      type='text'
      className='workspace-form__input'
      value={resolution || ''}
      onChange={(e) => onChange({ resolution: e.target.value })}
    />
  </WorkspaceFormInput>
)

WorkspaceFormResolution.propTypes = {
  resolution: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  formId: PropTypes.string
}

const mapStateToProps = state => ({
  resolution: state.form.data.resolution
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormResolution)
