import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  selectChangeover,
  selectChangeoverDetails
} from 'workspace/selectors'
import Color from 'color'
import {
  durationInMinutes,
  formatDuration,
  productPerformanceMultipier
} from 'workspace/lib'
import Icon from 'components/Icon'

const FormreinigerTimelineBlockContent = ({
  id,
  quantity,
  product,
  stoppageType,
  project,
  duration,
  onClick,
  rawColor,
  location
}) => {
  rawColor = rawColor && Color(rawColor)
  return (
    <div
      className='timeline-block__content'
      onClick={onClick}
      style={{
        borderColor: rawColor && rawColor.darken(0.5),
        backgroundColor: rawColor
      }}
    >
      {product && (
        <React.Fragment>
          <p className='timeline-block__line'>
            {product.description} ({product.code})
          </p>
          <p className='timeline-block__line'>
            <code>{quantity}x</code>
          </p>
          <p className='timeline-block__line'>
            <code>
              {Math.round(
                ((100 * quantity * +product.oeeProductionTime) /
                  durationInMinutes(duration)) *
                  productPerformanceMultipier(product)
              )}
              %
            </code>
          </p>
        </React.Fragment>
      )}
      {stoppageType && (
        <React.Fragment>
          <p className='timeline-block__line'>{stoppageType.symbol}</p>
          <p className='timeline-block__line'>
            <code>{formatDuration(duration)} min.</code>
          </p>
        </React.Fragment>
      )}
      <>
        <p className='timeline-block__line'>{project.name}</p>
        <p className='timeline-block__line'><Icon name='random' /></p>
      </>
      {location && <p className='timeline-block__line'>{location.symbol}</p>}
    </div>
  )
}

FormreinigerTimelineBlockContent.propTypes = {
  id: PropTypes.number.isRequired,
  quantity: PropTypes.number,
  product: PropTypes.shape({
    description: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    productionTime: PropTypes.number.isRequired
  }),
  stoppageType: PropTypes.shape({
    symbol: PropTypes.string.isRequired
  }),
  project: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  duration: PropTypes.number,
  onClick: PropTypes.func,
  rawColor: PropTypes.string,
  location: PropTypes.object
}

const mapStateToProps = (state, { id }) => ({
  ...selectChangeover(state, id),
  ...selectChangeoverDetails(state, id)
})

export default connect(mapStateToProps)(FormreinigerTimelineBlockContent)
