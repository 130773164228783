import { call, put, all, select } from 'redux-saga/effects'
import api from 'lib/api/client'
import {
  startInit,
  finishInit,
  receiveActivenesses,
  receiveBreakdowns,
  receiveCurrentUser,
  receiveLocations,
  receiveProjects,
  receiveProducts,
  receiveStoppageTypes,
  receiveScrapTypes,
  receivePermissions,
  receivePredefinedRemarks,
  receiveQualityControllers,
  receiveUserGroups,
  receiveComponents
} from 'workspace/actions'
import { selectMode, selectToken, selectPermissions } from 'workspace/selectors'

function * init () {
  yield put(startInit())
  const token = yield select(selectToken)
  const mode = yield select(selectMode)

  let [
    currentUser,
    permissions,
    locations,
    projects,
    products,
    stoppageTypes,
    scrapTypes,
    predefinedRemarks,
    qualityControllers,
    userGroups,
    components
  ] = yield all([
    call(api.get, '/users/me', { token }),
    call(api.get, '/users/policies', { token }),
    call(api.get, '/locations', { token }),
    call(api.get, '/projects?include_inactive=true', { token }),
    call(api.get, '/products?include_inactive=true', { token }),
    call(api.get, '/stoppage_types', { token }),
    call(api.get, '/scrap_types', { token }),
    call(api.get, '/predefined_remarks', { token }),
    call(api.get, '/users?quality=true', { token }),
    call(api.get, '/user_groups', { token }),
    call(api.get, '/components', { token })
  ])

  yield all([
    put(receiveCurrentUser(currentUser)),
    put(receivePermissions(permissions)),
    put(receiveLocations(locations)),
    put(receiveProjects(projects)),
    put(receiveProducts(products)),
    put(receiveStoppageTypes(stoppageTypes)),
    put(receiveScrapTypes(scrapTypes)),
    put(receivePredefinedRemarks(predefinedRemarks)),
    put(receiveQualityControllers(qualityControllers)),
    put(receiveUserGroups(userGroups)),
    put(receiveComponents(components))
  ])

  permissions = yield select(selectPermissions)

  if (mode === 'realization' && permissions.realization.write) {
    const [activenesses, breakdowns] = yield all([
      call(api.get, '/activeness_periods', { token }),
      call(api.get, '/breakdown_issues', { token })
    ])

    yield put(receiveActivenesses(activenesses))
    yield put(receiveBreakdowns(breakdowns))
  }

  yield put(finishInit())
}

export default init
