import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import TimelineBlock from 'components/TimelineBlock'
import RealizationSummaryTimelineBlockContent from 'workspace/RealizationSummaryTimelineBlockContent'
import RealizationSummaryTimelineTooltip from 'workspace/RealizationSummaryTimelineTooltip'
import { selectRealizationSummary } from 'workspace/selectors'

const RealizationSummaryTimelineBlock = ({
  id,
  startedAt,
  finishedAt,
  timelineSize,
  productionLeft,
  productionRight,
  stoppageDuration,
  changeover,
  scrap,
  ...rest
}) => (
  <TimelineBlock
    {...rest}
    startsAt={startedAt}
    endsAt={finishedAt}
    timelineSize={timelineSize}
    color='secondary'
    size={1}
    offset={0}
    stacked={!changeover}
  >
    <RealizationSummaryTimelineBlockContent id={id} />
    <RealizationSummaryTimelineTooltip id={id} />
  </TimelineBlock>
)

RealizationSummaryTimelineBlock.propTypes = {
  id: PropTypes.string.isRequired,
  startedAt: PropTypes.object.isRequired,
  finishedAt: PropTypes.object.isRequired,
  timelineSize: PropTypes.number.isRequired
}

const mapStateToProps = (state, { id }) => ({
  ...selectRealizationSummary(state, id)
})

export default connect(mapStateToProps, null)(RealizationSummaryTimelineBlock)
