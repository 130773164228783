import moment from 'moment-timezone'
import { put, select } from 'redux-saga/effects'
import { openForm } from 'workspace/actions'
import {
  selectMode,
  selectTimezone,
  selectStoppageTypesForLocation
} from 'workspace/selectors'
import { FormTypes, Modes } from 'workspace/const'

function * formDefaults ({ payload: { type, data, variant } }) {
  const { date } = yield select(state => state.navigation)
  const mode = yield select(selectMode)
  const locations = yield select(state => state.locations.selectedIds)
  const timezone = yield select(selectTimezone)
  const stoppageTypes = yield select(
    selectStoppageTypesForLocation,
    locations[0]
  )

  let multi = false

  let defaults = {
    priority: 10,
    date: date.clone(),
    duration: null,
    quantity: null,
    remarks: '',
    description: '',
    resolution: '',
    division: 'electrical',
    manualDuration: null,
    changeoverDuration: null
  }

  if (mode === Modes.REALIZATION) {
    defaults.locationId = locations[0]
    defaults.startedAt = moment.tz(timezone).startOf('minute')
    defaults.engineeringFinishedAt = moment.tz(timezone).startOf('minute')
    defaults.finishedAt = moment.tz(timezone).startOf('minute')

    if (type === FormTypes.REALIZATION_STOPPAGE) {
      defaults.stoppageTypeId = stoppageTypes[0].id
    }

    if (type === FormTypes.REALIZATION_SCRAP) {
      defaults.reportedAt = moment.tz(timezone).startOf('minute')
      multi = true

      if (variant === undefined) {
        defaults.createError = false
      }
    }

    const character =
      type.replace(/^realization/, '').toLowerCase()
    defaults.character = character
  }

  yield put(openForm(type, { ...defaults, ...data }, multi, variant))
}

export default formDefaults
