import { put, select } from 'redux-saga/effects'
import {
  selectAvailablePredefinedRemarks,
  selectAvailableProductForLeftSide,
  selectAvailableProductForRightSide,
  selectAvailableProducts,
  selectAvailableProductsAreExactlyLeftAndRight,
  selectAvailableProjects,
  selectAvailableStoppageTypes,
  selectMode,
  selectFormValue,
  selectIsFreshProject
} from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'
import { FormTypes, Modes } from 'workspace/const'
import moment from 'moment-timezone'

export default function * formChange ({ payload: { formId } }) {
  const isFormMulti = yield select(state => state.form.isMulti)
  if (isFormMulti && formId === undefined) {
    const data = yield select(state => state.form.data)
    formId = Object.keys(data)[0]
  }

  const selector = state => isFormMulti ? state.form.data[formId] : state.form.data

  const {
    projectId,
    productId,
    productLeftId,
    productRightId,
    stoppageTypeId,
    predefinedRemarkId
  } = yield select(selector)

  const mode = yield select(selectMode)
  const type = yield select(state => state.form.type)

  const availableProjects = yield select(state => selectAvailableProjects(state, formId))
  if (projectId && !availableProjects.map(p => p.id).includes(projectId)) {
    yield put(changeFormData({ projectId: null }, formId))
  }

  const availableProducts = yield select(state => selectAvailableProducts(state, formId))
  if (productId && !availableProducts.map(p => p.id).includes(productId)) {
    yield put(changeFormData({ productId: null }, formId))
  }
  if (productLeftId && !availableProducts.map(p => p.id).includes(productLeftId)) {
    yield put(changeFormData({ productLeftId: null }, formId))
  }
  if (productRightId && !availableProducts.map(p => p.id).includes(productRightId)) {
    yield put(changeFormData({ productRightId: null }, formId))
  }

  const notEditing = yield select(state => !selectFormValue(state, 'id', formId))

  if (mode === Modes.REALIZATION && (type === FormTypes.REALIZATION)) {
    const isFreshProject = yield select(selectIsFreshProject)
    const qualityControllerId = yield select(state => selectFormValue(state, 'qualityControllerId', formId))
    const extrusionDate = yield select(state => selectFormValue(state, 'extrusionDate', formId))

    if (!isFreshProject) {
      if (qualityControllerId || extrusionDate) {
        yield put(changeFormData({ qualityControllerId: null, extrusionDate: null }, formId))
      }
    } else {
      if (!extrusionDate) {
        yield put(changeFormData({ extrusionDate: moment() }, formId))
      }
    }
  }

  if (mode === Modes.REALIZATION && (type === FormTypes.REALIZATION || type === FormTypes.REALIZATION_SCRAP)) {
    const productsAreLeftAndRight = yield select(state => selectAvailableProductsAreExactlyLeftAndRight(state, formId))
    const sideProductsSelected = yield select(state => selectFormValue(state, 'productLeftId', formId))

    if (productsAreLeftAndRight && !sideProductsSelected && notEditing) {
      const left = yield select(state => selectAvailableProductForLeftSide(state, formId))
      const right = yield select(state => selectAvailableProductForRightSide(state, formId))
      yield put(changeFormData(
        { productLeftId: left.id, productRightId: right.id, productId: null, quantity: null }, formId)
      )
    } else if (!productsAreLeftAndRight && sideProductsSelected) {
      yield put(
        changeFormData({ productLeftId: null, productRightId: null, quantityLeft: null, quantityRight: null }, formId)
      )
    }
  }

  const availableStoppageTypes = yield select(state => selectAvailableStoppageTypes(state, formId))
  if (stoppageTypeId && !availableStoppageTypes.map(p => p.id).includes(stoppageTypeId)) {
    if (type === FormTypes.REALIZATION_STOPPAGE) {
      yield put(changeFormData({ stoppageTypeId: availableStoppageTypes[0] && availableStoppageTypes[0].id }, formId))
    } else {
      yield put(changeFormData({ stoppageTypeId: null }, formId))
    }
  }

  if (mode === Modes.REALIZATION) {
    const availablePredefinedRemarks = yield select(state => selectAvailablePredefinedRemarks(state, formId))
    if (predefinedRemarkId && !availablePredefinedRemarks.map(p => p.id).includes(predefinedRemarkId)) {
      yield put(changeFormData({ predefinedRemarkId: null }, formId))
    }
  }
}
