import { all, call, put, select, delay } from 'redux-saga/effects'
import api from 'lib/api/client'
import { closeForm, openForm } from 'workspace/actions'
import { selectToken, selectForm, selectScrap } from 'workspace/selectors'
import { requestLocationSchedules, loadLocationSchedules } from './schedules'
import { requestLocationScraps, loadLocationScraps } from './scraps'
import { requestLocationRealizationSummaries, loadLocationRealizationSummaries } from './realizationSummaries'
import { FormTypes } from 'workspace/const'

export function * edit ({ payload: id }) {
  const scrap = yield select(selectScrap, id)
  const form = yield select(selectForm)

  if (form.isActive) {
    yield put(closeForm())
    yield call(delay, 0) // allow redux to update state before opening the form again
  }

  yield put(openForm(FormTypes.REALIZATION_SCRAP, scrap))
}

export function * destroy ({ payload: scrapId }) {
  const token = yield select(selectToken)
  const scrap = yield select(selectScrap, scrapId)
  const form = yield select(selectForm)

  yield all([
    call(requestLocationSchedules, scrap.locationId),
    call(requestLocationScraps, scrap.locationId),
    call(requestLocationRealizationSummaries, scrap.locationId)
  ])

  if (form.isActive && form.data.id === scrapId) {
    yield put(closeForm())
  }

  yield call(api.delete, `/scraps/${scrapId}`, { token })
  yield all([
    call(loadLocationSchedules, scrap.locationId),
    call(loadLocationScraps, scrap.locationId),
    call(loadLocationRealizationSummaries, scrap.locationId)
  ])
}
