import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'

class WorkspaceBarcodeKanbanConfirmation extends React.PureComponent {
  static propTypes = {
    product: PropTypes.shape({
      kanbanConfirmationText: PropTypes.string
    }),
    children: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = { confirmed: false, value: false }
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnConfirmClick = this.handleOnConfirmClick.bind(this)
  }

  handleOnChange () {
    this.setState(state => ({
      value: !state.value
    }))
  }

  handleOnConfirmClick () {
    if (this.state.value) {
      this.setState({
        confirmed: true
      })
    }
  }

  render () {
    const { product, children } = this.props
    const { confirmed, value } = this.state

    if (!product.kanbanConfirmationText || confirmed) {
      return children()
    }

    return (
      <div style={styles.container}>
        <div className='label-modal__line label-modal__line--small'>
          {product.kanbanConfirmationText}
        </div>
        <div style={styles.row}>
          <Checkbox
            value={value}
            onChange={this.handleOnChange}
            style={styles.checkbox}
          />
          <Button
            color={value ? 'success' : 'default'}
            onClick={this.handleOnConfirmClick}
          >
            <FormattedMessage id='workspace.realization.barcode.confirm' />
          </Button>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    width: '100%',
    minHeight: 242,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    marginRight: 25
  }
}

export default React.memo(WorkspaceBarcodeKanbanConfirmation)
