import { call, fork, takeEvery, takeLatest, takeLeading, select } from 'redux-saga/effects'
import Actions from 'workspace/actions'
import formDefaults from './formDefaults'
import formSubmission from './formSubmission'
import formChange from './formChange'
import formPrefill from './formPrefill'
import heartbeat from 'lib/saga/heartbeat'
import takeLeadingWithPayload from 'lib/saga/takeLeadingWithPayload'
import init from './init'
import navigation from './navigation'
import navigationInit from './navigationInit'
import history from './history'
import { edit as scheduleEdit, destroy as scheduleDestroy } from './schedule'
import {
  edit as realizationEdit,
  destroy as realizationDestroy
} from './realization'
import { edit as scrapEdit, destroy as scrapDestroy } from './scrap'
import {
  start as activenessStart,
  finish as activenessFinish
} from './activeness'
import {
  fetchShiftBookNotes,
  createNote as createShiftBookNote,
  updateNote as updateShiftBookNote,
  destroyNote as destroyShiftBookNote,
  markAsRead as markShiftBookNoteAsRead,
  markAllAsRead as markAllShiftBookNotesAsRead,
  setReplyFromUrl,
  setResolved
} from './shiftBook'
import {
  create as changeoverChangeCreate,
  destroy as changeoverChangeDestroy
} from './changeover_changes'
import { loadTimelines } from './timelines'
import reload from './reload'
import measurements, { confirmMeasurement } from './measurements'
import { SET_NAVIGATION } from 'containers/Navigation/actions'
import { init as initFormreiniger } from './formreiniger'
import { Modes } from 'workspace/const'
import { selectMode } from 'workspace/selectors'

export default function * root () {
  const mode = yield select(selectMode)

  if (process.env.NODE_ENV === 'development') {
    yield fork(heartbeat, 'Workspace')
  }

  // Setup
  yield call(navigationInit)
  yield fork(reload)
  yield call(init)
  yield fork(history)
  yield call(loadTimelines)
  yield fork(measurements)

  // Side effects
  yield takeLatest([SET_NAVIGATION, Actions.SELECT_SINGLE_LOCATION], navigation)

  yield takeLatest(Actions.SET_MODE, navigationInit)

  yield takeEvery(Actions.SELECT_LOCATION, navigation)
  yield takeEvery(Actions.SHOW_ALL_LOCATIONS, navigation)
  yield takeEvery(Actions.HIDE_ALL_LOCATIONS, navigation)

  yield takeLeading(Actions.START_ACTIVENESS, activenessStart)
  yield takeLeading(Actions.FINISH_ACTIVENESS, activenessFinish)

  yield takeEvery(Actions.EDIT_SCHEDULE, scheduleEdit)
  yield takeLeading(Actions.DESTROY_SCHEDULE, scheduleDestroy)

  yield takeEvery(Actions.EDIT_REALIZATION, realizationEdit)
  yield takeLeading(Actions.DESTROY_REALIZATION, realizationDestroy)

  yield takeEvery(Actions.EDIT_SCRAP, scrapEdit)
  yield takeLeading(Actions.DESTROY_SCRAP, scrapDestroy)

  yield takeEvery(Actions.CREATE_CHANGEOVER_CHANGE, changeoverChangeCreate)
  yield takeLeading(Actions.DESTROY_CHANGEOVER_CHANGE, changeoverChangeDestroy)

  yield takeEvery(Actions.OPEN_NEW_FORM, formDefaults)
  yield takeEvery(Actions.CHANGE_FORM_DATA, formChange)
  yield takeEvery(Actions.SELECT_TIMELINE_BLOCK, formPrefill)
  yield takeLeading(Actions.SUBMIT_FORM, formSubmission)

  yield fork(fetchShiftBookNotes)
  yield takeLeading(Actions.RECEIVE_SHIFT_BOOK, setReplyFromUrl)
  yield takeEvery(
    [SET_NAVIGATION, Actions.SELECT_SINGLE_LOCATION, Actions.OPEN_SHIFT_BOOK],
    fetchShiftBookNotes
  )
  yield takeLeading([
    Actions.CREATE_SHIFT_BOOK_NOTE,
    Actions.SUBMIT_REPLY_SHIFT_BOOK_NOTE
  ], createShiftBookNote)
  yield takeLeading(Actions.SUBMIT_EDIT_SHIFT_BOOK_NOTE, updateShiftBookNote)
  yield takeLeading(Actions.DESTROY_SHIFT_BOOK_NOTE, destroyShiftBookNote)
  yield fork(
    takeLeadingWithPayload,
    Actions.MARK_SHIFT_BOOK_NOTE_AS_READ,
    markShiftBookNoteAsRead,
    JSON.stringify
  )
  yield takeLeading(Actions.MARK_ALL_SHIFT_BOOK_NOTES_AS_READ, markAllShiftBookNotesAsRead)
  yield takeLeading(Actions.SET_SHIFT_BOOK_NOTE_AS_RESOLVED, setResolved)
  yield takeLeading(Actions.CONFIRM_MEASUREMENT, confirmMeasurement)

  if (mode === Modes.FORMREINIGER) {
    yield call(initFormreiniger)
  }
}
