import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DateNavigation from 'components/DateNavigation'
import ScopeNavigation from 'components/ScopeNavigation'
import ShiftNavigation from 'components/ShiftNavigation'
import WorkspaceNavigationNotCurrentDateAlert from 'workspace/WorkspaceNavigationNotCurrentDateAlert'
import {
  setNavigationScope,
  setNavigationDate,
  setNavigationShift
} from 'containers/Navigation/actions'
import {
  selectNavigation,
  selectNavigationShift
} from 'containers/Navigation/selectors'

class WorkspaceNavigation extends React.Component {
  static defaultProps = {
    setScope: () => {},
    setDate: () => {}
  }

  static propTypes = {
    date: PropTypes.object.isRequired,
    scope: PropTypes.oneOf(['day', 'week']),
    setScope: PropTypes.func,
    setDate: PropTypes.func,
    shift: PropTypes.number,
    setShift: PropTypes.func
  }

  render () {
    const { scope, date, setDate, setScope, shift, setShift } = this.props

    return (
      <div
        className='workspace-navigation'
        style={{ flexWrap: scope === 'day' ? 'wrap' : 'nowrap' }}
      >
        <div className='workspace-navigation__container'>
          <div>
            <div className='workspace-navigation__dates'>
              <DateNavigation date={date} scope={scope} setDate={setDate} />
            </div>
            <WorkspaceNavigationNotCurrentDateAlert />
          </div>

          <div className='workspace-navigation__scope'>
            <ScopeNavigation
              scope={scope}
              setScope={setScope}
              scopeType='week'
            />
          </div>
        </div>
        <div className='workspace-navigation__shift'>
          {scope === 'day' && (
            <ShiftNavigation shift={shift} setShift={setShift} />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...selectNavigation(state),
  shift: selectNavigationShift(state)
})

const mapDispatchToProps = {
  setScope: setNavigationScope,
  setDate: setNavigationDate,
  setShift: setNavigationShift
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkspaceNavigation)
