import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Button from 'components/Button'
import DropdownButton from 'components/DropdownButton'
import ConfirmableButton from 'components/ConfirmableButton'
import RealizationMenuActivenessForm from 'workspace/RealizationMenuActivenessForm'
import {
  openNewForm,
  startActiveness,
  finishActiveness
} from 'workspace/actions'
import {
  selectCurrentActiveness,
  selectCurrentBreakdown,
  selectCurrentUserManualProductionAllowed
} from 'workspace/selectors'
import { SIMPLE_DATETIME_FORMAT } from 'lib/const'
import { FormTypes } from 'workspace/const'
import RealizationMenuShiftBookDropdown from './RealizationMenuShiftBookDropdown'

class RealizationMenu extends React.Component {
  static defaultProps = {
    onMenuClick: () => {}
  }

  static propTypes = {
    activeness: PropTypes.shape({
      id: PropTypes.number
    }),
    breakdown: PropTypes.shape({
      id: PropTypes.number
    }),
    locationId: PropTypes.number.isRequired,
    onMenuClick: PropTypes.func,
    startActiveness: PropTypes.func.isRequired,
    finishActiveness: PropTypes.func.isRequired,
    manualProductionAllowed: PropTypes.bool
  }

  menuClick = (type, data) => this.props.onMenuClick(type, data)
  menuRealizationClick = e => this.menuClick(FormTypes.REALIZATION)
  menuChangeoverClick = e => this.menuClick(FormTypes.REALIZATION_CHANGEOVER)
  menuStoppageClick = e => this.menuClick(FormTypes.REALIZATION_STOPPAGE)
  menuScrapClick = e => this.menuClick(FormTypes.REALIZATION_SCRAP)
  menuCollectiveScrapClick = e => this.props.onMenuClick(FormTypes.REALIZATION_SCRAP, undefined, undefined, 'collective')
  menuBreakdownStartClick = e => this.menuClick(FormTypes.BREAKDOWN_START)
  menuBreakdownFinishClick = e =>
    this.menuClick(FormTypes.BREAKDOWN_FINISH, this.props.breakdown)
  menuFreshProjectNotificationClick = () =>
    this.menuClick(FormTypes.FRESH_PROJECT_NOTIFICATION)
  startActiveness = params =>
    this.props.startActiveness({ ...params, locationId: this.props.locationId })
  finishActiveness = e => this.props.finishActiveness(this.props.activeness.id)

  render () {
    const { activeness, breakdown, manualProductionAllowed } = this.props

    return (
      <div className='workspace-menu'>
        {activeness && (
          <div className='workspace-menu__group'>
            <Button
              onClick={this.menuRealizationClick}
              color='success'
              className='workspace-menu__element'
              disabled={!manualProductionAllowed}
            >
              <FormattedMessage
                id='workspace.menu.realization'
                defaultMessage='Realization'
                description='Menu button that opens the realization form'
              />
            </Button>
            <Button
              onClick={this.menuChangeoverClick}
              color='default'
              className='workspace-menu__element'
            >
              <FormattedMessage
                id='workspace.menu.realizationChangeover'
                defaultMessage='Changeover'
                description='Menu button that opens the realization changeover form'
              />
            </Button>
            <Button
              onClick={this.menuStoppageClick}
              color='danger'
              className='workspace-menu__element'
            >
              <FormattedMessage
                id='workspace.menu.realizationStoppage'
                defaultMessage='Stoppage'
                description='Menu button that opens the realization stoppage form'
              />
            </Button>
            <Button
              onClick={this.menuScrapClick}
              color='info'
              className='workspace-menu__element'
            >
              <FormattedMessage
                id='workspace.menu.realizationScrap'
                defaultMessage='Scrap'
                description='Menu button that opens the realization scrap form'
              />
            </Button>
            <Button
              onClick={this.menuCollectiveScrapClick}
              color='info'
              className='workspace-menu__element'
            >
              <FormattedMessage
                id='workspace.menu.realizationCollectiveScrap'
                defaultMessage='Scrap'
                description='Menu button that opens the realization scrap form'
              />
            </Button>
          </div>
        )}

        <div className='workspace-menu__group'>
          {activeness && (
            <ConfirmableButton
              onClick={this.finishActiveness}
              color='warning'
              className='workspace-menu__element'
            >
              <FormattedMessage
                id='workspace.menu.finishActiveness'
                defaultMessage='Stop working [{startedAt}]'
                description='Menu button that finishes a location activeness'
                values={{
                  operatorsCount: activeness.operatorsCount,
                  startedAt: moment(activeness.startedAt)
                    .format(SIMPLE_DATETIME_FORMAT)
                    .replace(' ', '\u00a0')
                    .replace(/-/g, '\u2011')
                }}
              />
            </ConfirmableButton>
          )}
          {!activeness && (
            <DropdownButton
              color='warning'
              dropdownColor='warning'
              className='workspace-menu__element'
              content={({ close }) => (
                <RealizationMenuActivenessForm
                  onClose={close}
                  onSubmit={this.startActiveness}
                />
              )}
            >
              <FormattedMessage
                id='workspace.menu.startActiveness'
                defaultMessage='Begin working'
                description='Menu button that starts a location activeness'
              />
            </DropdownButton>
          )}
          <RealizationMenuShiftBookDropdown />
        </div>

        <div className='workspace-menu__group'>
          {!breakdown && (
            <Button
              onClick={this.menuBreakdownStartClick}
              color='default'
              className='workspace-menu__element'
            >
              <FormattedMessage
                id='workspace.menu.startBreakdown'
                defaultMessage='Report breakdown'
                description='Menu button that starts a location breakdown'
              />
            </Button>
          )}
          {breakdown && (
            <Button
              onClick={this.menuBreakdownFinishClick}
              color='danger'
              className='workspace-menu__element'
            >
              <FormattedMessage
                id='workspace.menu.finishBreakdown'
                defaultMessage='Resolve breakdown'
                description='Menu button that finishes a location breakdown'
              />
            </Button>
          )}
          <Button
            onClick={this.menuFreshProjectNotificationClick}
            color='default'
            className='workspace-menu__element'
          >
            <FormattedMessage
              id='workspace.menu.freshProjectNotification'
              defaultMessage='New project notification'
              description='Menu button that opens fresh project notificatio form'
            />
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  locationId: state.locations.selectedIds[0],
  activeness: selectCurrentActiveness(state),
  breakdown: selectCurrentBreakdown(state),
  manualProductionAllowed: selectCurrentUserManualProductionAllowed(state)
})

const mapDispatchToProps = {
  onMenuClick: openNewForm,
  startActiveness,
  finishActiveness
}

export default connect(mapStateToProps, mapDispatchToProps)(RealizationMenu)
