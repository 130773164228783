import { call, fork, put, select, delay } from 'redux-saga/effects'
import moment from 'moment-timezone'
import api from 'lib/api/client'
import {
  receiveRealizations,
  requestRealizations,
  receiveRealizationOverlappings
} from 'workspace/actions'
import { selectToken, selectTimezone } from 'workspace/selectors'
import { selectNavigationRange } from 'containers/Navigation/selectors'

const realizationDecorator = ({ startedAt, engineeringFinishedAt, finishedAt, ...realization }, timezone) => ({
  ...realization,
  startedAt: moment.tz(startedAt, timezone),
  finishedAt: moment.tz(finishedAt, timezone),
  engineeringFinishedAt: moment.tz(engineeringFinishedAt, timezone)
})

const overlapDecorator = (timezone, { startedAt, finishedAt, ...overlap }) => ({
  ...overlap,
  startedAt: moment.tz(startedAt, timezone),
  finishedAt: moment.tz(finishedAt, timezone),
})

const decorateRealizations = (realizations, timezone) => realizations.map(s => realizationDecorator(s, timezone))

const callRealizationsAPI = ({ token, from, to, locationId }) =>
  call(api.get, '/location_realizations', {
    token,
    params: {
      from,
      to,
      locationId
    }
  })

export function * requestLocationRealizations (locationId) {
  yield put(requestRealizations(locationId))
}

export function * requestAllRealizations () {
  const ids = yield select(state => state.locations.selectedIds)

  for (var i = 0; i < ids.length; i++) {
    yield put(requestRealizations(ids[i]))
  }
}

export function * loadLocationRealizations (locationId) {
  const token = yield select(selectToken)
  const { from, to } = yield select(selectNavigationRange)
  const timezone = yield select(selectTimezone)

  const realizations = yield callRealizationsAPI({ token, from, to, locationId })
  yield put(receiveRealizations({ locationId, realizations: decorateRealizations(realizations, timezone) }))

  yield loadLocationRealizationsOverlappings(locationId)
}

function * loadLocationRealizationsOverlappings (locationId) {
  const token = yield select(selectToken)
  const { from, to } = yield select(selectNavigationRange)
  const timezone = yield select(selectTimezone)

  const overlappings = yield  call(api.get, '/location_realizations/overlappings', {
    token,
    params: {
      from,
      to,
      locationId
    }
  })
  yield put(receiveRealizationOverlappings({
    locationId,
    overlappings: overlappings.map(overlapDecorator.bind(this, timezone))
  }))
}

export function * loadAllRealizations () {
  // used together with takeLatest this creates a "debounce" effect to stop too many requests from running at once
  yield delay(250)

  const ids = yield select(state => state.locations.selectedIds)

  for (var i = 0; i < ids.length; i++) {
    yield fork(loadLocationRealizations, ids[i])
  }
}
