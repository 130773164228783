import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import TimelineBlock from 'components/TimelineBlock'
import FormreinigerTimelineBlockContent from 'workspace/FormreinigerTimelineBlockContent'
import FormreinigerTimelineTooltip from 'workspace/FormreinigerTimelineTooltip'
import { selectTimelineBlock } from 'workspace/actions'
import {
  selectChangeover,
  selectChangeoverDetails
} from 'workspace/selectors'
import { Modes } from 'workspace/const'
import { Characters } from 'lib/const'

const CHARACTER_COLOR = {
  [Characters.PRODUCTION]: 'success',
  [Characters.STOPPAGE]: 'danger',
  [Characters.CHANGEOVER]: 'secondary'
}

const getRawColor = (project) => {
  return '#' + project.blockColor
}

const FormreinigerTimelineBlock = ({
  id,
  project,
  stoppageType,
  character,
  selectTimelineBlock,
  urgent,
  ...rest
}) => (
  <TimelineBlock
    {...rest}
    significant={stoppageType && stoppageType.isSignificant}
    color={CHARACTER_COLOR[character]}
    flash={urgent}
  >
    <FormreinigerTimelineBlockContent
      id={id}
      onClick={e => selectTimelineBlock({ mode: Modes.PLANNING, character, id })}
      rawColor={getRawColor(project)}
    />
    <FormreinigerTimelineTooltip id={id} rawColor={getRawColor(project)} />
  </TimelineBlock>
)

FormreinigerTimelineBlock.propTypes = {
  id: PropTypes.number.isRequired,
  project: PropTypes.shape({
    blockColor: PropTypes.string,
    id: PropTypes.number.isRequired
  }),
  stoppageType: PropTypes.shape({
    blockColor: PropTypes.string
  }),
  character: Characters.propType,
  urgent: PropTypes.bool
}

const mapStateToProps = (state, { id }) => ({
  ...selectChangeover(state, id),
  ...selectChangeoverDetails(state, id)
})

const mapDispatchToProps = ({
  selectTimelineBlock
})

export default connect(mapStateToProps, mapDispatchToProps)(FormreinigerTimelineBlock)
