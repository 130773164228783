import React from 'react'
import PropTypes from 'prop-types'
import Barcode from 'react-barcode'

import BarcodeKanbanConfirmation from './WorkspaceBarcodeKanbanConfirmation'

const WorkspaceBarcodeKanban = ({ product }) => {
  const color = `#${product.labelColor}`
  return (
    <React.Fragment>
      <div className='label-modal__line'>{product.description}</div>
      <div className='label-modal__line'>{product.labelDetails || '---'}</div>
      <BarcodeKanbanConfirmation product={product}>
        {() => (
          <Barcode
            value={product.labelNumber}
            format='CODE39'
            background={color}
            height={200}
            width={2}
            displayValue
          />
        )}
      </BarcodeKanbanConfirmation>
    </React.Fragment>
  )
}

WorkspaceBarcodeKanban.propTypes = {
  product: PropTypes.shape({
    description: PropTypes.string.isRequired,
    labelNumber: PropTypes.string,
    labelDetails: PropTypes.string,
    labelColor: PropTypes.string.isRequired,
    kanbanConfirmationText: PropTypes.string
  })
}

export default WorkspaceBarcodeKanban
