import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { addSubform } from 'workspace/actions'
import Button from 'components/Button'
import Icon from 'components/Icon'

class WorkspaceAddSubform extends React.Component {
  static propTypes = {
    isFormMulti: PropTypes.bool,
    isFormActive: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
  }

  onClick = (e) => {
    e.preventDefault()
    this.props.onClick()
  }

  render () {
    const { isFormMulti, isFormActive, disabled } = this.props

    if (!isFormMulti || !isFormActive) {
      return null
    }

    return (
      <div className='workspace__extra'>
        <Button onClick={this.onClick} color='success' disabled={disabled} >
          <Icon name='plus' />
        </Button>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const firstId = Object.keys(state.form.data)[0]
  const disabled = !state.form.isMulti || !state.form.data[firstId].projectId

  return {
    isFormMulti: state.form.isMulti,
    isFormActive: state.form.isActive,
    disabled
  }
}

const mapDispatchToProps = ({
  onClick: addSubform
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceAddSubform)
