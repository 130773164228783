import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'
import { selectFormValue } from 'workspace/selectors'
import DatePicker from 'components/DatePicker'

const WorkspaceFormReportedAt = ({ reportedAt, onChange, formId }) => (
  <WorkspaceFormInput attribute='reportedAt' formId={formId}>
    <DatePicker
      className='workspace-form__input'
      selected={reportedAt}
      onChange={value => onChange({ reportedAt: value })}
      showTimeSelect
      timeIntervals={1}
      precision='minute'
    />
  </WorkspaceFormInput>
)

WorkspaceFormReportedAt.propTypes = {
  reportedAt: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  formId: PropTypes.string
}

const mapStateToProps = (state, ownProps) => ({
  reportedAt: selectFormValue(state, 'reportedAt', ownProps.formId)
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormReportedAt)
