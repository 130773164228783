import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ToggleButton from 'components/ToggleButton'
import { hideAllLocations } from './actions'
import { compose } from 'redux'
import { injectIntl, intlShape } from 'react-intl'
import { selectAllLocationsHidden } from './selectors'

const WorkspaceLocationSelectNone = ({
  allHidden,
  hideAll,
  intl
}) => (
  <ToggleButton
    isToggled={allHidden}
    onToggleOn={hideAll}
    onToggleOff={hideAll}
    color='default'
  >
    {intl.formatMessage({ id: 'components.button.hideAll' })}
  </ToggleButton>
)

WorkspaceLocationSelectNone.propTypes = {
  allHidden: PropTypes.bool,
  hideAll: PropTypes.func,
  intl: intlShape
}

const mapStateToProps = state => ({
  allHidden: selectAllLocationsHidden(state)
})

const mapDispatchToProps = {
  hideAll: hideAllLocations
}

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(WorkspaceLocationSelectNone)
