import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, intlShape } from 'react-intl'
import AssociationSelect from 'components/AssociationSelect'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { selectAvailableQualityControllers, selectIsFreshProject } from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormQualityController = ({ id, intl, disabled, qualityControllers, onChange, formId }) => (
  <WorkspaceFormInput attribute='qualityController'>
    <AssociationSelect
      isClearable
      options={qualityControllers}
      value={id}
      onChange={(newId) => onChange({ qualityControllerId: newId })}
      labelMapper={user => `${user.lastName} ${user.firstName}`}
      isDisabled={disabled}
    />
  </WorkspaceFormInput>
)

WorkspaceFormQualityController.propTypes = {
  intl: intlShape.isRequired,
  qualityControllers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired
  })),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  qualityControllers: selectAvailableQualityControllers(state),
  id: state.form.data.qualityControllerId,
  formId: PropTypes.string,
  disabled: !selectIsFreshProject(state)
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WorkspaceFormQualityController)
