import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import Select from 'components/Select'
import { changeFormData } from 'workspace/actions'

const messages = defineMessages({
  electrical: {
    id: 'workspace.form.divisions.electrical',
    defaultMessage: 'Electrical'
  },
  logistics: {
    id: 'workspace.form.divisions.logistics',
    defaultMessage: 'Logistics'
  },
  mechanical: {
    id: 'workspace.form.divisions.mechanical',
    defaultMessage: 'Mechanical'
  },
  quality: {
    id: 'workspace.form.divisions.quality',
    defaultMessage: 'Quality'
  },
  technical: {
    id: 'workspace.form.divisions.technical',
    defaultMessage: 'Technical'
  }
})

const DIVISIONS = [
  'electrical',
  'logistics',
  'mechanical',
  'quality',
  'technical'
]

const optionMapper = (v, intl) => ({ label: v && intl.formatMessage(messages[v]), value: v })

const WorkspaceFormDivision = ({ division, onChange, intl, disabled, formId }) => (
  <WorkspaceFormInput attribute='division'>
    <Select
      options={DIVISIONS.map(v => optionMapper(v, intl))}
      value={optionMapper(division, intl)}
      onChange={(value) => onChange({ division: value ? value.value : false })}
      isDisabled={disabled}
      isClearable={false}
      backspaceRemovesValue={false}
    />
  </WorkspaceFormInput>
)

WorkspaceFormDivision.propTypes = {
  division: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  intl: intlShape,
  formId: PropTypes.string
}

const mapStateToProps = state => ({
  division: state.form.data.division
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WorkspaceFormDivision)
