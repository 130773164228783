import React from "react"
import PropTypes from "prop-types"
import TetherComponent from "react-tether"
import { compose } from "redux"
import { connect } from "react-redux"
import { selectOverlap } from "./selectors"
import moment, { duration } from "moment-timezone"
import { injectIntl } from "react-intl"
import { DATETIME_FORMAT } from "lib/const"

const MIN_MINUTES_LENGTH = 5

class RealizationTimelineOverlapping extends React.Component {
  constructor(props) {
    super(props)

    this.state = { focused: false }
  }

  focus = () => {
    this.setState({ focused: true })
  }

  blur = () => {
    this.setState({ focused: false })
  }

  render() {
    const {
      id,
      startedAt: startsAt,
      finishedAt: endsAt,
      timelineStart,
      intl,
      timelineHours: hours,
    } = this.props

    const timelineEnd = timelineStart.clone().add(hours, "hours")
    const visibleStartsAt = moment.max(startsAt, timelineStart)
    const visibleEndsAt = moment.min(endsAt, timelineEnd)
    const minutesLength = duration(
      visibleEndsAt.diff(visibleStartsAt)
    ).asMinutes()

    if (
      startsAt.isAfter(timelineEnd) ||
      endsAt.isBefore(timelineStart) ||
      minutesLength < MIN_MINUTES_LENGTH
    ) {
      return null
    }

    const leftOffset =
      (100 * duration(visibleStartsAt.diff(timelineStart)).asHours()) / hours
    const width =
      (100 * duration(visibleEndsAt.diff(visibleStartsAt)).asHours()) / hours

    return (
      <TetherComponent
        attachment="bottom left"
        targetAttachment="top left"
        renderElementTo="#workspace-tether"
        constraints={[
          {
            to: "window",
            attachment: "together",
            pin: ["left", "right"],
          },
        ]}
      >
        <div
          style={{
            background: "orange",
            height: 20,
            position: "absolute",
            bottom: -40,
            left: leftOffset + "%",
            width: width + "%",
          }}
          onMouseEnter={this.focus}
          onMouseLeave={this.blur}
        />
        {this.state.focused && (
          <div className="timeline-tooltip">
            <div className="timeline-tooltip__content">
              <table className="timeline-tooltip__table">
                <tbody>
                  <tr>
                    <th>
                      {intl.formatMessage({
                        id: "workspace.realization.tooltip.overlapping",
                      })}
                    </th>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      {intl.formatMessage({
                        id: "workspace.realization.tooltip.start",
                      })}
                    </th>
                    <td>{startsAt.format(DATETIME_FORMAT)}</td>
                  </tr>
                  <tr>
                    <th>
                      {intl.formatMessage({
                        id: "workspace.realization.tooltip.end",
                      })}
                    </th>
                    <td>{endsAt.format(DATETIME_FORMAT)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </TetherComponent>
    )
  }
}

RealizationTimelineOverlapping.propTypes = {
  id: PropTypes.string.isRequired,
  timelineSize: PropTypes.number.isRequired,
}

const mapStateToProps = (state, { id }) => ({
  ...selectOverlap(state, id),
})

export default compose(
  connect(mapStateToProps),
  injectIntl
)(RealizationTimelineOverlapping)
