import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { selectScrapDetails } from 'workspace/selectors'
import { DATETIME_FORMAT } from 'lib/const'
import Button from 'components/Button'
import DropdownButton from 'components/DropdownButton'
import ButtonGroup from 'components/ButtonGroup'
import Icon from 'components/Icon'
import { editScrap, destroyScrap } from 'workspace/actions'

const RealizationScrapTableRow = ({
  id,
  destroy,
  edit,
  product,
  productionPlan,
  project,
  quantity,
  reportedAt,
  scrapType,
  fullName,
  editable
}) => (
  <tr>
    <td><code>{reportedAt.format(DATETIME_FORMAT)}</code></td>
    <td>{productionPlan ? `${productionPlan.priority}. ${project.name}` : project.name}</td>
    <td>{product.description} ({product.code})</td>
    <td>{scrapType.symbol} - {scrapType.description}</td>
    <td style={{ textAlign: 'right' }}><code>{quantity}</code></td>
    <td>{fullName}</td>
    { editable && (<td>
      <ButtonGroup tableActions>
        <Button size='small' onClick={e => edit(id)}><Icon name='pencil' /></Button>
        <DropdownButton
          size='small'
          color='default'
          popperConfig={{ placement: 'bottom-end' }}
          content={({ close }) => (
            <React.Fragment>
              <a href='javascript:' style={{ minWidth: 50 }} onClick={e => destroy(id)}>
                <FormattedMessage id='workspace.table.remove' defaultMessage='Remove' />
              </a>
              <a href='javascript:' style={{ minWidth: 50 }} onClick={close}>
                <FormattedMessage id='workspace.table.cancel' defaultMessage='Cancel' />
              </a>
            </React.Fragment>
          )}
        >
          <Icon name='trash' />
        </DropdownButton>
      </ButtonGroup>
    </td>) }
  </tr>
)

RealizationScrapTableRow.propTypes = {
  destroy: PropTypes.func,
  edit: PropTypes.func,
  id: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  reportedAt: PropTypes.object.isRequired,
  fullName: PropTypes.string.isRequired,
  product: PropTypes.shape({
    code: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  project: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  scrapType: PropTypes.shape({
    description: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired
  }).isRequired,
  productionPlan: PropTypes.shape({
    priority: PropTypes.number.isRequired
  }),
  editable: PropTypes.bool
}

const mapStateToProps = (state, { id }) => selectScrapDetails(state, id)

const mapDispatchToProps = ({
  edit: editScrap,
  destroy: destroyScrap
})

export default connect(mapStateToProps, mapDispatchToProps)(RealizationScrapTableRow)
