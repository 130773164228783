import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import TimelineBlock from 'components/TimelineBlock'
import RealizationTimelineBlockContent from 'workspace/RealizationTimelineBlockContent'
import RealizationTimelineTooltip from 'workspace/RealizationTimelineTooltip'
import { selectTimelineBlock } from 'workspace/actions'
import { selectRealization, selectRealizationDetails } from 'workspace/selectors'
import { Modes } from 'workspace/const'
import { Characters } from 'lib/const'

const CHARACTER_COLOR = {
  [Characters.PRODUCTION]: 'success',
  [Characters.STOPPAGE]: 'danger',
  [Characters.CHANGEOVER]: 'secondary',
  [Characters.UNREPORTED]: 'warning'
}

const sizeAndOffset = (product, timelineSize) => {
  const isSided = product && product.side !== 'unspecified'
  const isRightSide = product && product.side === 'right'

  return {
    size: isSided ? timelineSize / 2 : timelineSize,
    offset: isRightSide ? timelineSize / 2 : 0
  }
}

const getRawColor = (character, project, stoppageType) => {
  if (character === Characters.PRODUCTION && project && project.blockColor) {
    return '#' + project.blockColor
  } else if (character === Characters.STOPPAGE && stoppageType && stoppageType.blockColor) {
    return '#' + stoppageType.blockColor
  } else if (character === Characters.UNREPORTED) {
    return '#000000'
  } else {
    return null
  }
}

const RealizationTimelineBlock = ({
  id,
  startedAt,
  finishedAt,
  product,
  project,
  stoppageType,
  timelineSize,
  character,
  isFormActive,
  selectTimelineBlock,
  pastTimeWindow,
  ...rest
}) => (
  <TimelineBlock
    {...rest}
    startsAt={startedAt}
    endsAt={finishedAt}
    color={CHARACTER_COLOR[character]}
    timelineSize={timelineSize}
    significant={stoppageType && stoppageType.isSignificant}
    {...sizeAndOffset(product, timelineSize)}
  >
    <RealizationTimelineBlockContent
      id={id}
      onClick={e => !pastTimeWindow && selectTimelineBlock({ mode: Modes.REALIZATION, character, id })}
      rawColor={getRawColor(character, project, stoppageType)}
      fresh={project && project.type === 'fresh'}
    />
    <RealizationTimelineTooltip id={id} rawColor={getRawColor(character, project, stoppageType)} />
  </TimelineBlock>
)

RealizationTimelineBlock.propTypes = {
  id: PropTypes.number.isRequired,
  timelineSize: PropTypes.number.isRequired,
  product: PropTypes.shape({
    id: PropTypes.number,
    side: PropTypes.oneOf(['left', 'right', 'unspecified']),
    projectIds: PropTypes.arrayOf(PropTypes.number).isRequired
  }),
  project: PropTypes.shape({
    blockColor: PropTypes.string
  }),
  stoppageType: PropTypes.shape({
    blockColor: PropTypes.string
  }),
  character: Characters.propType
}

const mapStateToProps = (state, { id }) => ({
  ...selectRealization(state, id),
  ...selectRealizationDetails(state, id)
})

const mapDispatchToProps = ({
  selectTimelineBlock
})

export default connect(mapStateToProps, mapDispatchToProps)(RealizationTimelineBlock)
