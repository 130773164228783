import React from 'react'
import { connect } from 'react-redux'
import PlanMenu from 'workspace/PlanMenu'
import RealizationMenu from 'workspace/RealizationMenu'
import { selectMode } from 'workspace/selectors'
import { Modes } from 'workspace/const'

const WorkspaceMenu = ({ mode }) => {
  switch (mode) {
    case Modes.REALIZATION:
      return <RealizationMenu />
    case Modes.FORMREINIGER:
      return <></>
    default:
      return <PlanMenu />
  }
}

WorkspaceMenu.propTypes = {
  mode: Modes.propType
}

const mapStateToProps = state => ({
  mode: selectMode(state)
})

export default connect(mapStateToProps)(WorkspaceMenu)
