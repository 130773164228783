import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { selectProduct, selectFormValue } from 'workspace/selectors'

const WorkspaceFormProductReadonly = ({ productType, product, formId }) => (
  <WorkspaceFormInput attribute={productType} formId={formId}>
    <input
      type='text'
      className='workspace-form__input'
      value={`${product.description} (${product.code})`}
      readOnly
    />
  </WorkspaceFormInput>
)

WorkspaceFormProductReadonly.propTypes = {
  formId: PropTypes.string,
  productType: PropTypes.oneOf(['product', 'productLeft', 'productRight']),
  product: PropTypes.shape({
    description: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired
  }).isRequired
}

const mapStateToProps = (state, props) => ({
  product: selectProduct(state, selectFormValue(state, `${props.productType}Id`, props.formId))
})

export default connect(mapStateToProps)(WorkspaceFormProductReadonly)
