import { all, call, fork, put, select, delay } from 'redux-saga/effects'
import moment from 'moment-timezone'
import api from 'lib/api/client'
import { receiveScraps, requestScraps } from 'workspace/actions'
import { selectToken, selectTimezone } from 'workspace/selectors'
import { selectNavigationRange } from 'containers/Navigation/selectors'

const scrapDecorator = ({ reportedAt, ...scrap }, timezone) => ({
  ...scrap,
  reportedAt: moment.tz(reportedAt, timezone)
})

const decorateScraps = (scraps, timezone) => scraps.map(s => scrapDecorator(s, timezone))

const callAPI = (path, { token, from, to, locationId }) =>
  call(api.get, path, {
    token,
    params: {
      from,
      to,
      locationId
    }
  })

const callScrapsAPI = (args) => callAPI('/scraps', args)

const callScrapsPercentageAPI = (args) => callAPI('/scraps/percentage', args)

export function * requestLocationScraps (locationId) {
  yield put(requestScraps(locationId))
}

export function * requestAllScraps () {
  const ids = yield select(state => state.locations.selectedIds)

  for (var i = 0; i < ids.length; i++) {
    yield put(requestScraps(ids[i]))
  }
}

export function * loadLocationScraps (locationId) {
  const token = yield select(selectToken)
  const { from, to } = yield select(selectNavigationRange)
  const timezone = yield select(selectTimezone)

  const [scraps, scrapsPercentage] = yield all([
    callScrapsAPI({ token, from, to, locationId }),
    callScrapsPercentageAPI({ token, from, to, locationId })
  ])
  yield put(receiveScraps({ locationId, scraps: decorateScraps(scraps, timezone), percentage: scrapsPercentage }))
}

export function * loadAllScraps () {
  // used together with takeLatest this creates a "debounce" effect to stop too many requests from running at once
  yield delay(250)

  const ids = yield select(state => state.locations.selectedIds)

  for (var i = 0; i < ids.length; i++) {
    yield fork(loadLocationScraps, ids[i])
  }
}
