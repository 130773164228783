import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Button from 'components/Button'
import { openNewForm } from 'workspace/actions'
import { FormTypes } from 'workspace/const'

class WorkspaceMenu extends React.Component {
  static defaultProps = {
    onMenuClick: () => {}
  }

  static propTypes = {
    onMenuClick: PropTypes.func
  }

  menuClick = (type, data) => this.props.onMenuClick(type, data)
  menuStoppageClick = (e) => this.menuClick(FormTypes.STOPPAGE)
  menuProductionClick = (e) => this.menuClick(FormTypes.PRODUCTION)
  menuSAPImportClick = (e) => this.menuClick(FormTypes.SAP_IMPORT)

  render () {
    return (
      <div className='workspace-menu'>
        <div className='workspace-menu__group'>
          <Button onClick={this.menuSAPImportClick} color='success' className='workspace-menu__element'>
            <FormattedMessage
              id='workspace.menu.sapImport'
              defaultMessage='SAP Import'
              description='Menu button that opens the sap-import form' />
          </Button>
          <Button onClick={this.menuProductionClick} color='success' className='workspace-menu__element'>
            <FormattedMessage
              id='workspace.menu.production'
              defaultMessage='Add production'
              description='Menu button that opens the production form' />
          </Button>
          <Button onClick={this.menuStoppageClick} color='danger' className='workspace-menu__element'>
            <FormattedMessage
              id='workspace.menu.stoppage'
              defaultMessage='Add stoppage'
              description='Menu button that opens the stoppage form' />
          </Button>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = ({
  onMenuClick: openNewForm
})

export default connect(null, mapDispatchToProps)(WorkspaceMenu)
