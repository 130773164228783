import { call, put, race, select, take, delay, fork } from 'redux-saga/effects'
import {
  selectMode,
  selectSelectedLocation,
  selectPlanTimestamp
} from 'workspace/selectors'
import {
  requestAllSchedules,
  loadAllSchedules
} from './schedules'
import Actions, { updatePlanChanged } from 'workspace/actions'
import { Modes } from 'workspace/const'
import { loadAllChangeovers, requestAllChangeovers } from './formreiniger'

const INTERVAL = 60 * 1000

function * realization () {
  let location, updatedAt, newUpdatedAt, result

  yield take(Actions.RECEIVE_SCHEDULES)
  location = yield select(selectSelectedLocation)
  updatedAt = yield select(selectPlanTimestamp, location.id)

  while (true) {
    result = yield race({
      action: take(Actions.RECEIVE_SCHEDULES),
      timeout: delay(INTERVAL)
    })

    if (result.timeout) {
      yield call(requestAllSchedules)
      yield call(loadAllSchedules)
      newUpdatedAt = yield select(selectPlanTimestamp, location.id)
      if (
        (!newUpdatedAt && updatedAt) ||
        (newUpdatedAt && !updatedAt) ||
        (newUpdatedAt && !newUpdatedAt.isSame(updatedAt, 'second'))
      ) {
        yield put(updatePlanChanged(true))
      }
      updatedAt = newUpdatedAt
    } else {
      location = yield select(selectSelectedLocation)
      updatedAt = yield select(selectPlanTimestamp, location.id)
    }
  }
}

function * formreiniger () {
  let result
  yield take(Actions.RECEIVE_CHANGEOVERS)

  while (true) {
    result = yield race({
      action: take(Actions.RECEIVE_CHANGEOVERS),
      timeout: delay(INTERVAL)
    })

    if (result.timeout) {
      yield call(requestAllChangeovers)
      yield call(loadAllChangeovers)
    }
  }
}

export default function * reload () {
  const mode = yield select(selectMode)

  if (mode === Modes.REALIZATION) {
    yield fork(realization)
  }

  if (mode === Modes.FORMREINIGER) {
    yield fork(formreiniger)
  }
}
