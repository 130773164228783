import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { intlShape, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import moment from 'moment-timezone'
import Button from 'components/Button'
import { DATETIME_FORMAT } from '../lib/const'
import { confirmMeasurement } from './actions'

const WorkspaceMeasurementModal = ({ realization, intl, onConfirm }) => {
  if (!realization) {
    return null
  }

  const isOk = realization.measurement.status === 'ok'

  return (
    <Modal className='label-modal' noClose style={{ background: isOk ? 'white' : '#e74c3c' }}>
      <div className='label-modal__line'>
        { isOk && intl.formatMessage({ id: 'measurements.modal.ok' }) }
        { !isOk && intl.formatMessage({ id: 'measurements.modal.nok' }) }
      </div>
      <div className='label-modal__line label-modal__line--small'>
        {`${realization.project} (${moment(realization.startedAt).format(DATETIME_FORMAT)}, ${realization.location} )`}
      </div>
      { realization.measurement.description && (
        <div className='label-modal__line label-modal__line--small'>
          { realization.measurement.description }
        </div>
      )}
      <Button color='success' onClick={() => onConfirm(realization.id)}>
        { isOk && intl.formatMessage({ id: 'measurements.modal.confirm' }) }
        { !isOk && intl.formatMessage({ id: 'measurements.modal.stopProduction' }) }
      </Button>
    </Modal>
  )
}

WorkspaceMeasurementModal.propTypes = {
  realization: PropTypes.object,
  onConfirm: PropTypes.func,
  intl: intlShape
}

const mapStateToProps = (state) => {
  return {
    realization: state.measurement
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onConfirm: (id) => dispatch(confirmMeasurement(id))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WorkspaceMeasurementModal)
