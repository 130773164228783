import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import RealizationScrapTableRow from 'workspace/RealizationScrapTableRow'
import Loader from 'components/Loader'

const RealizationScrapTable = ({ scrapIds, isLoading }) => (
  <div style={{ position: 'relative' }}>
    {
      isLoading && <Loader style={{ position: 'absolute', right: 0, left: 0, opacity: 0.66 }} size={2} full />
    }
    <table className='table'>
      <thead>
        <tr>
          <th><FormattedMessage id='workspace.scrap.reportedAt' defaultMessage='Reported at' /></th>
          <th><FormattedMessage id='workspace.scrap.plan' defaultMessage='Plan / Project' /></th>
          <th><FormattedMessage id='workspace.scrap.product' defaultMessage='Product' /></th>
          <th><FormattedMessage id='workspace.scrap.type' defaultMessage='Type' /></th>
          <th><FormattedMessage id='workspace.scrap.quantity' defaultMessage='Quantity' /></th>
          <th><FormattedMessage id='workspace.scrap.reportedBy' defaultMessage='Reported by' /></th>
          <th className='wmin' />
        </tr>
      </thead>

      <tbody>
        {
          scrapIds.length === 0 && <tr><td colSpan={7} style={{ textAlign: 'center' }}>---</td></tr>
        }
        {
          scrapIds.map(id => <RealizationScrapTableRow key={id} id={id} />)
        }
      </tbody>
    </table>
  </div>
)

RealizationScrapTable.propTypes = {
  scrapIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  isLoading: PropTypes.bool
}

const mapStateToProps = (state, { id }) => ({
  scrapIds: state.scraps.idsByLocation[id],
  isLoading: state.locations.isLoadingScrapsById[id]
})

export default connect(mapStateToProps)(RealizationScrapTable)
