import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'
import DatePicker from 'components/DatePicker'

const WorkspaceFormEngineeringFinishedAt = ({ engineeringFinishedAt, onChange, formId }) => (
  <WorkspaceFormInput attribute='engineeringFinishedAt'>
    <DatePicker
      className='workspace-form__input'
      selected={engineeringFinishedAt}
      onChange={value => onChange({ engineeringFinishedAt: value })}
      showTimeSelect
      timeIntervals={1}
      precision='minute'
    />
  </WorkspaceFormInput>
)

WorkspaceFormEngineeringFinishedAt.propTypes = {
  engineeringFinishedAt: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  formId: PropTypes.string
}

const mapStateToProps = state => ({
  engineeringFinishedAt: state.form.data.engineeringFinishedAt
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: data => dispatch(changeFormData(data, ownProps.formId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormEngineeringFinishedAt)
